/*  Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  #more {
    display: none;
  }
  #check_search {
    line-height: 0 !important;
  }
  /* .submitammiunt{
    width: 80%;
  } */
  .ordersubsecond {
    padding-left: 10%;
    padding-right: 8%;
    font-size: 14px;
  }
  .overlay_content {
    font-size: 22px;
  }
  .search_domain_div .form-control {
    padding: 10px 30px;
  }
  .box-border-sm {
    border: none !important;
  }
  .btn-mobile-nav {
    margin: 10px 0 !important;
  }
  .newcheckdomain {
    height: 38px !important;
  }
  .search_domain_div button.input-group-text {
    height: 39px !important;
    top: 2px !important;
  }
  .hosting_plans_popup h3 {
    padding: 0px;
  }
  .popup_body {
    padding: 20px 20px 20px 20px;
  }
  .addon-mobile {
    margin-top: 40px;
  }
  .block_text {
    height: 125px;
  }
  .hosting_plans_popup {
    height: 100%;
  }
  span.close_popup {
    position: absolute;
    top: 0px;
    right: 7px;
    font-size: 18px;
  }
  .ecommerce_solution h3 {
    font-size: 22px;
  }
  .hosting_plans_popup p {
    margin-bottom: 0px;
  }
  .center_popup.width_change {
    width: 100%;
  }

  .package-namepaaaa {
    /* width: 72%; */
    width: 95%;
  }

  .package-one-price {
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .ssliderhost ul {
    margin-bottom: 5px;
  }

  .psetiingcart {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .domainCheckMobile {
    align-self: auto;
  }
  .offcanvas {
    background-color: #ebf2f5 !important;
  }
  .modal-backdrop.show {
    display: none;
  }
  .modal-backdrop {
    background: transparent;
  }
  .modal-backdrop.show {
    display: none;
  }
  .krmNah {
    background-color: #005880 !important;
    box-shadow: 0 0 1px 3px #005880 !important;
  }
  .sm-g-0 {
    --bs-gutter-y: 0rem;
    --bs-gutter-x: 0rem;
  }
  .sm-g-1 {
    --bs-gutter-y: 1rem;
    --bs-gutter-x: 1rem;
  }
  .button-sigup {
    width: 50%;
  }
  .rec-arrow-left {
    display: none;
  }
  .rec-arrow-right {
    display: none;
  }
  .cart-button_secondary {
    margin-top: 5px;
  }
  .adjust-width-domainName {
    width: 110px !important;
  }
  .selection_tab {
    /* padding-top: 50px; */
    padding-top: 0px;
  }
  .margin-top11 {
    margin-top: 10px !important;
  }
  .search_domain_div {
    /* padding-top: 50px !important; */

    padding-top: 30px !important;
  }
  .mb-sm-5 {
    margin-bottom: 30px !important;
  }
  .first_select_tab .checkbox_container {
    font-size: 15px;
  }

  .wondfreedomain {
    font-size: 13px;
    height: 40px;
  }
  .first_select_tab .checkbox_container .checkedmark {
    top: 11px;
  }

  .adjust-width {
    width: 50px !important;
  }
  .sm-mt-1 {
    margin-top: 1.2rem !important;
  }

  .table-heading {
    padding: 13px 0px 13px 0px !important;
    font-size: 12pt;
    text-transform: uppercase;
    word-spacing: initial;
    margin: 0;
  }
  .border-right {
    border-right: none !important;
  }

  .sm-ml-0 {
    margin-left: 0px !important;
  }
  .registration h2 {
    font-size: 20px;
  }

  .passion_text h3 {
    width: 100%;
  }
  .passion_text h3 {
    font-size: 25px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .passion_text h3 span {
    font-size: 40px;
    line-height: 50px;
  }
  .pt-100 {
    padding-top: 50px;
  }
  .pb-50 {
    padding-bottom: 50px !important;
  }
  .sm-p-0 {
    padding-bottom: 0px !important;
  }
  .margin-b-0 {
    margin-bottom: 0px !important;
  }
  .margin-t-0 {
    margin-top: 0px !important;
  }
  .padding-t-50 {
    padding-top: 50px !important;
  }
  .margin-t-100 {
    margin-top: 100px !important;
  }
  .margin-t-80 {
    margin-top: 80px !important;
  }
  .pakistan_hosting {
    margin-bottom: 50px;
  }
  .cus-pt-190 {
    padding-top: 190px;
  }

  .icon_box {
    height: 70px;
    width: 70px;
    padding-top: 12px;
  }

  .plans_types {
    padding-left: 50px;
    height: 120px;
  }

  .banner-size {
    display: unset;
  }
  .about_bnr p {
    font-size: 15px;
  }
  .about_bnr h2 {
    margin-bottom: 10px;
  }
  .align_about_text .pr-5 {
    padding-right: 0px !important;
  }
  .about_bnr {
    margin-bottom: 50px !important;
    height: 270px;
  }
  .align_about_text .pl-5 {
    padding-left: 0px !important;
  }
  .eAyARa {
    color: #005880 !important;
    font-size: inherit !important;
    line-height: inherit !important;
    background-color: transparent !important;
    box-shadow: none !important;
    border-radius: none !important;
    width: 0px !important;
    height: 0px !important;
    min-width: 0px !important;
  }
  .eWjCzc {
    color: #005880 !important;
    font-size: inherit !important;
    line-height: inherit !important;
    background-color: transparent !important;
    box-shadow: none !important;
    border-radius: none !important;
    width: 0px !important;
    height: 0px !important;
    min-width: 0px !important;
  }

  .box-border:nth-child(3) {
    border: none !important;
  }

  .modal-dialog {
    max-width: 400px;
    margin: 1.75rem auto;
  }

  .align_data h4 {
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 10px;
  }
  .align_data p {
    font-size: 15px;
    padding-right: 15px;
  }
  .domaiPrivacy {
    font-size: 10px;
  }

  .ssliderhost h2 {
    font-size: 20px;

    color: #005880;
    margin-top: 40px;
  }
  .ssliderhost h5 {
    font-size: 16px;
    font-weight: 500;
    color: #414042;
  }
  .all_plans h5 {
    font-size: 16px;
    font-weight: 500;
  }
  .why_choose_us h5 {
    font-size: 16px;
    font-weight: 500;
  }
  .align_data h4 {
    font-size: 20px;
  }
  .align_data p {
    padding: 0px;
    font-size: 16px;
  }
  .serving_center p {
    font-size: 20px;
  }
  .technical_specification h5 {
    font-size: 16px;
    font-weight: 500;
  }
  .sec-transfer-sets .setps-1 span {
    font-size: 25px;
  }
  .bordersetps {
    border: none;
  }
  /* Main Banner start */
  .banner-size {
    background-position: 22%;
    height: 400px;
    background-size: cover;
  }
  .height-adjust {
    height: 200px !important;
  }
  .premove {
    padding-top: 0px !important;
  }
  .banner-left-text h3 span {
    font-size: 24px;
  }
  .banner-left-text h3 {
    color: #414042;
    line-height: 27px;
    font-size: 18px;
    margin-bottom: 30px;
  }
  .banner-left-button {
    padding: 10px 20px;
    display: inline-block;
    font-size: 14px;
  }
  .banner-right-text h3 {
    color: #414042;
    line-height: 27px;
    font-size: 18px;
    margin-bottom: 30px;
  }
  .banner-right-text h3 span {
    font-size: 24px;
  }
  .banner-right-button {
    padding: 10px 20px;
    display: inline-block;
    font-size: 14px;
  }
  .pb-sm-25 {
    padding-bottom: 25px;
  }
  /* Main Banner ends */

  /* domain search bar starts */

  .px-6 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .get-started {
    font-size: 23px;
    margin-left: 0px;
    padding-left: 0px;
  }
  .upper_section {
    text-align: center;
  }
  .contact_datail {
    padding-right: 0;
  }
  .contact_datail .input-group {
    justify-content: center;
  }
  .ftr_social_icon h4 {
    text-align: center;
  }
  .ftr_social_icon ul {
    display: inline-flex;
  }
  .privacy p {
    text-align: center;
    margin-bottom: 8px;
  }
  .homesearchbar {
    height: 44px !important;
    font-size: 13px;
  }

  .block_text {
    font-size: 18px;
  }
  .blockquote {
    padding: 10pt 10pt 20pt 15pt;
  }
  .package-name h3 {
    font-size: 18px;
  }
  p {
    font-size: 14px;
  }
  .discount h4 {
    font-size: 18px;
  }
  .package-one-price .amount {
    font-size: 24px;
  }
  .pacakge-one ul li {
    font-size: 15px;
  }
  .fa.fa-info-circle .litooltip {
    font-size: 11px;
    width: 190px;
  }
  font > strong {
    font-size: 16px !important;
  }
  .pacakge-one button {
    font-size: 14px;
  }
  .serving_since h4 {
    font-size: 18px;
  }
  .serving_since p {
    font-size: 14px;
  }
  .serving_since .img_circle .icon {
    font-size: 60px;
  }
  .serving_since .img_circle {
    width: 80px;
    height: 80px;
  }
  /* domain search bar ends */

  /* Plan section Starts from here */
  .first-heading {
    font-size: 20px;
  }
  .second-heading {
    font-size: 14px !important;
    margin-top: 0;
    font-weight: 500 !important;
  }
  .business_hosting {
    width: 100%;
    margin-bottom: 30px;
  }
  .center_hosting h5 {
    font-size: 16px;
  }
  .scnd_hosting p {
    font-size: 20px;
  }
  .center_hosting p {
    margin-bottom: 0px;
  }
  .center_hosting {
    padding-left: 55px;
  }
  .dashed {
    display: none;
  }
  .why_us_simple h5,
  .testimonials h5 {
    font-size: 16px;
    margin-top: 0;
    font-weight: 500;
  }
  .server-text h3 {
    font-size: 20px;
    line-height: 20px;
  }
  .server-text h3 span:nth-child(1) {
    padding: 0 10px;
    margin: 5px 0;
    font-size: 20px;
    font-weight: 600;
  }
  .server-text h3 span:nth-child(2) {
    font-weight: 600;
    margin-bottom: 0;
    font-size: 20px;
  }
  .server-text h3 span:nth-child(3) {
    color: #fff;
    font-weight: 400;
  }
  .server-text a {
    border-radius: 5px;
    padding: 10px 20px;
  }
  .bg-server {
    background-image: none;
    background-color: #049bd8;
  }
  .bg-server-set {
    height: 275px;
  }
  /* Plan section Starts ends here */

  /* Why Choose Us section Start */

  .why-choose-service h4 {
    font-size: 17px;
    color: #4d4e4f;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .why-choose-service p {
    font-size: 13px;
    color: #434343;
    text-align: justify;
    text-align-last: center;
    margin-top: 0;
    font-weight: 400;
  }
  .border-left-right:nth-of-type(1),
  .border-left-right:nth-of-type(2),
  .border-left-right:nth-of-type(3) {
    margin-bottom: 0;
  }
  /* Why Choose Us section ends */

  /* Domain extraction style starts from here */
  .domain-extraction h2 {
    font-size: 20px;
  }
  .domain-extraction h5 {
    font-size: 16px;
    margin-top: 0;
    font-weight: 500;
  }
  .domain-extraction .extraction a .d-table-cell {
    font-size: 20px;
  }
  .domain-extraction .extraction span {
    font-size: 16px;
  }
  .domain-extraction .d-table-cell {
    height: 80px;
  }
  /* Domain extraction style ends here */

  /* we got more section starts from here */

  .sec-we-got-more h2 {
    font-size: 20px;
  }
  .sec-we-got-more h5 {
    font-size: 16px;
  }

  .domain-what-nav-tabs ul li button {
    padding: 15px;
    font-size: 15px;
  }
  .mb-50 {
    margin-bottom: 50px !important;
  }
  .nav-tabs {
    border: none;
  }

  .nav-tabs-price div h4 {
    font-size: 20px;
  }

  .nav-tabs-price div h5 {
    font-size: 16px;
  }

  .nav-tabs-price div h5 span {
    font-size: 20px;
  }
  .domain-what-nav-tabs .nav-tabs {
    justify-content: center;
  }
  /* we got more section ends here */

  /* section going online starts from here */

  .going-online h2 {
    font-size: 20px;
  }
  .going-online h5 {
    font-size: 16px;
    margin-top: 0;
  }
  .going-online .hosting-text p {
    font-size: 15px;
  }
  .going-online .hosting-text h5 {
    font-size: 20px;
  }
  .going-online .hosting-text button {
    width: 110px;
    font-size: 15px;
  }

  .going-online .hosting-text {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  /* section going online ends here */

  /* Section stumped starts from here */

  .sec-specfication h2 {
    font-size: 20px;
  }
  .sec-specfication h5 {
    font-size: 16px;
    font-weight: 500;
    color: #4d4e4f;
  }

  /* Section stumped ends here */

  /* Section Account migration Strats from here  */

  .sec-account-migrate h2 {
    line-height: 40px;
    margin-bottom: 15px;
    font-size: 20px;
  }

  .sec-account-migrate p {
    font-size: 16px;
  }
  .sec-account-migrate button {
    width: 160px;
    font-size: 16px;
    height: 45px;
  }
  .py-100 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .sec-account-migrate .pb-5 {
    padding-bottom: 1rem !important;
  }

  /* Section Account migration ends here  */

  /* Domain Registration section starts from here */

  .sec-domain-reg h2 {
    font-size: 20px;
  }
  .sec-domain-reg h5 {
    font-size: 16px;
    font-weight: 500;
  }
  .text-domain-reg p {
    font-size: 15px;
  }

  /* Domain Registration section ends here */

  /* faq section start from here */

  .background-alter {
    padding: 50px 0px 0px 0px;
  }
  .sec-faq h2 {
    font-size: 20px;
  }
  .sec-faq h5 {
    font-size: 16px;
  }
  .sec-faq .sec-faq-accor .accordion button {
    font-size: 14px;
    padding: 15px 0 15px 15px;
    margin-bottom: 20px;
  }

  .sec-faq .accordion-body {
    padding: 6px 1.25rem;
  }

  .sec-faq .accordion-body p {
    font-size: 15px;
    margin-bottom: 10px;
    color: #808184;
    font-weight: 400;
  }
  .faq-padding-set {
    padding: 0;
  }
  /* faq section ends here */

  /* need help section starts here */

  .sec-need_help .text-oper h4 {
    font-size: 20px;
    text-align: center;
  }
  .sec-need_help .text-oper p {
    font-size: 14px;
    padding-right: 0;
    line-height: 20px;
    margin-bottom: 30px;
    text-align: center;
  }

  .text-oper .button-first {
    font-size: 16px;
    padding: 10px;
    text-align: center;
    margin: auto;
    min-width: unset;
  }

  .text-sm-center {
    text-align: center;
  }
  .pb-sm-3 {
    /* padding-bottom: 10px; */
    padding-bottom: 25px;
  }
  .text-oper .button-sec {
    font-size: 16px;
    padding: 10px 50px;
    text-align: center;
    margin: auto;
    min-width: unset;
  }

  /* need help section ends here */

  /* Domain Renewwal section strarts from here */

  .sec-domain-renew h2 {
    font-size: 20px;
  }
  .sec-domain-renew h5 {
    font-size: 16px;
  }

  .tabs-renew .nav-tabs .nav-link.active {
    padding: 13px;
    font-size: 13px;
  }
  .tabs-renew .nav-tabs .nav-link {
    padding: 13px;
    font-size: 13px;
  }

  /* Domain Renewwal section strarts from here */

  /* Section domain transfer starts from here */

  .sec-transfer-sets .setps-1 {
    height: 90px;
    width: 100%;
    display: table;
  }
  .sec-transfer-sets .pe-0 {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
  }
  .sec-transfer-sets .setps-1 span {
    border-right: 0;
    padding: 0;
  }
  .setps-progress {
    height: 300px;
    width: 100%;
    padding: 20px 20px 0;
    display: table;
    border: none;
  }
  .ps-0 {
    padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
  }

  /* Section domain transfer ends here */

  /* section all plans includes starts*/

  .sec-allplans h2 {
    font-size: 20px;
    color: #005a7e;
    font-weight: 700;
  }
  .sec-allplans h5 {
    font-size: 16px;
    font-weight: 500;
  }

  .sec-allplans .type-plans {
    padding-left: 65px;
    height: 120px;
  }

  .sec-allplans .type-plans .icon-left-box {
    height: 70px;
    width: 70px;
    padding-top: 12px;
  }
  .sec-allplans .p-sm-set {
    padding-left: 30px;
    padding-right: 0px;
  }
  .sec-allplans .type-plans .right-data h4 {
    font-size: 22px;
    line-height: 22px;
  }

  .sec-allplans .type-plans .right-data p {
    font-size: 15px;
    padding-right: 15px;
  }

  /* section all plans includes ends */

  /* section why chooseus starts */

  .v2-why-choose-us h2 {
    font-size: 20px;
  }
  .v2-why-choose-us h5 {
    font-size: 16px;
    font-weight: 500;
  }

  .v2-servce-text p {
    font-size: 16px;
  }
  .v2-why-choose-us .v2-servce-text {
    height: 100%;
  }
  /* section why chooseus ends */

  /* section woth it starts */

  .worth-it-sec h2 {
    font-size: 20px;
  }
  .worth-it-sec h5 {
    font-size: 16px;
    font-weight: 500;
  }

  .worth-it-sec .block-power {
    margin-bottom: 10px;
  }
  .worth-it-sec .block-power h3 {
    font-size: 16px;
  }
  .worth-it-sec .padding {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  /* section worth it ends */

  /* section v2 bundle booster starts */

  .v2-bundler-booster h2 {
    font-size: 20px;
  }
  .v2-bundler-booster h5 {
    font-size: 16px;
    font-weight: 500;
  }

  .v2-bundler-booster .table .bg {
    font-size: 20px;
  }

  .v2-bundler-booster .table .detial-order td {
    font-size: 14px;
  }

  .v2-bundler-booster .table .detial-order td button {
    padding: 8px;
    font-size: 12px;
    font-weight: 500;
  }
  .detial-order .fw-bolder {
    font-weight: 500 !important;
  }

  /* section v2 bundle booster ends */

  /* section customized plan starts */

  .sec-customized-plan {
    background-image: none !important;
    height: 360px;
  }

  .sec-customzied-text h3 {
    font-size: 20px;
  }
  .sec-customzied-text p {
    font-size: 16px;
    padding: 0;
  }
  .sec-customzied-text button.livechat {
    padding: 5px 15px 5px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .sec-customzied-text button.getintouch {
    padding: 5px 15px 5px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  /* section customized plan ends */

  /* section compare plans start */

  .sec-compare-plans h2 {
    font-size: 20px;
  }
  .sec-compare-plans h5 {
    font-size: 16px;
    font-weight: 500;
  }

  .sec-compare-plans table thead tr td h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .sec-compare-plans thead tr span.packages_price {
    font-size: 25px;
  }

  .sec-compare-plans thead tr .packages_price span.prcie_year {
    font-size: 16px;
  }
  .sec-compare-plans thead tr .packages_price span.prcie_pkr {
    font-size: 16px;
  }
  .sec-compare-plans thead tr .packages_price span.ninty_nine {
    font-size: 15px;
  }
  .sec-compare-plans .orderButton {
    font-size: 15px;
  }
  .sec-compare-plans .table td {
    font-size: 14px;
  }
  /* section compare plans ends */

  /* top bar responsive starts from here  */

  .fa-phone-square:before {
    content: none !important;
  }

  /* top bar responsive ends here  */
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .dashed {
    display: none;
  }
  /* .submitammiunt{
    width: 80%;
  } */
  .box-border-sm {
    border: none !important;
  }
  .package-namepaaaa {
    /* width: 72%; */
    width: 96%;
  }
  .psetiingcart {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .krmNah {
    background-color: #005880 !important;
    box-shadow: 0 0 1px 3px #005880 !important;
  }
  .business_hosting {
    width: 48%;
    margin-left: 10px;
  }
  .center_hosting {
    padding-left: 30px;
  }
  .center_hosting p {
    margin-bottom: 0;
    font-size: 14px;
  }
  .explore_more h5 {
    font-size: 16px;
    font-weight: 500;
    margin-top: 0;
  }
  .center_hosting button {
    width: 110px;
    font-size: 15px;
  }

  /* main banner style starts from here */

  .modal-dialog {
    max-width: 400px;
    margin: 1.75rem auto;
  }
  .banner-size {
    height: 400px;
    background-position: 0%;
    height: 400px;
    background-size: cover;
  }
  .banner-left-text h3 span {
    font-size: 30px;
  }
  .banner-left-text h3 {
    color: #414042;
    line-height: 27px;
    font-size: 20px;
    margin-bottom: 30px;
  }
  .banner-left-button {
    padding: 10px 20px;
    display: inline-block;
    font-size: 16px;
  }
  .banner-right-text h3 {
    color: #414042;
    line-height: 27px;
    font-size: 20px;
    margin-bottom: 30px;
  }
  .banner-right-text h3 span {
    font-size: 30px;
  }
  .banner-right-button {
    padding: 10px 20px;
    display: inline-block;
    font-size: 16px;
  }
  .pb-sm-25 {
    padding-bottom: 25px;
  }
  /* main banner style ends here */

  /* domain search bar starts */

  .px-6 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .get-started {
    font-size: 30px;
    margin-left: 0px;
    padding-left: 0px;
  }

  /* domain search bar ends */

  /* Plan section Starts from here */
  .first-heading {
    font-size: 25px;
  }
  .why_us_simple h2 {
    font-size: 25px;
  }
  .testimonials h2 {
    font-size: 25px;
  }
  .second-heading {
    font-size: 16px;
    margin-top: 0;
    font-weight: 500;
  }
  .why_us_simple h5,
  .testimonials h5 {
    font-size: 16px;
    margin-top: 0;
    font-weight: 500;
  }
  .server-text h3 {
    font-size: 25px;
    line-height: 35px;
  }
  .server-text h3 span:nth-child(1) {
    padding: 0 10px;
    margin: 5px 0;
    font-size: 22px;
    font-weight: 600;
  }
  .server-text h3 span:nth-child(2) {
    font-weight: 600;
    margin-bottom: 0;
    font-size: 22px;
  }
  .server-text h3 span:nth-child(3) {
    color: #fff;
    font-weight: 400;
  }
  .server-text a {
    border-radius: 5px;
    padding: 10px 20px;
  }
  .bg-server {
    background-image: none;
    background-color: #049bd8;
  }
  .bg-server-set {
    height: 275px;
  }
  /* Plan section Starts ends here */

  /* Why Choose Us section Start */
  .why-choose-us h2 {
    font-size: 25px;
  }
  .why-choose-us h5 {
    font-size: 16px;
    margin-top: 0;
    font-weight: 500;
  }
  .why-choose-service h4 {
    font-size: 17px;
    color: #4d4e4f;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .why-choose-service p {
    font-size: 13px;
    color: #434343;
    text-align: justify;
    text-align-last: center;
    margin-top: 0;
    font-weight: 400;
  }
  .border-left-right:nth-of-type(1),
  .border-left-right:nth-of-type(2),
  .border-left-right:nth-of-type(3) {
    margin-bottom: 0;
  }
  /* Why Choose Us section ends */

  /* Domain extraction style starts from here */
  .domain-extraction h2 {
    font-size: 25px;
  }
  .domain-extraction h5 {
    font-size: 16px;
    margin-top: 0;
    font-weight: 500;
  }
  .domain-extraction .extraction a .d-table-cell {
    font-size: 20px;
  }
  .domain-extraction .extraction span {
    font-size: 16px;
  }
  .domain-extraction .d-table-cell {
    height: 80px;
  }
  /* Domain extraction style ends here */

  /* we got more section starts from here */

  .sec-we-got-more h2 {
    font-size: 25px;
  }
  .sec-we-got-more h5 {
    font-size: 16px;
  }

  .domain-what-nav-tabs ul li button {
    padding: 15px;
    font-size: 20px;
  }
  .nav-tabs {
    border: none;
  }

  .nav-tabs-price div h4 {
    font-size: 20px;
  }

  .nav-tabs-price div h5 {
    font-size: 16px;
  }

  .nav-tabs-price div h5 span {
    font-size: 20px;
  }
  .domain-what-nav-tabs .nav-tabs {
    justify-content: center;
  }
  /* we got more section ends here */

  /* section going online starts from here */

  .going-online h2 {
    font-size: 25px;
  }
  .going-online h5 {
    font-size: 16px;
    margin-top: 0;
  }
  .going-online .hosting-text p {
    font-size: 15px;
  }
  .going-online .hosting-text h5 {
    font-size: 20px;
  }
  .going-online .hosting-text button {
    width: 110px;
    font-size: 15px;
  }
  /* section going online ends here */

  /* Section stumped starts from here */

  .sec-specfication h2 {
    font-size: 25px;
  }
  .sec-specfication h5 {
    font-size: 16px;
    font-weight: 500;
    color: #4d4e4f;
  }

  /* Section stumped ends here */

  /* Section Account migration Strats from here  */

  .sec-account-migrate h2 {
    line-height: 40px;
    margin-bottom: 15px;
    font-size: 25px;
  }

  .sec-account-migrate p {
    font-size: 16px;
  }
  .sec-account-migrate button {
    width: 160px;
    font-size: 16px;
    height: 45px;
  }
  .py-100 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .sec-account-migrate .pb-5 {
    padding-bottom: 1rem !important;
  }

  /* Section Account migration ends here  */

  /* Domain Registration section starts from here */

  .sec-domain-reg h2 {
    font-size: 30px;
  }
  .sec-domain-reg h5 {
    font-size: 16px;
    font-weight: 500;
  }
  .text-domain-reg p {
    font-size: 15px;
  }

  /* Domain Registration section ends here */

  /* faq section start from here */

  .background-alter {
    padding: 50px 40px;
  }
  .sec-faq h2 {
    font-size: 25px;
  }
  .sec-faq h5 {
    font-size: 16px;
  }
  .sec-faq .sec-faq-accor .accordion button {
    font-size: 16px;
    padding: 15px 0 15px 15px;
    margin-bottom: 20px;
  }

  .sec-faq .accordion-body {
    padding: 6px 1.25rem;
  }

  .sec-faq .accordion-body p {
    font-size: 15px;
    margin-bottom: 10px;
    color: #808184;
    font-weight: 400;
  }
  .faq-padding-set {
    padding: 0;
  }
  /* faq section ends here */

  /* need help section starts here */

  .sec-need_help .text-oper h4 {
    font-size: 25px;
    text-align: center;
  }
  .sec-need_help .text-oper p {
    font-size: 16px;
    padding-right: 0;
    line-height: 20px;
    margin-bottom: 30px;
    text-align: center;
  }

  .text-oper .button-first {
    font-size: 16px;
    padding: 10px;
    text-align: center;
    margin: auto;
    min-width: unset;
  }

  .text-sm-center {
    text-align: center;
  }
  .pb-sm-3 {
    padding-bottom: 10px;
  }
  .text-oper .button-sec {
    font-size: 16px;
    padding: 10px 50px;
    text-align: center;
    margin: auto;
    min-width: unset;
  }

  /* need help section ends here */

  /* Domain Renewwal section strarts from here */

  .sec-domain-renew h2 {
    font-size: 25px;
  }
  .sec-domain-renew h5 {
    font-size: 16px;
  }

  .tabs-renew .nav-tabs .nav-link.active {
    padding: 13px;
    font-size: 13px;
  }
  .tabs-renew .nav-tabs .nav-link {
    padding: 13px;
    font-size: 13px;
  }

  /* Domain Renewwal section strarts from here */

  /* Section domain transfer starts from here */

  .sec-transfer-sets .setps-1 {
    height: 60px;
    width: 100%;
    display: table;
  }
  .sec-transfer-sets .pe-0 {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
  }
  .sec-transfer-sets .setps-1 span {
    border-right: 0;
    padding: 0;
    font-size: 25px;
  }
  .setps-progress {
    height: 100%;
    width: 100%;
    padding: 20px 20px 0;
    display: table;
  }
  .ps-0 {
    padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
  }

  /* Section domain transfer ends here */

  /* section all plans includes starts*/

  .sec-allplans h2 {
    font-size: 25px;
    color: #005a7e;
    font-weight: 700;
  }
  .sec-allplans h5 {
    font-size: 16px;
    font-weight: 500;
  }

  .sec-allplans .type-plans .icon-left-box {
    height: 70px;
    width: 70px;
    padding-top: 12px;
  }
  .sec-allplans .type-plans {
    width: 100%;
  }
  .sec-allplans .p-sm-set {
    margin: auto;
  }
  .sec-allplans .type-plans .right-data h4 {
    font-size: 22px;
    line-height: 22px;
  }

  .sec-allplans .type-plans .right-data p {
    font-size: 15px;
    padding-right: 15px;
  }

  /* section all plans includes ends */

  /* section why chooseus starts */

  .v2-why-choose-us h2 {
    font-size: 25px;
  }
  .v2-why-choose-us h5 {
    font-size: 16px;
    font-weight: 500;
  }

  .v2-servce-text p {
    font-size: 16px;
  }
  .v2-why-choose-us .v2-servce-text {
    height: 100%;
  }
  /* section why chooseus ends */

  /* section woth it starts */

  .worth-it-sec h2 {
    font-size: 25px;
  }
  .worth-it-sec h5 {
    font-size: 16px;
    font-weight: 500;
  }

  .worth-it-sec .block-power {
    margin-bottom: 10px;
  }
  .worth-it-sec .block-power h3 {
    font-size: 20px;
  }
  .worth-it-sec .padding {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  /* section worth it ends */

  /* section v2 bundle booster starts */

  .v2-bundler-booster h2 {
    font-size: 25px;
  }
  .v2-bundler-booster h5 {
    font-size: 16px;
    font-weight: 500;
  }

  .v2-bundler-booster .table .bg {
    font-size: 20px;
  }

  .v2-bundler-booster .table .detial-order td {
    font-size: 18px;
  }

  .v2-bundler-booster .table .detial-order td button {
    padding: 8px 15px;
    font-size: 15px;
    font-weight: 500;
  }
  .detial-order .fw-bolder {
    font-weight: 600 !important;
  }

  /* section v2 bundle booster ends */

  /* section customized plan starts */

  .sec-customized-plan {
    background-image: none !important;
    background-color: #005272 !important;
    height: 360px;
  }

  .sec-customzied-text h3 {
    font-size: 25px;
  }

  .sec-customzied-text p {
    font-size: 16px;
    padding: 0;
  }

  .sec-customzied-text button.livechat {
    padding: 5px 15px 5px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .sec-customzied-text button.getintouch {
    padding: 5px 15px 5px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  /* section customized plan ends */

  /* section compare plans start */

  .sec-compare-plans h2 {
    font-size: 25px;
  }
  .sec-compare-plans h5 {
    font-size: 16px;
    font-weight: 500;
  }

  .sec-compare-plans table thead tr td h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .sec-compare-plans thead tr span.packages_price {
    font-size: 25px;
  }

  .sec-compare-plans thead tr .packages_price span.prcie_year {
    font-size: 16px;
  }
  .sec-compare-plans thead tr .packages_price span.prcie_pkr {
    font-size: 16px;
  }
  .sec-compare-plans thead tr .packages_price span.ninty_nine {
    font-size: 15px;
  }
  .sec-compare-plans .orderButton {
    font-size: 14px;
    padding: 10px 15px;
  }
  .sec-compare-plans .table td {
    font-size: 14px;
  }
  /* section compare plans ends */
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .center_hosting {
    padding-left: 30px;
  }
  /* .submitammiunt{
    width: 80%;
  } */
  .package-namepaaaa {
    /* width: 72%; */
    width: 96%;
  }
  .modal-backdrop.show {
    display: none;
  }
  .krmNah {
    background-color: #005880 !important;
    box-shadow: 0 0 1px 3px #005880 !important;
  }
  .premove {
    padding-top: 0px !important;
  }
  .center_hosting p {
    margin-bottom: 0;
  }
  .explore_more h5 {
    font-size: 18px;
    font-weight: 500;
    margin-top: 0;
  }
  .center_hosting button {
    width: 110px;
    font-size: 15px;
  }

  .dashed {
    visibility: hidden;
  }

  .modal-dialog {
    max-width: 400px;
    margin: 1.75rem auto;
  }

  /* main banner style starts from here */
  .banner-size {
    height: 330px;
    background-size: cover;
  }
  .pb-md-5 {
    margin-bottom: 30px !important;
  }
  .banner-left-text h3 span {
    font-size: 30px;
  }
  .banner-left-text h3 {
    color: #414042;
    line-height: 27px;
    font-size: 20px;
    margin-bottom: 30px;
  }
  .banner-left-button {
    padding: 10px 20px;
    display: inline-block;
    font-size: 16px;
  }
  .banner-right-text h3 {
    color: #414042;
    line-height: 27px;
    font-size: 20px;
    margin-bottom: 30px;
  }
  .banner-right-text h3 span {
    font-size: 30px;
  }
  .banner-right-button {
    padding: 10px 20px;
    display: inline-block;
    font-size: 16px;
  }
  .pb-sm-25 {
    padding-bottom: 25px;
  }
  /* main banner style ends here */

  /* domain search bar starts */

  .px-6 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .get-started {
    font-size: 30px;
    margin-left: 20px;
    padding: 10px;
  }
  .domain-searching .domian-category {
    font-size: 16px;
  }
  .domain-searching .domian-price {
    font-size: 13px;
  }
  .domain-searching .domain-details ul {
    justify-content: space-between;
  }
  /* domain search bar ends */

  /* Plan section Starts from here */
  .first-heading {
    font-size: 30px;
  }
  .second-heading {
    font-size: 18px;
    margin-top: 0;
    font-weight: 500;
  }
  .server-text h3 {
    font-size: 25px;
    line-height: 35px;
  }
  .server-text h3 span:nth-child(1) {
    padding: 0 10px;
    margin: 5px 0;
    font-size: 22px;
    font-weight: 600;
  }
  .server-text h3 span:nth-child(2) {
    font-weight: 600;
    margin-bottom: 0;
    font-size: 22px;
  }
  .server-text h3 span:nth-child(3) {
    color: #fff;
    font-weight: 400;
  }
  .server-text a {
    border-radius: 5px;
    padding: 10px 20px;
  }
  .bg-server {
    background-image: none;
    background-color: #049bd8;
  }
  .bg-server-set {
    height: 275px;
  }
  /* Plan section Starts ends here */

  /* Why Choose Us section Start */
  .why-choose-us h2 {
    font-size: 30px;
  }
  .why-choose-us h5 {
    font-size: 18px;
    margin-top: 0;
    font-weight: 500;
  }
  .why-choose-service h4 {
    font-size: 17px;
    color: #4d4e4f;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .why-choose-service p {
    font-size: 13px;
    color: #434343;
    text-align: justify;
    text-align-last: center;
    margin-top: 0;
    font-weight: 400;
  }
  .border-left-right:nth-of-type(1),
  .border-left-right:nth-of-type(2),
  .border-left-right:nth-of-type(3) {
    margin-bottom: 0;
  }
  /* Why Choose Us section ends */

  /* Domain extraction style starts from here */
  .domain-extraction h2 {
    font-size: 30px;
  }
  .domain-extraction h5 {
    font-size: 18px;
    margin-top: 0;
    font-weight: 500;
  }
  .domain-extraction .extraction a .d-table-cell {
    font-size: 30px;
  }
  .domain-extraction .extraction span {
    font-size: 18px;
  }
  .domain-extraction .d-table-cell {
    height: 80px;
  }
  /* Domain extraction style ends here */

  /* we got more section starts from here */

  .sec-we-got-more h2 {
    font-size: 30px;
  }
  .sec-we-got-more h5 {
    font-size: 18px;
  }

  .domain-what-nav-tabs ul li button {
    padding: 15px;
    font-size: 20px;
  }
  .nav-tabs {
    border: none;
  }

  .nav-tabs-price div h4 {
    font-size: 20px;
  }

  .nav-tabs-price div h5 {
    font-size: 16px;
  }

  .nav-tabs-price div h5 span {
    font-size: 20px;
  }
  .domain-what-nav-tabs .nav-tabs {
    justify-content: center;
  }
  /* we got more section ends here */

  /* section going online starts from here */

  .going-online h2 {
    font-size: 30px;
  }
  .going-online h5 {
    font-size: 18px;
    margin-top: 0;
  }
  .going-online .hosting-text p {
    font-size: 15px;
  }
  .going-online .hosting-text h5 {
    font-size: 20px;
  }
  .going-online .hosting-text button {
    width: 110px;
    font-size: 15px;
  }
  /* section going online ends here */

  /* Section stumped starts from here */

  .sec-specfication h2 {
    font-size: 30px;
  }
  .sec-specfication h5 {
    font-size: 18px;
    font-weight: 500;
    color: #4d4e4f;
  }

  /* Section stumped ends here */

  /* Section Account migration Strats from here  */

  .sec-account-migrate h2 {
    line-height: 40px;
    margin-bottom: 15px;
    font-size: 30px;
  }

  .sec-account-migrate p {
    font-size: 18px;
  }
  .sec-account-migrate button {
    width: 160px;
    font-size: 16px;
    height: 35px;
  }
  .py-100 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .sec-account-migrate .pb-5 {
    padding-bottom: 1rem !important;
  }

  /* Section Account migration ends here  */

  /* Domain Registration section starts from here */

  .sec-domain-reg h2 {
    font-size: 35px;
  }
  .sec-domain-reg h5 {
    font-size: 18px;
    font-weight: 500;
  }
  .text-domain-reg p {
    font-size: 15px;
  }

  /* Domain Registration section ends here */

  /* faq section start from here */

  .background-alter {
    padding: 70px 60px;
  }
  .sec-faq h2 {
    font-size: 30px;
  }
  .sec-faq h5 {
    font-size: 18px;
  }
  .sec-faq .sec-faq-accor .accordion button {
    font-size: 18px;
    padding: 15px 0 15px 25px;
    margin-bottom: 20px;
  }

  .sec-faq .accordion-body {
    padding: 6px 1.25rem;
  }

  .sec-faq .accordion-body p {
    font-size: 15px;
    margin-bottom: 10px;
    color: #808184;
    font-weight: 400;
  }
  .faq-padding-set {
    padding: 0;
  }
  /* faq section ends here */

  /* need help section starts here */

  .sec-need_help .text-oper h4 {
    font-size: 30px;
  }
  .sec-need_help .text-oper p {
    font-size: 18px;
    padding-right: 0;
    line-height: 20px;
    margin-bottom: 30px;
  }

  .text-oper .button-first {
    font-size: 16px;
    padding: 10px;
    float: left;
    margin: auto;
    min-width: unset;
  }

  .text-sm-center {
    text-align: center;
  }
  .pb-sm-3 {
    padding-bottom: 10px;
  }
  .text-oper .button-sec {
    font-size: 16px;
    padding: 10px 50px;
    text-align: center;
    margin: auto;
    min-width: unset;
  }

  /* need help section ends here */

  /* Domain Renewwal section strarts from here */

  .sec-domain-renew h2 {
    font-size: 30px;
  }
  .sec-domain-renew h5 {
    font-size: 18px;
  }

  .tabs-renew .nav-tabs .nav-link.active {
    padding: 20px 30px;
    font-size: 14px;
  }
  .tabs-renew .nav-tabs .nav-link {
    padding: 20px 30px;
    font-size: 14px;
  }

  /* Domain Renewwal section strarts from here */

  /* Section domain transfer starts from here */

  .sec-transfer-sets .setps-1 {
    height: 170px;
    width: 100%;
    padding: 16px;
    display: table;
  }
  .sec-transfer-sets .pe-0 {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
  }
  .sec-transfer-sets .setps-1 span {
    border-right: 0;
    padding: 0;
    font-size: 35px;
  }
  .setps-progress {
    height: 100%;
    width: 100%;
    padding: 0 45px 0 60px;
    display: table;
  }

  /* Section domain transfer ends here */

  /* section all plans includes starts*/

  .sec-allplans h2 {
    font-size: 30px;
    color: #005a7e;
    font-weight: 700;
  }
  .sec-allplans h5 {
    font-size: 16px;
    font-weight: 500;
  }
  .mr-md-30 {
    margin-right: 30px;
  }

  .sec-allplans .type-plans .icon-left-box {
    height: 70px;
    width: 70px;
    padding-top: 12px;
  }
  .sec-allplans .type-plans {
    width: 100%;
    padding-left: 60px;
  }
  .sec-allplans .p-sm-set {
    margin: auto;
    justify-content: center;
  }
  .sec-allplans .type-plans .right-data h4 {
    font-size: 22px;
    line-height: 22px;
  }

  .sec-allplans .type-plans .right-data p {
    font-size: 15px;
    padding-right: 15px;
  }

  /* section all plans includes ends */

  /* section bundle booster start from here */

  .bundle-booster h2 {
    font-size: 30px;
  }
  .bundle-booster h5 {
    font-size: 18px;
    font-weight: 500;
  }
  .bundel-booster-tabs-set .nav-tabs .nav-link {
    font-size: 16px;
  }

  .bundel-booster-tabs-set .tab-content .tab-pane h4 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .bundel-booster-tabs-set .tab-content .tab-pane p {
    font-size: 16px;
  }
  .bundel-booster-tabs-set .tab-content .tab-pane {
    height: 320px;
  }
  /* section bundle booster ends here */

  /* section why chooseus starts */

  .v2-why-choose-us h2 {
    font-size: 30px;
  }
  .v2-why-choose-us h5 {
    font-size: 18px;
    font-weight: 500;
  }

  .v2-servce-text p {
    font-size: 18px;
  }
  .v2-why-choose-us .v2-servce-text {
    height: 100%;
  }
  /* section why chooseus ends */

  /* section woth it starts */

  .worth-it-sec h2 {
    font-size: 30px;
  }
  .worth-it-sec h5 {
    font-size: 18px;
    font-weight: 500;
  }
  .worth-it-sec .block-power p {
    font-size: 14px;
  }
  .worth-it-sec .block-power {
    margin-bottom: 10px;
  }
  .worth-it-sec .block-power h3 {
    font-size: 20px;
  }
  .worth-it-sec .padding {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  /* section worth it ends */

  /* section v2 bundle booster starts */

  .v2-bundler-booster h2 {
    font-size: 30px;
  }
  .v2-bundler-booster h5 {
    font-size: 18px;
    font-weight: 500;
  }

  .v2-bundler-booster .table .bg {
    font-size: 20px;
  }

  .v2-bundler-booster .table .detial-order td {
    font-size: 18px;
  }

  .v2-bundler-booster .table .detial-order td button {
    padding: 8px 15px;
    font-size: 15px;
    font-weight: 500;
  }
  .detial-order .fw-bolder {
    font-weight: 600 !important;
  }

  /* section v2 bundle booster ends */

  /* section customized plan starts */

  .sec-customized-plan {
    background-image: none !important;
    background-color: #005272 !important;
    height: 360px;
  }

  .sec-customzied-text h3 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .sec-customzied-text p {
    font-size: 18px;
    padding: 0;
  }

  .sec-customzied-text button.livechat {
    padding: 5px 15px 5px;
    margin-right: 10px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .sec-customzied-text button.getintouch {
    padding: 5px 15px 5px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  /* section customized plan ends */

  /* section compare plans start */

  .sec-compare-plans h2 {
    font-size: 25px;
  }
  .sec-compare-plans h5 {
    font-size: 16px;
    font-weight: 500;
  }

  .sec-compare-plans table thead tr td h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .sec-compare-plans thead tr span.packages_price {
    font-size: 25px;
  }

  .sec-compare-plans thead tr .packages_price span.prcie_year {
    font-size: 16px;
  }
  .sec-compare-plans thead tr .packages_price span.prcie_pkr {
    font-size: 16px;
  }
  .sec-compare-plans thead tr .packages_price span.ninty_nine {
    font-size: 15px;
  }
  .sec-compare-plans .orderButton {
    font-size: 14px;
    padding: 10px 15px;
  }
  .sec-compare-plans .table td {
    font-size: 14px;
  }
  /* section compare plans ends */

  /* main navbar responsiv starts from here  */

  .main_dropdown {
    margin-left: 0px;
  }
  .force-container {
    padding-right: 0;
    padding-left: 0;
  }
  .navigation ul li a {
    font-size: 14px;
  }
  .navigation ul li a .fa-caret-down {
    padding-left: 8px;
  }

  /* main navbar responsiv ends here  */
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .homesearchbar {
    height: 46px !important;
  }
  .block_text {
    height: 190px;
  }
  .package-namepaaaa {
    /* width: 72%; */
    width: 98%;
  }
  /* .submitammiunt{
    width: 80%;
  } */
  .block_text {
    font-size: 13pt;
  }
  .rec-arrow-left,
  .rec-arrow-right {
    font-size: 25px !important;
    font-weight: 200;
  }
  .passion_text h3 {
    width: 60%;
  }
  .package-name h3 {
    font-size: 22px;
  }
  .discount h4 {
    font-size: 22px;
  }
  .package-one-price .amount {
    font-size: 28px;
  }
  .pacakge-one ul li {
    font-size: 16px;
  }
  .pacakge-one ul li font strong {
    font-size: 18px !important;
  }
  .serving_since h4 {
    font-size: 23px;
  }
  .serving_since p {
    font-size: 15px;
  }
  .cross-button {
    font-size: 14px;
  }
  .sm-w-50 {
    width: 68%;
  }
  .button-sigup {
    width: 25%;
  }
  .about_bnr p {
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .sm-mt-1 {
    margin-top: 1rem !important;
  }
  .serving_center p {
    font-size: 25px;
  }
  .adjust-width-domainName {
    width: 120px !important;
  }
  .adjust-width {
    width: 120px !important;
  }
  .about_bnr {
    height: 290px;
  }
  .about_bnr h2 {
    font-size: 35px;
    margin-bottom: 0px;
  }
  .serving_since .img_circle {
    width: 90px;
    height: 90px;
    line-height: 65px;
    padding: 6px;
  }
  .mb-50 {
    margin-bottom: 70px;
  }
  .height-adjust {
    height: 290px !important;
  }
  .color-b {
    color: #414042;
  }
  .pt-100 {
    padding-top: 70px;
  }
  .logo a img {
    max-width: 55%;
  }
  .align_about_text h3 {
    font-size: 35px;
  }
  .md-mb-70 {
    margin-bottom: 70px !important;
  }
  .secure_server h5 {
    font-size: 23px;
  }
  .secure_server p {
    font-size: 14px;
  }
  .secure_server .icon {
    font-size: 90px;
  }
  .img-fluid {
    width: 75%;
  }
  .center_hosting {
    padding-left: 40px;
  }

  .center_hosting p {
    font-size: 16px;
  }
  .explore_more h5 {
    font-size: 20px;
    margin-top: 0;
  }
  .center_hosting button {
    width: 110px;
    font-size: 15px;
  }
  .dashed {
    visibility: hidden;
  }

  .modal-dialog {
    max-width: 400px;
    margin: 1.75rem auto;
  }

  .plans_types {
    padding-left: 60px;
  }
  .icon_box {
    height: 70px;
    width: 70px;
    padding-top: 10px;
  }
  .align_data h4 {
    font-size: 20px;
    line-height: 22px;
  }
  .align_data p {
    font-size: 16px;
    padding-right: 20px;
  }

  /* main banner style starts from here */
  .banner-size {
    height: 410px;
    background-size: cover;
  }
  .pb-md-5 {
    margin-bottom: 30px !important;
  }
  .banner-left-text h3 span {
    font-size: 30px;
  }
  .banner-left-text h3 {
    color: #414042;
    line-height: 35px;
    font-size: 25px;
    margin-bottom: 30px;
  }
  .banner-left-button {
    padding: 10px 20px;
    display: inline-block;
    font-size: 16px;
  }
  .banner-right-text h3 {
    color: #414042;
    line-height: 35px;
    font-size: 25px;
    margin-bottom: 30px;
  }
  .banner-right-text h3 span {
    font-size: 30px;
  }
  .banner-right-button {
    padding: 10px 20px;
    display: inline-block;
    font-size: 16px;
  }
  .pb-sm-25 {
    padding-bottom: 25px;
  }
  /* main banner style ends here */

  /* domain search bar starts */

  .px-6 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .get-started {
    font-size: 20px;
    margin-left: 20px;
    padding: 10px;
  }
  .domain-searching .domian-category {
    font-size: 18px;
  }
  .domain-searching .domains_price {
    font-size: 14px;
    padding: 0;
    color: #4d4e4f;
    font-weight: 600;
  }
  /* domain search bar ends */

  /* Plan section Starts from here */
  .first-heading {
    font-size: 35px;
  }
  .second-heading {
    font-size: 18px !important;
    margin-top: 0 !important;
    font-weight: 500 !important;
  }
  .server-text h3 {
    font-size: 32px;
    line-height: 35px;
  }
  .server-text h3 span:nth-child(1) {
    font-size: 32px;
    padding: 10px;
    margin: 10px 0;
    font-weight: 600;
  }
  .server-text h3 span:nth-child(2) {
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 32px;
  }
  .server-text h3 span:nth-child(3) {
    color: #fff;
    font-weight: 400;
  }
  .server-text a {
    border-radius: 5px;
    padding: 10px 20px;
  }
  .bg-server {
    background-image: none;
    background-color: #049bd8;
  }
  .bg-server-set {
    height: 410px;
  }
  /* Plan section Starts ends here */

  /* Why Choose Us section Start */
  .why-choose-us h2 {
    font-size: 35px;
  }
  .why-choose-us h5 {
    font-size: 20px;
    margin-top: 0;
    font-weight: 500;
  }
  .why-choose-service h4 {
    font-size: 18px;
    color: #4d4e4f;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .why-choose-service p {
    font-size: 14px;
    color: #434343;
    text-align: justify;
    text-align-last: center;
    margin-top: 0;
    font-weight: 400;
  }
  .border-left-right:nth-of-type(1),
  .border-left-right:nth-of-type(2),
  .border-left-right:nth-of-type(3) {
    margin-bottom: 0;
  }
  /* Why Choose Us section ends */

  /* Domain extraction style starts from here */
  .domain-extraction h2 {
    font-size: 35px;
  }
  .domain-extraction h5 {
    font-size: 20px;
    margin-top: 0;
    font-weight: 500;
  }
  .domain-extraction .extraction a .d-table-cell {
    font-size: 35px;
  }
  .domain-extraction .extraction span {
    font-size: 21px;
  }
  .domain-extraction .d-table-cell {
    height: 110px;
  }
  /* Domain extraction style ends here */

  /* we got more section starts from here */

  .sec-we-got-more h2 {
    font-size: 35px;
  }
  .sec-we-got-more h5 {
    font-size: 20px;
  }

  .domain-what-nav-tabs ul li button {
    padding: 20px 30px;
    font-size: 20px;
  }
  .nav-tabs {
    border: none;
  }

  .nav-tabs-price div h4 {
    font-size: 25px;
  }

  .nav-tabs-price div h5 {
    font-size: 17px;
  }

  .nav-tabs-price div h5 span {
    font-size: 20px;
  }

  /* we got more section ends here */

  /* section going online starts from here */

  .going-online h2 {
    font-size: 35px;
  }
  .going-online h5 {
    font-size: 20px;
    margin-top: 0;
  }
  .going-online .hosting-text p {
    font-size: 15px;
  }
  .going-online .hosting-text h5 {
    font-size: 20px;
  }
  .going-online .hosting-text button {
    width: 110px;
    font-size: 15px;
  }
  /* section going online ends here */

  /* Section stumped starts from here */

  .sec-specfication h2 {
    font-size: 35px;
  }
  .sec-specfication h5 {
    font-size: 20px;
    font-weight: 500;
    color: #4d4e4f;
  }

  /* Section stumped ends here */

  /* Section Account migration Strats from here  */

  .sec-account-migrate h2 {
    line-height: 45px;
    margin-bottom: 15px;
    font-size: 35px;
  }

  .sec-account-migrate p {
    font-size: 20px;
  }
  .sec-account-migrate button {
    width: 160px;
    font-size: 16px;
    height: 40px;
  }
  .py-100 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .sec-account-migrate .pb-5 {
    padding-bottom: 1rem !important;
  }

  /* Section Account migration ends here  */

  /* Domain Registration section starts from here */

  .sec-domain-reg h2 {
    font-size: 40px;
  }
  .sec-domain-reg h5 {
    font-size: 20px;
    font-weight: 500;
  }
  .text-domain-reg p {
    font-size: 15px;
  }

  /* Domain Registration section ends here */

  /* faq section start from here */

  .background-alter {
    padding: 70px 60px;
  }
  .sec-faq h2 {
    font-size: 35px;
  }
  .sec-faq h5 {
    font-size: 20px;
  }
  .sec-faq .sec-faq-accor .accordion button {
    font-size: 20px;
    padding: 10px 0 10px 25px;
    margin-bottom: 20px;
  }

  .sec-faq .accordion-body {
    padding: 6px 1.25rem;
  }

  .sec-faq .accordion-body p {
    font-size: 15px;
    margin-bottom: 10px;
    color: #414042;
    font-weight: 400;
  }
  .faq-padding-set {
    padding: 0;
  }
  /* faq section ends here */

  /* need help section starts here */

  .sec-need_help .text-oper h4 {
    font-size: 30px;
  }
  .sec-need_help .text-oper p {
    font-size: 18px;
    padding-right: 0;
    line-height: 20px;
    margin-bottom: 30px;
  }

  .text-oper .button-first {
    font-size: 18px;
    padding: 15px 25px;
    float: left;
    margin: auto;
    min-width: unset;
  }

  .text-sm-center {
    text-align: center;
  }
  .pb-sm-3 {
    padding-bottom: 10px;
  }
  .text-oper .button-sec {
    font-size: 18px;
    padding: 15px 25px;
    text-align: center;
    margin: auto;
    min-width: unset;
  }

  /* need help section ends here */

  /* Domain Renewwal section strarts from here */

  .sec-domain-renew h2 {
    font-size: 35px;
  }
  .sec-domain-renew h5 {
    font-size: 20px;
  }

  .tabs-renew .nav-tabs .nav-link.active {
    padding: 20px 30px;
    font-size: 18px;
  }
  .tabs-renew .nav-tabs .nav-link {
    padding: 20px 30px;
    font-size: 18px;
  }

  /* Domain Renewwal section strarts from here */

  /* Section domain transfer starts from here */

  .sec-transfer-sets .setps-1 {
    height: 170px;
    width: 100%;
    padding: 16px;
    display: table;
  }

  .sec-transfer-sets .setps-1 span {
    border-right: 0;
    padding: 0;
    font-size: 35px;
    font-weight: 500;
  }
  .setps-progress {
    height: 100%;
    width: 100%;
    padding: 0 45px 0 60px;
    display: table;
  }

  /* Section domain transfer ends here */

  /* section all plans includes starts*/

  .sec-allplans h2 {
    font-size: 35px;
    color: #005a7e;
    font-weight: 700;
  }
  .sec-allplans h5 {
    font-size: 20px;
    font-weight: 500;
  }
  .mr-md-30 {
    margin-right: 30px;
  }

  .sec-allplans .type-plans .icon-left-box {
    height: 70px;
    width: 70px;
    padding-top: 12px;
  }
  .sec-allplans .type-plans {
    width: 100%;
    padding-left: 60px;
  }
  .sec-allplans .p-sm-set {
    margin: auto;
    justify-content: center;
  }
  .sec-allplans .type-plans .right-data h4 {
    font-size: 22px;
    line-height: 22px;
  }

  .sec-allplans .type-plans .right-data p {
    font-size: 15px;
    padding-right: 15px;
  }

  /* section all plans includes ends */

  /* section bundle booster start from here */

  .bundle-booster h2 {
    font-size: 35px;
  }
  .bundle-booster h5 {
    font-size: 20px;
    font-weight: 500;
  }
  .bundel-booster-tabs-set .nav-tabs .nav-link {
    font-size: 20px;
  }

  .bundel-booster-tabs-set .tab-content .tab-pane h4 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .bundel-booster-tabs-set .tab-content .tab-pane p {
    font-size: 17px;
  }
  .bundel-booster-tabs-set .tab-content .tab-pane {
    height: 370px;
  }
  /* section bundle booster ends here */

  /* section why chooseus starts */

  .v2-why-choose-us h2 {
    font-size: 35px;
  }
  .v2-why-choose-us h5 {
    font-size: 20px;
    font-weight: 500;
  }

  .v2-servce-text p {
    font-size: 20px;
  }
  .v2-why-choose-us .v2-servce-text {
    height: 100%;
  }
  /* section why chooseus ends */

  /* section woth it starts */

  .worth-it-sec h2 {
    font-size: 35px;
  }
  .worth-it-sec h5 {
    font-size: 20px;
    font-weight: 500;
  }
  .worth-it-sec .block-power p {
    font-size: 16px;
  }
  .worth-it-sec .block-power {
    margin-bottom: 10px;
  }
  .worth-it-sec .block-power h3 {
    font-size: 20px;
    margin-bottom: 0;
  }
  .worth-it-sec .padding {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  /* section worth it ends */

  /* section v2 bundle booster starts */

  .v2-bundler-booster h2 {
    font-size: 35px;
  }
  .v2-bundler-booster h5 {
    font-size: 20px;
    font-weight: 500;
  }

  .v2-bundler-booster .table .bg {
    font-size: 25px;
  }

  .v2-bundler-booster .table .detial-order td {
    font-size: 18px;
  }

  .v2-bundler-booster .table .detial-order td button {
    padding: 8px 30px;
    font-size: 15px;
    font-weight: 400;
  }
  .detial-order .fw-bolder {
    font-weight: 600 !important;
  }

  /* section v2 bundle booster ends */

  /* section customized plan starts */

  .sec-customized-plan {
    background-image: none !important;
    /* background-color: #005272 !important; */
    height: 360px;
  }

  .sec-customzied-text h3 {
    font-size: 35px;
    margin-bottom: 20px;
  }

  .sec-customzied-text p {
    font-size: 20px;
    padding: 0;
  }

  .sec-customzied-text button.livechat {
    padding: 5px 15px 5px;
    margin-right: 10px;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .sec-customzied-text button.getintouch {
    padding: 5px 15px 5px;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  /* section customized plan ends */

  /* section compare plans start */

  .sec-compare-plans h2 {
    font-size: 35px;
  }
  .sec-compare-plans h5 {
    font-size: 20px;
    font-weight: 500;
  }

  .sec-compare-plans table thead tr td h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .sec-compare-plans thead tr span.packages_price {
    font-size: 30px;
  }

  .sec-compare-plans thead tr .packages_price span.prcie_year {
    font-size: 16px;
  }
  .sec-compare-plans thead tr .packages_price span.prcie_pkr {
    font-size: 16px;
  }
  .sec-compare-plans thead tr .packages_price span.ninty_nine {
    font-size: 15px;
  }
  .sec-compare-plans .orderButton {
    font-size: 14px;
    padding: 10px 15px;
  }
  .sec-compare-plans .table td {
    font-size: 15px;
  }
  .px-4-fix {
    padding-left: 0.5rem !important;
    padding-right: 0.2rem !important;
  }
  /* section compare plans ends */
}

/* only for 1200 abovbe screens (laptops */

@media (min-width: 1200px) and (max-width: 1400px) {
  .get-started {
    font-size: 27px;
  }
  .contact_datail ul li a {
    margin-right: 0px;
  }
  /* .submitammiunt{
    width: 60%;
  } */
  .block_text {
    height: 235px;
  }
  .navbar-expand-lg .navbar-nav .nav-link-new {
    padding-right: 1.4rem !important;
  }
  .px-4-fix {
    padding-left: 1.2rem !important;
    padding-right: 0.9rem !important;
  }
  .sm-w-50 {
    width: 63%;
  }
  .psetiingcart {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  /* .text-oper .button-first {
    font-size: 18px;
    padding: 15px 25px;
    float: left;
    margin: auto;
    min-width: unset;
}
.text-oper .button-sec {
  font-size: 18px;
  padding: 15px 25px;
  text-align: center;
  margin: auto;
  min-width: unset;
} */
}

@media (min-width: 1400px) {
  .px-4-fix {
    padding-right: 0.9rem !important;
    padding-left: 1.2rem !important;
  }

  /* .submitammiunt{
    width: 80%;
  } */
  .psetiingcart {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .navbar-expand-lg .navbar-nav .nav-link-new {
    padding-right: 1.4rem !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* .psetiingcart{
    padding-left:0px !important;
    padding-right: 0px !important;
  } */
  .dashed {
    visibility: hidden;
  }
  .height-adjust {
    height: 389px !important;
  }
  .container {
    min-width: 90%;
  }
  .sc-gKXOVf {
    /* display: none; */
  }

  .modal-dialog {
    max-width: 400px;
    margin: 1.75rem auto;
  }
  .passion_text h3 {
    width: 50%;
  }

  /* section main banner starts */
  /* .eAyARa {
    display: none;
  }
  .eWjCzc {
    display: none;
  } */
  .banner-left-text h3 {
    margin-bottom: 85px;
  }
  .banner-right-text h3 {
    margin-bottom: 85px;
  }
  /* section main banner ends */

  /* Section domain price starts */
  .domain-searching .domains_price {
    font-size: 14px;
    padding: 0;
    color: #4d4e4f;
    display: inline-block;
    font-weight: 600;
  }
  /* section domain price ends */

  /* section why choose us starts */
  .why-choose-us h4 {
    font-size: 25px;
    color: #4d4e4f;
    font-weight: 600;
  }
  .why-choose-us p {
    font-size: 16px;
    color: #434343;
    text-align: justify;
    text-align-last: center;
    margin-top: 0;
    font-weight: 400;
  }
  /* section why choose us ends */

  /* section need help starts */

  .sec-need_help .button-first {
    float: left;
  }
  .sec-need_help .button-sec {
    float: left;
  }

  .sec-transfer-sets .setps-1 span {
    padding: 10px 17px;
  }

  .sec-allplans .type-plans {
    padding-left: 48px;
  }
  /* section need help ends */
}

@media (min-width: 1400px) {
  .banner-size {
    height: 690px;
  }
  .passion_text h3 {
    width: 35%;
    font-size: 41px;
  }
  .passion_text h3 span {
    font-size: 57px;
  }
}

/* reposinve of one div starts */

@media (min-width: 576px) and (max-width: 767.98px) {
  .going-online .hosting-text {
    padding-left: 14px;
  }
}

/* reposinve of one div ends */

/* Header Responsive starts from here */

@media (min-width: 992px) and (max-width: 1199px) {
  .list-group-item {
    padding-left: 4px;
    padding-right: 4px;
  }
  .languages {
    width: 25%;
  }
  .contact_info {
    width: 75%;
  }
  .ticket {
    margin-left: 20px;
  }
  .cell {
    margin-left: 20px;
  }
  .my_account a {
    padding: 5px;
  }
  .logo {
    width: 15%;
  }
  .navigation {
    width: 85%;
  }
  .navigation ul li a {
    padding: 8px 8px 29px;
    font-size: 13px;
  }
  .navigation ul li button {
    padding: 8px;
    font-size: 13px;
  }
  .bnr {
    height: 410px;
  }
  .bnr_txt {
    padding-top: 20px;
  }
  .bnr_txt h3 {
    margin-bottom: 40px;
    font-size: 30px;
    line-height: 35px;
  }
  .megadrop {
    top: 116px;
  }
}

/* #dl-menu {
      display: none;
    } */
@media only screen and (max-width: 767px) {
  #dl-menu {
    display: block !important;
  }
  .dl-menuwrapper {
    display: block;
  }
  .navigation .hiden_on_mobile_view {
    display: none;
  }
  .languages {
    width: 24%;
  }
  .contact_info {
    width: 76%;
  }
  .ticket {
    display: none;
  }
  .cell {
    margin-left: 12px;
    float: right;
  }
  .cell a .celltext {
    display: none;
  }
  .my_account {
    display: none;
  }
  .languages ul li ul.dropdown {
    width: 250px;
    padding: 20px 10px 10px;
  }
  .languages ul li ul.dropdown li a {
    padding: 10px 0;
  }
}

@media only screen and (max-width: 479px) {
  .dl-menuwrapper {
    display: block;
  }
  .navigation .hiden_on_mobile_view {
    display: none;
  }
  h2 {
    font-size: 20px;
  }
  .languages {
    width: 24%;
  }
  .contact_info {
    width: 76%;
  }
  .ticket {
    display: none;
  }
  .cell {
    margin-left: 12px;
    float: right;
  }
  .cell a .celltext {
    display: inline-block;
    margin-right: 0px;
    padding-top: 2px;
    font-size: 13px;
  }
  .my_account {
    display: none;
  }
  .languages ul li ul.dropdown {
    width: 170px;
    padding: 10px;
  }
  .languages ul li ul.dropdown li a {
    padding: 10px 0;
  }
}

/* Header Responsive ends here */
