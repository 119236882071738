@charset "UTF-8";
@font-face {
  font-family: spantix;
  src: url(../fonts/spantix.eot);
  src: url(../fonts/spantix.eot?#iefix) format("embedded-opentype"),
    url(../fonts/spantix.woff) format("woff"),
    url(../fonts/spantix.ttf) format("truetype"),
    url(../fonts/spantix.svg#spantix) format("svg");
  font-weight: 400;
  font-style: normal;
}

[data-icon]:before {
  font-family: spantix !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: 400 !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="wp-"]:before,
[class*=" wp-"]:before {
  font-family: spantix !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.wp-advanced-tool:before {
  content: "\61";
}
.wp-brand-generatin:before {
  content: "\62";
}
.wp-certified-expert:before {
  content: "\63";
}
.wp-content-optimization:before {
  content: "\64";
}
.wp-keyword-analysis:before {
  content: "\65";
}
.wp-link-building:before {
  content: "\66";
}
.wp-local-seo:before {
  content: "\67";
}
.wp-ogranic-traffic:before {
  content: "\68";
}
.wp-outrank:before {
  content: "\69";
}
.wp-page-speed:before {
  content: "\6a";
}
.wp-potential-customer:before {
  content: "\6b";
}
.wp-proven-techniques:before {
  content: "\6c";
}
.wp-revenue-generation:before {
  content: "\6d";
}
.wp-sell-to-customer:before {
  content: "\6e";
}
.wp-seo-audit:before {
  content: "\6f";
}
