/* @import "https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"; */
/* @import url(https://fonts.googleapis.com/css?family=Open+Sans:300, 300i, 400, 400i, 600, 600i, 700, 700i, 800, 800i&display=swap); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: spantic;
  src: url(../fonts/spantic.eot);
  src: url(../fonts/spantic.eot?#iefix) format("embedded-opentype"),
    url(../fonts/spantic.woff) format("woff"),
    url(../fonts/spantic.ttf) format("truetype"),
    url(../fonts/spantic.svg#spantic) format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

[data-icon]:before {
  font-family: spantic !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: 400 !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-align-justify {
  text-align: justify;
}
.text-align-last {
  -moz-text-align-last: center;
  text-align-last: center;
}

/* Global Pre-defined css starts from here */

/* Pre-Defined Tags those are mostly used in the theme starts */

body {
  font-family: Poppins, sans-serif;
  color: #414042 !important;
}

p {
  font-size: 16px;
  color: #414042;
  font-weight: 400;
}

h2 {
  font-size: 48px;
  color: #005880;
  font-weight: 300;
}
.text-decoration-0 {
  text-decoration: none;
}

.pt-100 {
  padding-top: 100px;
}

.py-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}
.ftr_social_icon .row-centered {
  text-align: center;
  margin-bottom: 25px;
}

.faq-padding-set {
  padding: 100px 50px 100px 50px;
}
.bg-white {
  background: white;
}
.display-tableCell {
  display: table-cell;
}
.vertical-align-middle {
  vertical-align: middle;
}
.mb-50 {
  margin-bottom: 50px;
}

/* Pre-Defined Tags those are mostly used in the theme ends */

.icon-color {
  color: #414042;
}
.border-left-creem {
  border-left: 2px solid #e7e7e7;
}

.px-6 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
.fa.fa-info-circle .litooltip {
  position: absolute;
  background-color: #414042;
  text-transform: none;
  font-family: open sans, sans-serif;
  padding: 15px;
  text-align: center;
  width: 250px;
  border-radius: 5px;
  right: -18px;
  bottom: 23px;
  line-height: 17px;
  color: #fff;
  font-size: 14px;
  visibility: hidden;
  opacity: 0;
  display: block;
}
.fa.fa-info-circle {
  position: relative;
  margin-left: 5px;
  padding-top: 5px;
  display: inline-block;
  color: #005880;
}
.fa.fa-info-circle:hover .litooltip {
  visibility: visible;
  opacity: 1;
}
.fa.fa-info-circle .litooltip:before {
  content: "";
  position: absolute;
  bottom: -5px;
  right: 20px;
  width: 10px;
  height: 10px;
  border-top: none;
  border-left: none;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #414042;
}

.ptb-100 {
  padding: 100px 0px;
}

.display_table_cell {
  display: table-cell;
  vertical-align: middle;
}
.display_table {
  display: table;
  width: 100%;
  height: 100%;
}

/* Global Pre-defined css ends from here */

.top_bar {
  width: 100%;
  float: left;
  background-color: #ebf2f5;
}

/* Main Banner Styles starts from here */

/* .bg-banner {
  background-image: url("assets/img/main_banner.jpg");
} */

.banner-size {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 590px;
  display: table;
  /* border-bottom: 1px solid #d2d2d2; */
}

/* Main Banner Styles ends here */

/* Main Banner left Heading and span styles start here */

.banner-left-text h3 {
  font-size: 45px;
  color: #414042;
  font-weight: 200;
  font-style: normal;
  line-height: 44px;
}
.banner-left-text h3 span {
  font-weight: 700;
  font-size: 45px;
  display: block;
}

.banner-left-button {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  text-transform: capitalize;
  padding: 15px 30px;
  border: none;
  background-color: #005880;
}
.banner-left-button:hover {
  color: white;
}
.mb-sm-1 {
  margin-bottom: 10px;
}
/* Main Banner left Heading and span styles ends heree */

/* Main Banner right Heading and span styles starts from here */

.banner-right-text h3 {
  font-size: 45px;
  color: #414042;
  font-weight: 200;
  font-style: normal;
  line-height: 44px;
}
.banner-right-text h3 span {
  font-weight: 700;
  font-size: 45px;
  display: block;
}

.banner-right-button {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  text-transform: capitalize;
  padding: 15px 30px;
  border: none;
  background-color: #005880;
}
.banner-right-button:hover {
  color: white;
}
/* Main Banner right Heading and span styles ends heree */

/* Services section since 2002 starts from here */

.service-font {
  font-size: 85px;
}

/* Services section since 2002 ends from here */

/* support section since 2002 starts from here */

.support-font {
  font-size: 85px;
}

/* support section since 2002 ends from here */

/* cpanel section since 2002 starts from here */

.cpanel-font {
  font-size: 85px;
}

/* cpanel section since 2002 ends from here */

/* serveruptime section since 2002 starts from here */

.server-up-time-font {
  font-size: 85px;
}

/* serveruptime section since 2002 ends from here */

/* domain search section styles starts from here */

/* .bg-wave {
  background-image: url("assets/img/waves_bg.png");
} */
.bg-wave-section {
  width: 100%;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.get-started {
  /* background-color: #f9f9f9; */
  font-size: 32px;
  color: #414042;
  font-weight: 400;
  padding: 10px 20px;
}

.serach_button {
  background-color: transparent;
  border: none;
}

.domain-searching .input-group input[type="search"] {
  padding: 0 20px;
  outline: none;
  color: #a0a0a0;
}
.domain-searching .input-group button {
  padding: 10px 20px;
  outline: none;
  background-color: transparent;
  color: #005880;
}
.domain-searching .input-group button .fa {
  font-size: 18px;
}

.domain-searching .domain-details ul {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.domain-searching .domian-category {
  display: block;
  font-size: 18px;
  padding: 0;
  font-weight: 700;
  color: #046289;
}

.domain-searching .domain-details ul li {
  width: 12.5%;
  list-style: none;
  text-align: center;
}
.deny-services ul li {
  float: left;
}
/* domain search section styles ends from here */

/* plan sections styling starts from here */
.plan-section {
  width: 100%;

  background-repeat: no-repeat;
}
.first-heading {
  color: #005880;
  font-size: 48px;
  text-transform: capitalize;
  font-weight: 600;
}
.second-heading {
  color: #414042;
  padding-top: 3px;
  font-size: 25px;
  font-weight: 300;
  margin-top: -5px;
}
/* plan sections styling ends from here */

/*  packages plan section starts from here  */

.pacakge-one {
  width: 100%;
  background-color: #f8f8f8;
  padding-left: 20px !important;
}
.package-name h3 {
  padding-top: 20px !important;
  font-size: 25px;
  color: #414042;
  text-transform: uppercase;
  font-weight: 400;
}
.package-namepaaaa {
  text-transform: capitalize;
  /* width: 44%; */
  width: 100%;
  line-height: 20px;
}

.package-one p {
  color: #414042;
  font-size: 20px;
  font-weight: 300;
  margin: 0 0 10px;
}
.discount {
  display: inline-block;
  padding-top: 50px;
  padding-right: 20px !important;
  font-size: 20px;
  font-weight: 300;
}

.discount h4 {
  font-size: 24px;
  font-weight: 200;
  background-color: #ebf2f5;
  margin: 0;
  width: 100%;
}

.package-one-price {
  display: inline-block;
  width: 100%;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 34px;
}

.package-one-price .amount {
  display: block;
  overflow-wrap: break-word;
  font-size: 30px;
  color: #414042;
  font-weight: 300;
  margin-bottom: 0;
  position: relative;
}

.package-one-price .amount_cut {
  font-size: 24px;
  color: #414042;
  font-weight: 200;
  margin-bottom: 0;
  position: relative;
}

.points_amount {
  display: inline;
  position: absolute;
  font-size: 14px;
  top: 4px;
}
.month {
  display: inline;
  font-weight: 400;
  font-size: 22px;
}
.star {
  color: #eb1c24;
}
.package-one-description {
  width: 100%;
  background-color: #004e71;
  padding: 5px 0;
}

.package-one-description p {
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
  margin: 75px 0 0;
}

.pacakge-one ul li {
  width: 100%;
  padding: 3px 10px 3px 0px;
  font-size: 18px;
  color: #414042;
  display: flex;
  font-weight: 300;
  /* margin: 75px 0 0; */
}
.pacakge-one button {
  font-size: 16px;
  background-color: #005880;
  padding: 10px 30px;
  color: #fff;
  border: none;
  margin: 40px 0 25px 0;
}
/*  packages plan section ends here  */

/* Digital Shop Section starts here */

/* .bg-server {
  background-image: url("assets/img/Web-Hosting-Pakistan.png");
} */

.bg-server-set {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 525px;
  margin-bottom: 100px;
}

.server-text h3 span:nth-child(1) {
  display: inline-block;
  margin: 10px 0;
  font-weight: 300;
  color: #fff;
  text-transform: uppercase;
  padding: 20px 15px;
  background-color: #005880;
}
.server-text h3 span:nth-child(2) {
  display: block;
  font-weight: 300;
  margin-bottom: 10px;
}
.server-text h3 span:nth-child(3) {
  color: #414042;
  font-weight: 300;
}

.server-text h3 {
  font-size: 38px;
  color: #414042;
  text-transform: capitalize;
  font-weight: 300;
  line-height: 35px;
}
.server-text a {
  font-size: 16px;
  background-color: #005880;
  padding: 10px 30px;
  color: #fff;
  border: none;
  text-decoration: none;
  font-weight: 600;
  margin: 35px 0 0;
  display: inline-block;
}
/* Digital Shop Section ends here */

/* Why Choose Us Section Styling starts */

/* .why-choose-us h2 {
  color: #046289;
  font-weight: 400;
}
.why-choose-us h5 {
  font-size: 25px;
  color: #414042;
  font-weight: 300;
  margin-top: -5px;
} */

.why-choose-service {
  width: 100%;
  text-align: center;
  cursor: context-menu;
  padding: 0 20px;
}

.why-choose-service .circle-image {
  width: 100px;
  height: 100px;
  display: inline-block;
  line-height: 75px;
  padding: 10px;
  border-radius: 50px;
  border: 1px solid #046289;
  transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
}
.ws-serve-b:before {
  content: "\e00b";
}
.why-choose-service .img_circle .icon {
  font-size: 75px;
  color: #046289;
  line-height: 75px;
}
.border-left-right {
  position: relative;
}
.border-left-right:nth-of-type(2):before,
.border-left-right:nth-of-type(5):before,
.border-left-right:nth-of-type(8):before {
  position: absolute;
  height: 70%;
  width: 1px;
  left: 0;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #eaeaea;
  content: "";
}
.border-left-right:nth-of-type(2):after,
.border-left-right:nth-of-type(5):after,
.border-left-right:nth-of-type(8):after {
  position: absolute;
  height: 70%;
  width: 1px;
  right: 0;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #eaeaea;
  content: "";
}
.border-left-right:nth-of-type(1),
.border-left-right:nth-of-type(2),
.border-left-right:nth-of-type(3) {
  margin-bottom: 35px;
}
/* Why Choose Us Section Styling ends */

/* Domain Extraction Starts from here  */
.domain-extraction .extraction {
  width: 100%;
  min-height: 130px;
  background-color: #ebf2f5;
  border: 1px solid #e0e0e0;
  margin-bottom: 30px;
  position: relative;
}
.domain-extraction .extraction a .d-table-cell {
  font-size: 45px;
  color: #414042;
  font-weight: 400;
  text-decoration: none;
}
.domain-extraction .d-table-cell {
  height: 130px;
}
.domain-extraction .extraction .d-table-cell div {
  color: #414042;
  display: inline-block;
}
.extraction .d-table {
  width: 100%;
}
.domain-extraction .extraction span {
  display: block;
  width: 100%;
  background-color: #005a7e;
  padding: 10px 0;
  color: #fff;
  font-size: 24px;
  font-weight: 400;
}
.extraction:hover .register-price-overlay {
  display: block;
}
.register-price-overlay {
  width: 100%;
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ebf2f5;
  display: none;
  height: 100%;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}

.register-price-overlay a.btn {
  background-color: #005880;
  display: inline-block;
  width: auto;
  padding: 7px 35px;
  color: #fff;
  font-size: 20px;
  border-radius: 0px !important;
  border: none;
  margin: 0;
  font-weight: 300;
}
.register-price-overlay .d-table {
  height: 100%;
  width: 100%;
}
/* Domain Extraction ends here  */

/* What we got section starts from here */

.domain-what-nav-tabs ul li button.active {
  background-color: #005880 !important;
  color: #fff !important;
  border-radius: 0 !important;
  border: none !important;
}

.domain-what-nav-tabs ul li button {
  background-color: #ebf2f5 !important;
  color: #414042;
  font-size: 22px;
  font-weight: 400;
  padding: 20px 30px;
  text-align: center;
  border-radius: 0 !important;
  border: none !important;
}

.nav-link:focus,
.nav-link:hover {
  color: #414042;
}

.domain-what-nav-tabs .tab-content {
  padding-top: 50px;
}

.nav-tabs-price div.text_alignment {
  width: 100%;
  height: 140px;
  text-align: center;
}

.nav-tabs-price div h4 {
  font-size: 30px;
  font-weight: 400;
  color: #414042;
}
.nav-tabs-price div h5 {
  font-size: 20px;
  color: #414042;
  font-weight: 600;
}

.nav-tabs-price div h5 span {
  font-size: 25px;
  font-weight: 400;
}

.nav-tabs-price div h5 span.steric {
  color: #eb1c24;
  font-size: 20px;
}

.navTabs_price_overlay {
  width: 100%;
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ebf2f5;
  display: none;
  height: 100%;
}
.setTab {
  height: 140px;
  border: 1px solid #ebf2f5;
  position: relative;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}

.domain-what-nav-tabs div > .setTab:hover .navTabs_price_overlay {
  display: block;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}
.navTabs_price_overlay .d-table {
  width: 100%;
  height: 100%;
}
.navTabs_price_overlay a.btn {
  background-color: #005880;
  display: inline-block;
  width: auto;
  padding: 7px 35px;
  color: #fff;
  font-size: 20px;
  border-radius: 0px !important;
  border: none;
  margin: 0;
  font-weight: 300;
}
/* What we got section ends  here */

/* Going Online section starts here */

/* .bg-buyonline {
  background-image: url("assets/img/buy-domain-in-Pakistan.jpg");
} */

/* .going-online:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0,90,126,.8);
    width: 100%;
    height: 100%;
} */
.going-online h2 {
  color: #fff;
  position: sticky;
  font-weight: 700;
}
.going-online h5 {
  font-size: 25px;
  color: #fff;
  margin-top: -5px;
  font-weight: 600;
  position: relative;
}
.going-online .divider {
  max-width: 75px;
  border-width: 0;
  height: 2px !important;
  background-color: #fff;
  margin-bottom: 40px !important;
  position: sticky;
  margin-top: 40px;
}

.hosting-text {
  text-align: center;
  display: table;
  position: sticky;
  border: 1px solid #e6e6e6;
  height: 150px;
  border-left: 5px solid #6fcd32;
}

.going-online .hosting-text {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
  padding-left: 80px;
  padding-right: 30px;
}
.going-online .hosting-text p {
  font-size: 17px;
  color: #fff;
  margin-bottom: -8px;
}
.going-online .hosting-text h5 {
  font-size: 35px;
  color: #fff;
  margin-bottom: 15px;
}
.going-online .hosting-text button {
  font-size: 16px;
  cursor: pointer;
  color: #fff;
  background-color: #6fcd32;
  border: none;
  width: 130px;
  height: 40px;
  text-transform: capitalize;
}
.divider_two {
  max-width: 20px;
  border-width: 3px;
  height: 3px !important;
  background-color: #e4e4e4;
  margin-top: 70px;
}
/* Going Online section ends here */

/* Section Stumped Starts here  */

.sec-specfication .icon {
  font-size: 100px;
  color: #039bd8;
  margin-bottom: 10px;
}
.sec-specfication h5 {
  font-size: 25px;
  color: #414042;
  margin-bottom: 20px;
  font-weight: 400;
}
.easy-to-remember {
  padding: 20px 10px;
}
.sec-specfication p {
  font-size: 14px;
  color: #414042;
  text-align: justify;
  font-weight: 400;
  -moz-text-align-last: center;
  text-align-last: center;
  padding: 0 10px;
}
/* Section Stumped ends here  */

/* Section Account Migrate Strats from here */

.sec-account-migrate {
  background-color: #fff;
}
.sec-account-migrate h2 {
  line-height: 55px;
  color: #414042;
  font-weight: 400;
}
.sec-account-migrate p {
  font-size: 25px;
  color: #414042;
  font-weight: 300;
}

.sec-account-migrate button {
  width: 200px;
  height: 50px;
  border: none;
  background-color: #005880;
  color: #fff;
  font-size: 16px;
  text-transform: capitalize;
}

/* Section Account Migrate Ends here */

/* Section Domain Registration Starts from here */

.text-domain-reg p {
  font-size: 16px;
  color: #414042;
  margin-bottom: 10px;
  text-align: justify;
  font-weight: 400;
}

/* Section Domain Registration ends here */

/* Section faq starts from here */

.sec-faq .sec-faq-accor .accordion button {
  width: 100%;
  text-align: left;
  padding: 15px 15px 15px 45px;
  border: none;
  background-color: #ebf2f5;
  font-size: 20px;
  color: #414042;
  font-weight: 400;
  margin-bottom: 18px;
  cursor: pointer;
  outline: none;
  position: relative;
}

.sec-faq-accor .accordion-item {
  border: none;
}

.sec-faq-accor .accordion-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.sec-faq-accor .accordion-button::after {
  font-family: FontAwesome;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  background-image: none;
  content: "\f067";
}

.sec-faq-accor .accordion-button:not(.collapsed)::after {
  content: "\f068";
}

/* Section faq ends here */

/* Section need help starts from here */
.sec-need_help .text-oper h4 {
  color: #005880;
  font-size: 40px;
  margin-bottom: 15px;
  font-weight: 400;
}

.sec-need_help .text-oper p {
  color: #414042;
  font-size: 22px;
  font-weight: 300;
  line-height: normal;
  padding-right: 35px;
  margin-bottom: 60px;
}

.sec-need_help .help-oper-img {
  max-width: 100%;
}

.text-oper .button-sec {
  background-color: transparent;
  color: #414042;
  border: 1px solid #005880;
  max-width: 270px;
  min-width: 270px;
  padding: 20px;
}
.text-oper .button-first {
  background-color: #005880;
  color: #fff;
  border: 1px solid #005880;
  min-width: 270px;
  padding: 20px;
  margin-right: 50px;
}

.mt-neg-78 {
  margin-top: -78px;
}
/* Section need help ends here */

/* section domain renewal starts from here  */

.tabs-renew .nav-tabs .nav-link.active {
  background-color: #005880 !important;
  font-size: 22px;
  font-weight: 600;
  padding: 20px 30px;
  color: #fff !important;
  border-radius: 0 !important;
  border: none !important;
}

.tabs-renew .nav-tabs {
  border-bottom: none;
}
.tabs-renew .nav-tabs .nav-link {
  background-color: #ebf2f5;
  color: #414042;
  font-size: 22px;
  font-weight: 600;
  padding: 20px 30px;
  text-align: center;
  border-radius: 0 !important;
  border: none !important;
}

.tabs-renew .tab-content {
  padding-top: 50px;
}
.tabs-renew .tab-content p {
  font-size: 20px;
  color: #414042;
}
/* section domain renewal ends here  */

/* section  domain transfer nav page starts from here  */

.sec-transfer-sets .setps-1 {
  background-color: #ebf2f5;
  text-align: center;
  display: table;
  height: 150px;
  width: 100%;
}

.sec-transfer-sets .setps-1 span {
  font-size: 35px;
  text-transform: capitalize;
  color: #414042;
  font-weight: 600;
  border-right: #fff 1px dashed;
  padding: 10px 30px;
}

.setps-progress {
  border-top: 1px solid #005880;
  border-right: 1px solid #005880;
  border-bottom: 1px solid #005880;
  border-left: 1px solid #005880;
  padding: 0 45px 0 60px;
  height: 100%;
  display: table;
}

.sec-transfer-sets .setps-progress h3 {
  color: #414042;
  font-size: 25px;
}

.sec-transfer-sets .setps-progress p {
  font-size: 14px;
  color: #414042;
}

/* section  domain transfer nav page ends here  */

/* section  all plan types starts from here   */

.sec-allplans .type-plans {
  border: 1px solid #005880;
  cursor: context-menu;
  height: 125px;
  margin-bottom: 30px;
  position: relative;
  padding-left: 70px;
  text-align: left;
  display: table;
}

.sec-allplans .type-plans .icon-left-box {
  width: 75px;
  height: 75px;
  background-color: #005880;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  float: left;
  position: absolute;
  left: -35px;
  top: 20%;
}
.sec-allplans .type-plans .right-data {
  display: table-cell;
  vertical-align: middle;
}
.sec-allplans .type-plans .right-data h4 {
  font-size: 24px;
  color: #414042;
  text-transform: capitalize;
  line-height: 28px;
  font-weight: 400;
}
.sec-allplans .type-plans .right-data p {
  font-size: 17px;
  color: #414042;
  padding-right: 35px;
  font-weight: 400;
  margin: 0;
}

/* section  all plan types ends here  */

/* section bundlr booster section design starts from here  */

.w-25per {
  width: 25%;
}

.bundel-booster-tabs-set .nav-tabs .nav-link {
  width: 100%;
  padding: 15px 0;
  outline: none;
  z-index: 1;
  cursor: pointer;
  border: none;
  background-color: #ebf2f5;
  font-size: 25px;
  color: #414042;
  position: relative;
}
.bundel-booster-tabs-set .nav-tabs .nav-link.active {
  background: #005880;
  color: white;
}
/* .bundel-booster-tabs-set ul li button:before {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: #f8f8f8;
  bottom: -9px;
  left: 50%;
  transform: rotate(45deg);
  opacity: 0;
} */

.bundel-booster-tabs-set ul li button.active:before {
  background-color: #005880;
  opacity: 1 !important;
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  bottom: -9px;
  left: 50%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity: 0;
}

.bundel-booster-tabs-set .tab-content {
  background-color: #fff;
}
.bundel-booster-tabs-set .tab-content .tab-pane {
  height: 400px;
}

.bundel-booster-tabs-set .tab-content .tab-pane h4 {
  font-size: 25px;
  font-weight: 400;
  color: #414042;
  margin-bottom: 30px;
}

.bundel-booster-tabs-set .tab-content .tab-pane p {
  font-size: 22px;
  text-align: justify;
  color: #414042;
  font-weight: 300;
}
/* section bundlr booster section design ends here  */

/* section v2 why choose us starts from here */

.v2-why-choose-us .v2-servce-text {
  width: 100%;
  background-color: #ebf2f5;
  height: 160px;
  display: table;
  border-bottom: 8px solid #005880;
}

.v2-servce-text .icon {
  font-size: 50px;
}
.v2-servce-text p {
  font-size: 28px;
  color: #414042;
  font-weight: 400;
}
/* section v2 why choose us ends here */

/* section woth it starts */

.worth-it-sec .block-power {
  background-color: #ebf2f5;
  border: 1px solid #d7d7d7;
  padding: 10px 0;
}
.worth-it-sec .padding {
  padding: 0 50px;
}
.worth-it-sec .block-power .icon {
  font-size: 100px;
  color: #005a7e;
}
.ws-fast:before {
  content: "\57";
}
.worth-it-sec .block-power h3 {
  font-size: 20px;
  color: #414042;
}

.worth-it-sec .block-power p {
  font-size: 16px;
  color: #414042;
  margin-bottom: 20px;
  font-weight: 400;
}

/*  section woth it ends here */

/*  v2 bundler booster start from here */

.v2-bundler-booster .table .bg {
  background-color: #005880;
  padding: 15px 0;
  color: #fff;
  font-size: 30px;
  font-weight: 400;
}

.v2-bundler-booster .table .detial-order {
  background-color: #ebf2f5;
  text-align: left;
}

.v2-bundler-booster .table .detial-order td {
  border: none;
  border-bottom: 1px solid #bcbcbc;
  vertical-align: middle;
  font-size: 18px;
  color: #414042;
  font-weight: 400;
  padding: 0.75rem;
}

.v2-bundler-booster .table .detial-order td button {
  border: none;
  background-color: #005880;
  color: #fff;
  font-size: 16px;
  padding: 8px 30px;
  font-weight: 600;
  cursor: pointer;
}
/*  v2 bundler booster ends here  */

/* section custmized plan start  */
.bg-customized-plan {
  background-color: #ebf2f5;
}

.sec-customized-plan {
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.sec-customized-text {
  display: table-cell;
  vertical-align: middle;
}

.sec-customzied-text h3 {
  font-size: 48px;
  text-transform: capitalize;

  font-weight: 700;
  margin-bottom: 40px;
}

.sec-customzied-text p {
  font-size: 25px;
  text-transform: none;

  font-weight: 400;
  margin-bottom: 40px;
  padding: 0 155px;
}

.sec-customzied-text button.livechat {
  background-color: #005880;
  color: white;
  font-weight: 600;
  border: none;
  font-size: 16px;
  text-decoration: none;
  padding: 10px 35px 10px;
  border-radius: 3px;
}

.sec-customzied-text button.getintouch {
  background-color: transparent;
  color: #414042;
  font-weight: 600;
  border: 1px solid #005880;
  font-size: 16px;
  text-decoration: none;
  padding: 10px 35px 10px;
  border-radius: 3px;
}

/* section custmized plan ends  */

/* section compare plans starts from here */

.sec-compare-plans table thead tr td h3 {
  text-transform: capitalize;
  font-size: 25px;
  color: #414042;

  font-weight: 400;
}

.sec-compare-plans .orderButton {
  height: auto;
  width: auto;
  padding: 15px 40px;
  cursor: pointer;
  font-size: 16px;
  border: none;
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
  background-color: #005880;
}

.sec-compare-plans .table td {
  border: none;
  border: 1px solid #ebf2f5;
  vertical-align: middle;
  font-size: 18px;
  color: #414042;
  font-weight: 400;
  padding: 20px;
}

.sec-compare-plans thead tr span.packages_price {
  font-size: 30px;
  color: #414042;
  display: block;
  font-weight: 400;
  margin-bottom: 15px;
}

.sec-compare-plans thead tr .packages_price span.prcie_pkr {
  font-size: 38px;
  font-weight: 400;
}
.sec-compare-plans thead tr .packages_price span.prcie_year {
  font-size: 25px;
  font-weight: 400;
  position: relative;
}
.sec-compare-plans thead tr .packages_price span.ninty_nine {
  position: absolute;
  top: -15px;
  font-size: 18px;
  font-weight: 600;
}
/* section compare plans ends here */

/* Header styles starts from here */

.topbar {
  background-color: #414042;
}
.languages {
  width: 32%;
  float: left;
}
.languages ul li {
  position: relative;
  width: 90px;
  cursor: pointer;
}
.languages ul li a {
  display: inline-block;
  text-decoration: none;
  color: #414042;
  padding: 5px;
}
.languages ul li a .fa.fa-globe {
  margin-right: 5px;
  font-size: 20px;
}
.languages ul li a img {
  padding-right: 5px;
}
.languages ul li a .fa-caret-down {
  margin-left: 5px;
}
.languages ul li ul.dropdown {
  position: absolute;
  z-index: 9;
  display: none;
  width: max-content;
  width: -moz-max-content;
  width: -o-max-content;
  width: -webkit-max-content;
  float: left;
  background-color: #f9f9f9;
  padding: 0 30px 10px;
  border-radius: 5px;
}
.languages ul li ul.dropdown li {
  /* background-image: url(../images/underline.png); */
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100%;
  width: 100%;
}

.languages ul li ul.dropdown li a {
  padding: 20px 0;
  color: #414042;
  background: 0 0;
}

.languages ul li ul.dropdown li a img {
  margin-right: 25px;
}

.languages ul li ul.dropdown li a:hover {
  color: #414042;
}

.contact_info {
  width: 68%;
  float: left;
  text-align: right;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ticket {
  float: left;
  margin-left: 20px;
  padding: 5px 0;
}

.ticket a {
  text-decoration: none;
}

.ticket .tikttxt {
  color: #414042;
  font-size: 14px;
  padding-top: 2px;
}

.ticket a .fa.fa-shopping-cart {
  font-size: 16px;
  color: #414042;
  position: relative;
}

.ticket a .fa.fa-shopping-cart span.circle_count {
  position: absolute;
  width: 11px;
  height: 11px;
  background-color: #fff;
  border-radius: 50px;
  font-size: 10px;
  color: #414042;
  display: inline-block;
  text-align: center;
  top: -5px;
}

.ticket .ticketimg {
  margin-right: 4px;
}

.ticket .ticketimg .fa {
  color: #414042;
}
.cell.red {
  margin-left: 0;
  padding: 20px 0 5px;
}
.cell {
  float: left;
  margin-left: 20px;
  padding: 5px 0;
}

.cell a {
  text-decoration: none;
}
.cell a .cellimg {
  margin-right: 3px;
}
.cell a .cellimg .fa {
  color: #414042;
}
.cell a .celltext {
  color: #414042;
  font-size: 14px;
}
.red a {
  color: #ffffff;
}
span.celltext.button_shape {
  text-decoration: none;
  background-color: #005880;
  color: #fff;
  padding: 2px 13px;
}
.my_account {
  display: inline-block;
  margin-left: 20px;
}

.my_account ul li {
  float: left;
  position: static;
}

.my_account a {
  text-decoration: none;
  color: #414042;
  font-size: 14px;
  display: block;
  padding: 7px 0 5px;
  background-color: transparent;
  border-radius: 3px;
}

.my_account .fa-caret-down {
  margin-left: 8px;
}

.my_account ul li {
  display: inline-block;
  position: relative;
}

.my_account {
  position: static;
}

.dropdown_content {
  width: 100%;
  float: left;
  background-color: #f9f9f9;
  padding: 50px 0;
  text-align: center;
  position: absolute;
  top: 32px;
  left: 0;
  display: none;
  z-index: 99999999;
}

.dropdown_content ul li {
  float: left;
  width: 50%;
  /* background-image: url(../images/mega_left_brdr.png); */
  background-repeat: no-repeat;
  background-size: auto;
  background-position: left;
}

.dropdown_content ul li:nth-child(1) {
  background-image: none;
}

.sign_up {
  width: 100%;
  float: left;
}

.sign_up h3 {
  font-size: 25px;
  color: #414042;
  font-weight: 600;
  margin-bottom: -3px;
}

.sign_up p {
  font-size: 20px;
  color: #414042;
  margin-bottom: 30px;
  font-weight: 300;
}

.sign_up a {
  background-color: #005880;
  padding: 10px 80px;
  border-radius: 3px;
  color: #fff;
  font-size: 20px;
  text-transform: capitalize;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
}

.sign_up a:hover {
  background-color: #005880;
}

.sign_in a {
  background-color: #005880;
  cursor: pointer;
}

.sign_in a:hover {
  background-color: #005880;
}

.navigation_bar {
  padding: 8px 0 0;
  border-bottom: 1px solid #414042;
  float: left;
  width: 100%;
  transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
}

.logo {
  width: 19%;
  float: left;
  padding-bottom: 8px;
}

.logo a img {
  max-width: 45%;
}

.navigation {
  width: 81%;
  float: left;
  padding-top: 25px;
}

.navigation ul {
  float: right;
}

.navigation ul li {
  float: left;
  position: static;
  /* margin-left: 30px; */
}
.main_dropdown {
  margin-left: 30px;
}

.navigation ul li:nth-child(1) {
  margin-left: 0;
}

.navigation ul li a .fa-caret-down {
  padding-left: 10px;
}

.navigation ul li a {
  display: block;
  position: relative;
  font-size: 15px;
  padding: 0 5px 29px;
  color: #414042;
  text-decoration: none;
}

.navigation ul li a span {
  position: absolute;
  top: -10px;
  text-transform: capitalize;
  color: #ec1c24;
  font-size: 10px;
  left: 0;
}

.navigation ul li a:hover {
  color: #015a7d;
  text-decoration: underline;
}
#dl-menu {
  display: none;
}
.megadrop {
  width: 100%;
  float: left;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 335px;
  top: 132px;
  left: 0;
  position: absolute;
  z-index: 9999999;
  display: none;
  border-top: 1px solid #414042;
}

.megadrop ul li {
  margin-left: 0;
}

.web_hosting {
  width: 100%;
  float: left;
  padding: 40px 45px 0 0;
  text-align: justify;
}

.web_hosting h3 {
  font-size: 35px;
  color: #fff;
  margin-bottom: 10px;
}

.web_hosting p {
  font-size: 16px;
  color: #fff;
}

.shared_hosting {
  width: 100%;
  float: left;
  padding-top: 60px;
}

.shared_hosting ul {
  float: none;
}

.shared_hosting ul li {
  float: left;
  margin-bottom: 30px;
  margin-left: 0;
}

.shard_column {
  width: 100%;
  float: left;
}

.shard_column .shrdimg {
  width: 26%;
  float: left;
}

.shard_column .shrdimg a {
  padding: 0;
}

.shard_column .shrdimg img {
  max-width: 100%;
}

.shard_column .shared_text {
  width: 74%;
  float: left;
  padding-left: 15px;
}

.shard_column .shared_text h4 a {
  color: #046289;
  font-size: 20px;
  text-transform: capitalize;
  padding: 0;
}

.shard_column .shared_text p {
  color: #6d6e71;
  font-size: 10px;
  line-height: 10px;
}

.dard_grey button:hover {
  background-color: #242424;
}

/* Header styles ends here */

/* Header mobile menu styles starts from here  */

.dl-menuwrapper {
  width: 100%;
  max-width: 300px;
  float: left;
  position: relative;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-perspective-origin: 50% 200%;
  perspective-origin: 50% 200%;
  z-index: 99 !important;
}

.dl-menuwrapper:first-child {
  margin-right: 100px;
}

.dl-menuwrapper button {
  background: #046289;
  border: none;
  width: 40px;
  height: 30px;
  text-indent: -900em;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  outline: none;
  float: right;
  margin-bottom: 8px;
}

.dl-menuwrapper button:hover,
.dl-menuwrapper button.dl-active,
.dl-menuwrapper ul {
  background: #046289;
}

.dl-menuwrapper button:after {
  content: "";
  position: absolute;
  width: 68%;
  height: 3px;
  background: #fff;
  top: 50%;
  left: 25%;
  -webkit-box-shadow: 0 6px 0 #fff, 0 12px 0 #fff;
  box-shadow: 0 6px 0 #fff, 0 12px 0 #fff;
}

.dl-menuwrapper ul {
  padding: 0;
  list-style: none;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.dl-menuwrapper li {
  position: relative !important;
  float: none !important;
}

.dl-menuwrapper li a {
  display: block;
  position: relative;
  padding: 15px 20px !important;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  color: #fff !important;
  outline: none;
}

.dl-menuwrapper li a .fa-arrow-right {
  float: right;
  font-size: 15px;
}

.no-touch .dl-menuwrapper li a:hover {
  background: rgba(255, 248, 213, 0.1);
}

.dl-menuwrapper li.dl-back > a {
  padding-left: 30px;
  background: rgba(0, 0, 0, 0.1);
  opacity: 0;
}

.dl-menuwrapper li.dl-back:after,
.dl-menuwrapper li > a:not(:only-child):after {
  position: absolute;
  top: 0;
  line-height: 50px;
  font-family: "icomoon";
  /* speak: none; */
  -webkit-font-smoothing: antialiased;
  content: "\e000";
}

.dl-menuwrapper li.dl-back:after {
  left: 10px;
  color: rgba(212, 204, 198, 0.3);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dl-menuwrapper li > a:after {
  right: 10px;
  color: rgba(0, 0, 0, 0.15);
}

.dl-menuwrapper .dl-menu {
  margin: 30px 0 0 0;
  position: absolute;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.dl-menuwrapper .dl-menu.dl-menu-toggle {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.dl-menuwrapper .dl-menu.dl-menuopen {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

/* Hide the inner submenus */
.dl-menuwrapper li .dl-submenu {
  display: none;
  float: none !important;
}

/*
  When a submenu is openend, we will hide all li siblings.
  For that we give a class to the parent menu called "dl-subview".
  We also hide the submenu link.
  The opened submenu will get the class "dl-subviewopen".
  All this is done for any sub-level being entered.
  */
.dl-menu.dl-subview li,
.dl-menu.dl-subview li.dl-subviewopen > a,
.dl-menu.dl-subview li.dl-subview > a {
  display: none;
}

.dl-menu.dl-subview li.dl-subview,
.dl-menu.dl-subview li.dl-subview .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu > li {
  display: block;
}

/* Dynamically added submenu outside of the menu context */
.dl-menuwrapper > .dl-submenu {
  position: absolute;
  width: 100%;
  top: 50px;
  left: 0;
  margin: 0;
}

/* Animation classes for moving out and in */

.dl-menu.dl-animate-out-1 {
  -webkit-animation: MenuAnimOut1 0.4s;
  animation: MenuAnimOut1 0.4s;
}

.dl-menu.dl-animate-out-2 {
  -webkit-animation: MenuAnimOut2 0.3s ease-in-out;
  animation: MenuAnimOut2 0.3s ease-in-out;
}

.dl-menu.dl-animate-out-3 {
  -webkit-animation: MenuAnimOut3 0.4s ease;
  animation: MenuAnimOut3 0.4s ease;
}

.dl-menu.dl-animate-out-4 {
  -webkit-animation: MenuAnimOut4 0.4s ease;
  animation: MenuAnimOut4 0.4s ease;
}

.dl-menu.dl-animate-out-5 {
  -webkit-animation: MenuAnimOut5 0.4s ease;
  animation: MenuAnimOut5 0.4s ease;
}

@-webkit-keyframes MenuAnimOut1 {
  0% {
  }
  50% {
    -webkit-transform: translateZ(-250px) rotateY(30deg);
  }
  75% {
    -webkit-transform: translateZ(-372.5px) rotateY(15deg);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: translateZ(-500px) rotateY(0deg);
    opacity: 0;
  }
}

@-webkit-keyframes MenuAnimOut2 {
  0% {
  }
  100% {
    -webkit-transform: translateX(-100%);
    opacity: 0;
  }
}

@-webkit-keyframes MenuAnimOut3 {
  0% {
  }
  100% {
    -webkit-transform: translateZ(300px);
    opacity: 0;
  }
}

@-webkit-keyframes MenuAnimOut4 {
  0% {
  }
  100% {
    -webkit-transform: translateZ(-300px);
    opacity: 0;
  }
}

@-webkit-keyframes MenuAnimOut5 {
  0% {
  }
  100% {
    -webkit-transform: translateY(40%);
    opacity: 0;
  }
}

@keyframes MenuAnimOut1 {
  0% {
  }
  50% {
    -webkit-transform: translateZ(-250px) rotateY(30deg);
    transform: translateZ(-250px) rotateY(30deg);
  }
  75% {
    -webkit-transform: translateZ(-372.5px) rotateY(15deg);
    transform: translateZ(-372.5px) rotateY(15deg);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: translateZ(-500px) rotateY(0deg);
    transform: translateZ(-500px) rotateY(0deg);
    opacity: 0;
  }
}

@keyframes MenuAnimOut2 {
  0% {
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes MenuAnimOut3 {
  0% {
  }
  100% {
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    opacity: 0;
  }
}

@keyframes MenuAnimOut4 {
  0% {
  }
  100% {
    -webkit-transform: translateZ(-300px);
    transform: translateZ(-300px);
    opacity: 0;
  }
}

@keyframes MenuAnimOut5 {
  0% {
  }
  100% {
    -webkit-transform: translateY(40%);
    transform: translateY(40%);
    opacity: 0;
  }
}

.dl-menu.dl-animate-in-1 {
  -webkit-animation: MenuAnimIn1 0.3s;
  animation: MenuAnimIn1 0.3s;
}

.dl-menu.dl-animate-in-2 {
  -webkit-animation: MenuAnimIn2 0.3s ease-in-out;
  animation: MenuAnimIn2 0.3s ease-in-out;
}

.dl-menu.dl-animate-in-3 {
  -webkit-animation: MenuAnimIn3 0.4s ease;
  animation: MenuAnimIn3 0.4s ease;
}

.dl-menu.dl-animate-in-4 {
  -webkit-animation: MenuAnimIn4 0.4s ease;
  animation: MenuAnimIn4 0.4s ease;
}

.dl-menu.dl-animate-in-5 {
  -webkit-animation: MenuAnimIn5 0.4s ease;
  animation: MenuAnimIn5 0.4s ease;
}

@-webkit-keyframes MenuAnimIn1 {
  0% {
    -webkit-transform: translateZ(-500px) rotateY(0deg);
    opacity: 0;
  }
  20% {
    -webkit-transform: translateZ(-250px) rotateY(30deg);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: translateZ(0px) rotateY(0deg);
    opacity: 1;
  }
}

@-webkit-keyframes MenuAnimIn2 {
  0% {
    -webkit-transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    opacity: 1;
  }
}

@-webkit-keyframes MenuAnimIn3 {
  0% {
    -webkit-transform: translateZ(300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0px);
    opacity: 1;
  }
}

@-webkit-keyframes MenuAnimIn4 {
  0% {
    -webkit-transform: translateZ(-300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0px);
    opacity: 1;
  }
}

@-webkit-keyframes MenuAnimIn5 {
  0% {
    -webkit-transform: translateY(40%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    opacity: 1;
  }
}

@keyframes MenuAnimIn1 {
  0% {
    -webkit-transform: translateZ(-500px) rotateY(0deg);
    transform: translateZ(-500px) rotateY(0deg);
    opacity: 0;
  }
  20% {
    -webkit-transform: translateZ(-250px) rotateY(30deg);
    transform: translateZ(-250px) rotateY(30deg);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: translateZ(0px) rotateY(0deg);
    transform: translateZ(0px) rotateY(0deg);
    opacity: 1;
  }
}

@keyframes MenuAnimIn2 {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes MenuAnimIn3 {
  0% {
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
    opacity: 1;
  }
}

@keyframes MenuAnimIn4 {
  0% {
    -webkit-transform: translateZ(-300px);
    transform: translateZ(-300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
    opacity: 1;
  }
}

@keyframes MenuAnimIn5 {
  0% {
    -webkit-transform: translateY(40%);
    transform: translateY(40%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-1 {
  -webkit-animation: SubMenuAnimIn1 0.4s ease;
  animation: SubMenuAnimIn1 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-2 {
  -webkit-animation: SubMenuAnimIn2 0.3s ease-in-out;
  animation: SubMenuAnimIn2 0.3s ease-in-out;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-3 {
  -webkit-animation: SubMenuAnimIn3 0.4s ease;
  animation: SubMenuAnimIn3 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-4 {
  -webkit-animation: SubMenuAnimIn4 0.4s ease;
  animation: SubMenuAnimIn4 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-5 {
  -webkit-animation: SubMenuAnimIn5 0.4s ease;
  animation: SubMenuAnimIn5 0.4s ease;
}

@-webkit-keyframes SubMenuAnimIn1 {
  0% {
    -webkit-transform: translateX(50%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    opacity: 1;
  }
}

@-webkit-keyframes SubMenuAnimIn2 {
  0% {
    -webkit-transform: translateX(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    opacity: 1;
  }
}

@-webkit-keyframes SubMenuAnimIn3 {
  0% {
    -webkit-transform: translateZ(-300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0px);
    opacity: 1;
  }
}

@-webkit-keyframes SubMenuAnimIn4 {
  0% {
    -webkit-transform: translateZ(300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0px);
    opacity: 1;
  }
}

@-webkit-keyframes SubMenuAnimIn5 {
  0% {
    -webkit-transform: translateZ(-200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes SubMenuAnimIn1 {
  0% {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes SubMenuAnimIn2 {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes SubMenuAnimIn3 {
  0% {
    -webkit-transform: translateZ(-300px);
    transform: translateZ(-300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
    opacity: 1;
  }
}

@keyframes SubMenuAnimIn4 {
  0% {
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
    opacity: 1;
  }
}

@keyframes SubMenuAnimIn5 {
  0% {
    -webkit-transform: translateZ(-200px);
    transform: translateZ(-200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-1 {
  -webkit-animation: SubMenuAnimOut1 0.4s ease;
  animation: SubMenuAnimOut1 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-2 {
  -webkit-animation: SubMenuAnimOut2 0.3s ease-in-out;
  animation: SubMenuAnimOut2 0.3s ease-in-out;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-3 {
  -webkit-animation: SubMenuAnimOut3 0.4s ease;
  animation: SubMenuAnimOut3 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-4 {
  -webkit-animation: SubMenuAnimOut4 0.4s ease;
  animation: SubMenuAnimOut4 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-5 {
  -webkit-animation: SubMenuAnimOut5 0.4s ease;
  animation: SubMenuAnimOut5 0.4s ease;
}

@-webkit-keyframes SubMenuAnimOut1 {
  0% {
    -webkit-transform: translateX(0%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50%);
    opacity: 0;
  }
}

@-webkit-keyframes SubMenuAnimOut2 {
  0% {
    -webkit-transform: translateX(0%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%);
    opacity: 0;
  }
}

@-webkit-keyframes SubMenuAnimOut3 {
  0% {
    -webkit-transform: translateZ(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-300px);
    opacity: 0;
  }
}

@-webkit-keyframes SubMenuAnimOut4 {
  0% {
    -webkit-transform: translateZ(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(300px);
    opacity: 0;
  }
}

@-webkit-keyframes SubMenuAnimOut5 {
  0% {
    -webkit-transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-200px);
    opacity: 0;
  }
}

@keyframes SubMenuAnimOut1 {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    opacity: 0;
  }
}

@keyframes SubMenuAnimOut2 {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes SubMenuAnimOut3 {
  0% {
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-300px);
    transform: translateZ(-300px);
    opacity: 0;
  }
}

@keyframes SubMenuAnimOut4 {
  0% {
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    opacity: 0;
  }
}

@keyframes SubMenuAnimOut5 {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-200px);
    transform: translateZ(-200px);
    opacity: 0;
  }
}

/* No JS Fallback */
.no-js .dl-menuwrapper .dl-menu {
  position: relative;
  opacity: 1;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.no-js .dl-menuwrapper li .dl-submenu {
  display: block;
}

.no-js .dl-menuwrapper li.dl-back {
  display: none;
}

.no-js .dl-menuwrapper li > a:not(:only-child) {
  background: rgba(0, 0, 0, 0.1);
}

.no-js .dl-menuwrapper li > a:not(:only-child):after {
  content: "";
}

/* Colors for demos */

/* Demo 1 */
.demo-1 .dl-menuwrapper button {
  background: #c62860;
}

.demo-1 .dl-menuwrapper button:hover,
.demo-1 .dl-menuwrapper button.dl-active,
.demo-1 .dl-menuwrapper ul {
  background: #9e1847;
}

/* Demo 2 */
.demo-2 .dl-menuwrapper button {
  background: #e86814;
}

.demo-2 .dl-menuwrapper button:hover,
.demo-2 .dl-menuwrapper button.dl-active,
.demo-2 .dl-menuwrapper ul {
  background: #d35400;
}

/* Demo 3 */
.demo-3 .dl-menuwrapper button {
  background: #08cbc4;
}

.demo-3 .dl-menuwrapper button:hover,
.demo-3 .dl-menuwrapper button.dl-active {
  background-color: #08cbc4;
}
.demo-3 .dl-menuwrapper ul {
  background: #00b4ae;
}

/* Demo 4 */
.demo-4 .dl-menuwrapper button {
  background: #90b912;
}

.demo-4 .dl-menuwrapper button:hover,
.demo-4 .dl-menuwrapper button.dl-active,
.demo-4 .dl-menuwrapper ul {
  background: #79a002;
}

/* Demo 5 */
.demo-5 .dl-menuwrapper button {
  background: #744783;
}

.demo-5 .dl-menuwrapper button:hover,
  /* .demo-5 .dl-menuwrapper button.dl-active{background-color: } */
  .demo-5 .dl-menuwrapper ul {
  background: #643771;
  background: #643771;
}
/* ======= */
/* @font-face {
  font-family: "icomoon";
  src: url("./fonts/icomoon.html");
  src: url("./fonts/icomoond41d.html?#iefix") format("embedded-opentype"),
    url("./fonts/icomoon-2.html") format("woff"),
    url("./fonts/icomoon-3.html") format("truetype"),
    url("./fonts/icomoon-4.html#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
} */

/* Common styles of menus */

.dl-menuwrapper {
  width: 100%;
  max-width: 100%;
  float: left;
  position: relative;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-perspective-origin: 50% 200%;
  perspective-origin: 50% 200%;
  z-index: 99 !important;
}

.dl-menuwrapper:first-child {
  margin-right: 0px;
}

.dl-menuwrapper button {
  background: #fff;
  border: none;
  width: 35px;
  height: 30px;
  text-indent: -900em;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  outline: none;
  float: right;
  margin-bottom: 8px;
}

.dl-menuwrapper button:hover,
.dl-menuwrapper button.dl-active {
  background-color: #fff;
}
.dl-menuwrapper ul {
  background: #04587b;
}

.dl-menuwrapper button:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background: #414042;
  top: 50%;
  left: 25%;
  -webkit-box-shadow: 0 6px 0 #414042, 0 12px 0 #414042;
  box-shadow: 0 6px 0 #414042, 0 12px 0 #414042;
}

.dl-menuwrapper ul {
  padding: 0;
  list-style: none;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.dl-menuwrapper li {
  position: relative !important;
  float: none !important;
}

.dl-menuwrapper li a {
  display: block;
  position: relative;
  padding: 15px 20px !important;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  color: #fff !important;
  outline: none;
}

.no-touch .dl-menuwrapper li a:hover {
  background: rgba(255, 248, 213, 0.1);
}

.dl-menuwrapper li.dl-back > a {
  padding-left: 30px;
  background: #04587b;
}

.dl-menuwrapper li.dl-back:after {
  position: absolute;
  top: 0;
  line-height: 50px;
  font-family: "FontAwesome";
  /* speak: none; */
  -webkit-font-smoothing: antialiased;
  content: "\f060";
}

.dl-menuwrapper li.dl-back:after {
  left: 25px;
  font-size: 20px;
  cursor: pointer;
  color: #fff;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.dl-menuwrapper li > a:after {
  right: 10px;
  color: rgba(0, 0, 0, 0.15);
}

.dl-menuwrapper .dl-menu {
  margin: 40px 0 0 0;
  position: absolute;
  width: 100%;
  opacity: 0;
  background-color: #005a7e;
  pointer-events: none;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.dl-menuwrapper .dl-menu.dl-menu-toggle {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.dl-menuwrapper .dl-menu.dl-menuopen {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

/* Hide the inner submenus */
.dl-menuwrapper li .dl-submenu {
  display: none;
  float: none !important;
}

/*
  When a submenu is openend, we will hide all li siblings.
  For that we give a class to the parent menu called "dl-subview".
  We also hide the submenu link.
  The opened submenu will get the class "dl-subviewopen".
  All this is done for any sub-level being entered.
  */
.dl-menu.dl-subview li,
.dl-menu.dl-subview li.dl-subviewopen > a,
.dl-menu.dl-subview li.dl-subview > a {
  display: none;
}

.dl-menu.dl-subview li.dl-subview,
.dl-menu.dl-subview li.dl-subview .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu > li {
  display: block;
}

/* Dynamically added submenu outside of the menu context */
.dl-menuwrapper > .dl-submenu {
  position: absolute;
  width: 100%;
  top: 50px;
  left: 0;
  margin: 0;
}

.accordion-button:not(.collapsed)::after {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

@media only screen and (max-width: 479px) {
  .navigation {
    padding-top: 0;
  }
  .logo a img {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .navigation {
    padding-top: 10px;
  }
}

/* >>>>>>> .theirs */

/* Header mobile menu styles ends here  */

/* Footer section strat from here */

.footer {
  width: 100%;
  float: left;
  background-color: #ebf2f5;
}
.contact_datail p {
  padding-top: 25px;
  font-size: 14px;
  color: #414042;
  margin-bottom: 25px;
}
.contact_datail h4 {
  font-size: 18px;
  color: #fff;
  margin-bottom: 15px;
}
.contact_datail ul {
  display: inline-block;
  margin-bottom: 20px;
}
.contact_datail ul li {
  float: left;
  display: inline-block;
}
.contact_datail ul li a {
  display: block;
  color: #414042;
  text-decoration: none;
  font-size: 14px;
  margin-right: 10px;
}
.contact_datail ul li a .fa {
  margin-right: 10px;
}
.contact_datail .input-group {
  float: left;
}
.contact_datail h4 {
  font-size: 18px;
  font-weight: 300;
  color: #005880;
  margin-bottom: 15px;
}
#results {
  width: 100%;
  display: block;
  color: #fff;
}
.payment_method_ftr {
  width: 100%;
  float: left;
  margin: 5px 0;
}
.payment_method_ftr ul {
  margin-bottom: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.payment_method_ftr ul li {
  display: inline-block;
}
.payment_method_ftr ul li a {
  margin-right: 15px;
}
.payment_method_ftr ul li a img {
  max-width: 100%;
  margin-bottom: 0;
}
.ftr_social_icon {
  width: 100%;
  float: left;
}
.ftr_social_icon h4 {
  font-size: 20px;
  color: #005880;
  text-align: left;
}
.ftr_social_icon ul {
  display: block;
}
.ftr_social_icon ul li {
  margin-left: 0;
}
.contact_datail ul li {
  display: inline-block;
}
.ftr_social_icon ul li a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #fff;
  border-radius: 50px;
  text-align: center;
}
.ftr_social_icon ul li a .fa.fa-facebook {
  color: #3b5998;
}
.contact_datail {
  width: 100%;
  padding-right: 25px;
}
.privacy ul {
  float: right;
}
.privacy ul li:nth-child(1) {
  border-left: none;
}
.privacy ul li a {
  padding: 0 10px;
  color: #414042;
}
.privacy ul li {
  float: left;
  border-left: 1px solid #414042;
}

.upper_section {
  width: 100%;
  float: left;
  padding: 50px 0;
}
.ftr_services {
  width: 100%;
  float: left;
}
.ftr_services h3 {
  font-size: 18px;
  color: #005880;
  font-weight: 300;
  margin: 15px 0;
}
.ftr_services ul li {
  display: block;
  margin-bottom: 5px;
}
.ftr_services ul li a {
  text-decoration: none;
  color: #414042;
  font-weight: 400;
  font-size: 14px;
}
.text-muted {
  color: #979797 !important;
}
.privacy {
  width: 100%;
  float: left;
  border-top: 1px solid #676767;
  padding: 20px 0;
}
.privacy p {
  color: #414042;
  font-size: 14px;
}

/* Footer section ends here */

/* Testimonails Starts here */

.testimonials {
  width: 100%;

  text-align: center;
}
/* .testimonials h2 {
  color: #005880;
  font-weight: 400;
}
.testimonials h5 {
  font-size: 25px;
  color: #414042;
  margin-top: -5px;
  font-weight: 300;
} */
.scnd_divider {
  background-color: #fff !important;
  margin-bottom: 3rem !important;
}
.client_comment {
  width: 100%;
  background-color: #ebf2f5;
  text-align: left;
  padding: 20px 20px 30px;
  position: relative;
  border-radius: 5px;
}
.client_comment:before {
  position: absolute;
  left: 38px;
  bottom: -5px;
  content: "";
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: #fff;
}
.client_comment .icon {
  font-size: 40px;
  color: #005880;
}
.ws-comas:before {
  content: "\e019";
}
.client_comment p {
  color: #414042;
  font-size: 14px;
}
.client_comment p a {
  text-decoration: underline;
  color: #414042;
}
.show-read-more .more-text {
  display: none;
  text-transform: capitalize;
  font-style: italic;
  font-weight: 600;
}
.client_comment span {
  font-size: 70px;
  display: none;
  color: #0391cc;
  display: contents;
  left: 20px;
  position: absolute;
  top: 0;
}
.client_img {
  width: 100%;
  float: left;
  padding-top: 20px;
}
.client_img .img_block {
  width: 72px;
  height: 72px;
  border-radius: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  line-height: 90px;
  float: left;
}
.client_img .img_block img {
  max-width: 100%;
  border-radius: 100%;
}
.client_name {
  width: 70%;
  float: left;
  text-align: left;
  padding: 15px 0 0 20px;
}
.client_name h4 {
  font-size: 16px;
  color: #414042;
  font-weight: 400;
}
.client_name p {
  font-size: 14px;
  color: #414042;
  margin-top: 0;
  font-weight: 300;
}
/* Testimonails ends */
/* section {
  margin: 100px 0px;
} */
/* Domain Registration search domain */

.about_bnr {
  display: table;
  height: 380px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.about_bnr,
.passion,
.vision_section {
  width: 100%;
  text-align: center;
  float: left;
}
.align_about_text {
  display: table-cell;
  vertical-align: middle;
  background: #ebf2f5;
}
.about_bnr h2 {
  color: #414042;
  margin-bottom: 25px;
  font-weight: 400;
}
.about_bnr p {
  color: #414042;
  font-size: 25px;
  margin-top: -5px;
  font-weight: 300;
  margin-bottom: 25px;
}
.align_about_text .pl-5 {
  padding-left: 90px !important;
}
.align_about_text .pr-5 {
  padding-right: 90px !important;
}
.align_about_text .form-control {
  border-radius: 0;
  background-color: #f5f5f5 !important;
  color: #898989;
  padding: 20px;
  border: 1px solid #f5f5f5;
  background-clip: border-box;
}
.btn {
  font-size: 16px;
  margin: 20px 0;
  text-transform: capitalize;
  border: 1px solid #333;
  color: #504e4f;
  background-color: transparent;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.align_about_text button {
  background-color: #005880;
  top: 0;
  height: 100%;
  border-radius: 0;
  width: auto;
  display: inline-block;
  /* padding: 10px 70px; */
  line-height: inherit;
}

/* slider styles */

.domain_renewls {
  width: 100%;
  float: left;
  margin-bottom: 100px;
  text-align: center;
}
.domain_renewls h2 {
  color: #005a7e;
  font-weight: 700;
}
.domain_renewls h5 {
  font-size: 25px;
  color: #414042;
  margin-top: -5px;
  font-weight: 600;
}

.ssliderhost {
  text-align: center;
  padding-bottom: 0px !important;
  padding: 60px 0;
}
/* .ssliderhost h2 {
  color: #005880;
  font-size: 48px;
  font-weight: 400;
}
.ssliderhost h5 {
  color: #414042;
  font-size: 25px;
  font-weight: 300;
} */
.scnd_divider {
  background-color: #fff;
}

.ssliderhost ul {
  /* display: inline-flex; */
  margin-top: 40px;
  margin-bottom: 40px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.nav-tabs {
  border: none;
}
.ssliderhost ul li {
  list-style: none;
}
.ssliderhost .nav-tabs .nav-link {
  border-radius: 0;
  background-color: #fff;
  color: #005a7e;
  padding: 10px 25px;
  text-transform: capitalize;
}
.ssliderhost .nav-tabs .nav-link .fa {
  margin-right: 10px;
  font-size: 25px;
}
.pkgs_tab {
  width: 100%;
  padding: 0;
}
.ssliderhost .tab-content .tab-pane {
  position: relative;
}
.ssliderhost .nav-tabs .nav-link.active {
  background-color: #ebf2f5;
  color: #414042;
  border: 1px solid #fff;
}

/* renewls_tabs */
.renewls_tabs {
  width: 100%;
  float: left;
  text-align: left;
}
.renewls_tabs ul li a {
  background-color: #ebf2f5;
  color: #414042;
  font-size: 22px;
  font-weight: 600;
  padding: 20px 30px;
  text-align: center;
  border-radius: 0 !important;
  border: none !important;
}
.renewls_tabs ul li a.active {
  background-color: #005880 !important;
  color: #fff !important;
  border-radius: 0 !important;
  border: none !important;
}
.renewls_tabs .tab-content {
  width: 100%;
  text-align: left;
  padding-top: 50px;
}
.renewls_tabs .tab-content .tab-pane p {
  font-size: 20px;
  color: #414042;
  margin-bottom: 10px;
}
.renewls_tabs .tab-content .tab-pane .download_btn {
  width: 100%;
  float: left;
  background-color: #f9f9f9;
  border: 1px solid #d3d3d3;
  height: 100px;
  display: table;
  padding: 0 50px;
  margin-top: 50px;
}
.renewls_tabs .tab-content .tab-pane .download_btn .d-table-cell h5 {
  font-size: 25px;
  color: #414042;
  margin-top: 0;
  width: 70%;
  font-weight: 300;
  display: inline-block;
  line-height: 32px;
}
.renewls_tabs .tab-content .tab-pane .download_btn .d-table-cell h5 span {
  display: inline;
  font-weight: 600;
}
.renewls_tabs .tab-content .tab-pane .download_btn .d-table-cell button {
  margin: 0;
  float: right;
  padding: 17px 30px;
  font-size: 20px;
  background-color: #6fcd32;
  border: none;
  color: #fff;
  border-radius: 0;
  border: none;
}
.renewls_tabs .tab-content .tab-pane .download_btn .d-table-cell button .fa {
  margin-right: 15px;
  font-size: 20px;
}

.hosting_banr,
.compare_plans,
.all_plans,
.website_basics,
.explore_more,
.technical_specification,
.compare_plan,
.accout_migration,
.frequently,
.need_help,
.nmbr_one {
  width: 100%;
  float: left;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.explore_more {
  display: table;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  position: relative;
}
.explore_scnd {
  position: relative;
}
.explore_scnd p {
  color: #fff;
}
.explore_scnd:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255);
  width: 100%;
  height: 100%;
}
.explore_more h2 {
  color: #005a7e;
  font-weight: 700;
}
.explore_more h5 {
  font-size: 25px;
  color: #414042;
  margin-top: -5px;
  font-weight: 600;
  position: relative;
}
.explore_scnd h2 {
  color: #005a7e;
  position: sticky;
  font-weight: 600;
}
.explore_scnd h5 {
  font-size: 25px;
  color: #414042;
  margin-top: -5px;
  font-weight: 300;
  position: relative;
}
.explore_center_data {
  display: table-cell;
  vertical-align: middle;
}
.business_hosting {
  width: 46%;
  float: left;
  text-align: center;
  display: table;
  position: sticky;
  border: 1px solid #e6e6e6;
  height: 150px;
  border-left: 5px solid #005880;
}
.center_hosting {
  display: table-cell;
  vertical-align: middle;
  background-color: #ebf2f5;
  text-align: left;
  padding-left: 80px;
  padding-top: 25px;
  padding-bottom: 25px;
}
.center_hosting p {
  font-size: 17px;
  color: #414042;
  margin-bottom: -8px;
}
.center_hosting h5 {
  font-size: 35px;
  color: #414042;

  font-weight: 600;
  margin-bottom: 25px;
}
.center_hosting button {
  font-size: 16px;
  cursor: pointer;
  color: #fff;
  background-color: #005880;
  border: none;
  width: 130px;
  height: 40px;
  text-transform: capitalize;
}
/* .center_hosting button:hover {
  background-color: #57ad20;
} */
.dashed {
  width: 8%;
  float: left;
  position: sticky;
}
.divider_two {
  max-width: 20px;
  border-width: 3px;
  background-color: #e4e4e4;
  margin-top: 70px;
}
.scnd_hosting p {
  color: #414042;
}
.scnd_hosting h5 {
  color: #414042;
}

/* Stumped */
/* .technical_specification {
  margin-bottom: 100px;
} */

.technical_specification p {
  font-size: 16px;
  text-align: left;
  font-weight: 400;
  color: #414042;
}
.technical_specification p a {
  text-decoration: underline;
  color: #005a7e;
  font-weight: 700;
}
.technical_specification ul {
  margin-top: 20px;
}
.technical_specification ul li {
  float: left;
}
.technical_specification ul li:nth-child(4),
.technical_specification ul li:nth-child(5),
.technical_specification ul li:nth-child(6) {
  margin-bottom: 0;
}

.secure_server {
  width: 100%;
  float: left;
  padding: 20px 10px;
}
.secure_server .icon {
  font-size: 100px;
  color: #005880;
  margin-bottom: -31px;
}
.secure_server img {
  max-width: 100%;
  margin-bottom: 10px;
}
.secure_server h5 {
  font-size: 25px;
  color: #414042;
  margin-bottom: 20px;
  font-weight: 400;
}
.secure_server p {
  font-size: 14px;
  color: #414042;
  text-align: justify;
  font-weight: 400;
  -moz-text-align-last: center;
  text-align-last: center;
  padding: 0 10px;
}

/* Account MIgration */
.accout_migration {
  background-color: #005880;
  margin-bottom: 100px;
}
.accout_migration h2 {
  color: #fff;
  font-weight: 700;
}
.migration_text {
  width: 100%;
  float: left;
  text-align: left;
  padding: 100px 0;
}
.migration_text h2 {
  line-height: 55px;
}
.migration_text p {
  font-size: 25px;
  color: #fff;
  margin-bottom: 70px;
}
.migration_text button {
  width: 200px;
  height: 50px;
  border: none;
  background-color: #6fcd32;
  color: #fff;
  font-size: 16px;
  text-transform: capitalize;
}
.migration_text button:hover {
  background-color: #57ad20;
}
.plane_img {
  width: 100%;
  float: left;
  padding: 60px 0;
}

/* AE Domain Hosting in Pakistan */
.pakistan_hosting {
  width: 100%;
  float: left;
  margin-bottom: 100px;
  text-align: center;
}

.websouls_experience {
  width: 100%;
  float: left;
  text-align: left;
}
.websouls_experience h5 {
  font-size: 18px;
  color: #414042;
  margin-bottom: 10px;
}
.websouls_experience p {
  font-size: 16px;
  color: #414042;
  margin-bottom: 10px;
  text-align: justify;
  font-weight: 300;
}
.websouls_experience p a {
  text-decoration: underline;
  color: #005a7e;
  font-weight: 700;
}
.websouls_experience p span {
  color: #005a7e;
  display: inline-block;
  font-weight: 400;
}
.websouls_experience p strong {
  font-weight: 700;
  color: #005a7e;
}

/* Domain trandfer Steps */
.domain_transfer_step {
  width: 100%;
  float: left;
  margin-bottom: 40px;
}
.steps .step_one {
  width: 20%;
  background-color: #005a7e;
  text-align: center;
  display: table;
  height: 150px;
  padding: 20px;
}
.steps .step_one span {
  font-size: 35px;
  text-transform: capitalize;
  color: #fff;
  font-weight: 600;
  border-right: #fff 1px dashed;
  padding: 10px 30px;
}
.steps {
  -webkit-box-shadow: #ccc 0 0 5px 2px;
  box-shadow: #ccc 0 0 5px 2px;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  float: left;
}
.step_process {
  width: 80%;
  display: table;
  height: 150px;
  background-color: #fff;
  padding: 0 45px 0 60px;
}
.step_process h3 {
  color: #005a7e;
  font-size: 25px;
}
.step_process p {
  font-size: 14px;
  color: #414042;
}

/* VPS HOSTING Page Why Choose Us */
.not_sure {
  width: 100%;
  float: left;
  text-align: center;
  display: table;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 370px;
  margin-bottom: 100px;
}
.not_sure_text {
  display: table-cell;
  vertical-align: middle;
  background: rgba(0, 90, 125, 0.8);
}
.not_sure_text h3 {
  font-size: 48px;
  text-transform: capitalize;
  color: #fff;
  font-weight: 700;
  margin-bottom: 40px;
}
.not_sure_text p {
  font-size: 25px;
  margin-bottom: 50px;
  text-transform: none;
  color: #cdcdcd;
  font-weight: 400;
  padding: 0 60px;
}
.not_sure_text ul {
  height: 65px;
}
.not_sure_text ul li {
  display: inline-block;
  margin-left: 60px;
  background-color: transparent;
}
.not_sure_text ul li a {
  background-color: #6fcd32;
  color: #fff;
  font-weight: 600;
  font-size: 25px;
  text-decoration: none;
  padding: 15px 35px 20px;
  border-radius: 3px;
}
.not_sure_text ul li a img {
  padding-right: 15px;
}
.not_sure_text ul li:nth-child(1) {
  margin-left: 0;
}
.not_sure_text ul li:nth-child(2) a {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
}
.not_sure_text ul li:nth-child(2) a:hover {
  background-color: #6fcd32;
  border: 1px solid #6fcd32;
}
.not_sure_text ul li:nth-child(1) a:hover {
  background-color: #6fcd32;
  border: 1px solid #6fcd32;
}

.passion {
  text-align: left;
}
.passion ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  float: left;
}
.passion_text {
  width: 100%;
  float: left;
}
.passion_text h3 {
  font-size: 45px;
  display: block;
  width: 55%;
  float: left;
  color: #046289;
  line-height: 40px;
  font-weight: 600;
}
.passion_text h3 span {
  display: block;
  font-size: 65px;
  line-height: 60px;
  font-weight: 700;
}
.passion_text p {
  text-align: justify;
  margin-bottom: 20px;
  font-size: 16px;
  color: #414042;
}
.passion_img {
  width: 100%;
  float: left;
}
.passion_img img {
  max-width: 100%;
}
.passion_text h3 .dreams {
  font-size: 40px;
  line-height: 45px;
}
.passion_text ul {
  display: block;
  list-style: disc;
}
.passion_text ul li {
  font-size: 16px;
  color: #414042;
  display: inline-block;
  float: none;
}

.vision_section {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 100px;
}
.vision_section ul li {
  float: left;
  display: block;
}
.vision_text {
  width: 100%;
  float: left;
  background-color: #fff;
  text-align: left;
}
.vision_text .icon {
  font-size: 100px;
  color: #00638d;
  margin-bottom: 10px;
}
.vision_text img {
  margin-bottom: 10px;
}
.vision_text h3 {
  font-size: 40px;
  color: #046289;
  margin-bottom: 10px;
  font-weight: 400;
  margin-top: -30px;
}
.vision_text p {
  color: #414042;
  font-size: 15px;
  text-align: justify;
  padding-bottom: 15px;
}

/* Team */
.passion {
  text-align: left;
}
.passion ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  float: left;
}
.passion_text {
  width: 100%;
  float: left;
}
.passion_text h3 {
  font-size: 45px;
  display: block;
  width: 55%;
  float: left;
  color: #046289;
  line-height: 40px;
  font-weight: 600;
}
.passion_text h3 span {
  display: block;
  font-size: 65px;
  line-height: 60px;
  font-weight: 700;
}
.passion_text p {
  text-align: justify;
  margin-bottom: 20px;
  font-size: 16px;
  color: #414042;
}
.passion_img {
  width: 100%;
  float: left;
}
.passion_img img {
  max-width: 100%;
}
.passion_text h3 .dreams {
  font-size: 40px;
  line-height: 45px;
}
.passion_text ul {
  display: block;
  list-style: disc;
}
.passion_text ul li {
  font-size: 16px;
  color: #414042;
  display: inline-block;
  float: none;
}
.vision_section {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 100px;
}
.vision_section ul li {
  float: left;
  display: block;
}
.vision_text {
  width: 100%;
  float: left;
  background-color: #fff;
  text-align: left;
}
.vision_text .icon {
  font-size: 85px;
  color: #00638d;
  margin-bottom: 10px;
}
.border-right {
  border-right: 2px solid #046289 !important;
}
.vision_text img {
  margin-bottom: 7px;
}
.vision_text h3 {
  font-size: 40px;
  color: #046289;
  margin-bottom: 10px;
  font-weight: 400;
}
.vision_text p {
  color: #414042;
  font-size: 15px;
  text-align: justify;
}
.sales_inquiry {
  width: 100%;
  float: left;
  margin-bottom: 100px;
  text-align: center;
}
.career_envoirment {
  margin-bottom: 100px;
}
.sales_inquiry ul {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-align: center;
}
.sales_inquiry ul li {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.inquiry_block {
  width: 100%;
  float: left;
  margin-bottom: 30px;
}
.inquiry_block p a {
  font-size: 18px;
  color: #6d6b6e;
  display: initial;
}
.inquiry_block p a:hover {
  text-decoration: none;
  color: inherit;
}
.inquiry_block .icon {
  font-size: 100px;
  color: #039bd8;
  margin-bottom: 10px;
  text-decoration: none;
}
.inquiry_block a span .icon {
  font-size: 70px;
  color: #0e6484;
  margin-bottom: 10px;
  border: 1px solid #0e6484;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  line-height: 100px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.inquiry_block a span img {
  max-width: 100%;
}
.inquiry_block h3 {
  color: #116484;
  font-size: 25px;
  font-weight: 600;
  padding: 0 40px;
  line-height: 25px;
  margin-bottom: 20px;
}
.inquiry_block p {
  color: #6d6b6e;
  font-size: 18px;
  margin-bottom: 30px;
}
.inquiry_block button {
  width: 175px;
  height: 40px;
  color: #fff;
  background-color: #6fcd32;
  border: none;
  font-size: 18px;
  text-transform: capitalize;
}
.career_team {
  width: 100%;
  text-align: center;
}
.career_team h2 {
  color: #005a7e;
  font-weight: 600;
}
.career_team h5 {
  font-size: 25px;
  color: #414042;
}
.carer_team_paragraph {
  width: 100%;
  float: left;
}
.carer_team_paragraph p {
  font-size: 20px;
  color: #414042;
  margin-bottom: 50px;
}
.career_team img {
  max-width: 100%;
}

.accordian_sction {
  width: 100%;
  float: left;
}
.accordian_sction button {
  width: 100%;
  text-align: left;
  padding: 15px 15px 15px 45px;
  border: none;
  background-color: #f8f8f8;
  font-size: 25px;
  color: #58595b;
  font-weight: 600;
  margin-bottom: 30px;
  cursor: pointer;
  outline: none;
  position: relative;
}

.accordian_sction button:before {
  content: "\f068";
  position: absolute;
  right: 15px;
  color: #414042;
  transition: all 0.6s ease-in-out 0s;
  -moz-transition: all 0.6s ease-in-out 0s;
  -ms-transition: all 0.6s ease-in-out 0s;
  -o-transition: all 0.6s ease-in-out 0s;
  -webkit-transition: all 0.6s ease-in-out 0s;
  font-size: 14px;
  top: 40%;
  padding-right: 5px;
  font-family: FontAwesome;
}
.accordian_sction button:after {
  content: "\f067";
  position: absolute;
  right: 20px;
  color: #414042;
  transition: all 0.6s ease-in-out 0s;
  -moz-transition: all 0.6s ease-in-out 0s;
  -ms-transition: all 0.6s ease-in-out 0s;
  -o-transition: all 0.6s ease-in-out 0s;
  -webkit-transition: all 0.6s ease-in-out 0s;
  font-size: 14px;
  top: 40%;
  font-family: FontAwesome;
}
.accordian_sction button.active:after {
  display: none;
  transition: all 0.6s ease-in-out 0s;
  -moz-transition: all 0.6s ease-in-out 0s;
  -ms-transition: all 0.6s ease-in-out 0s;
  -o-transition: all 0.6s ease-in-out 0s;
  -webkit-transition: all 0.6s ease-in-out 0s;
}
.panel {
  width: 100%;
  float: left;
  text-align: left;
  padding: 0 25px 20px;
}
.panel ul {
  margin-bottom: 10px;
  padding-left: 15px;
}
.panel ul li {
  font-size: 14px;
  list-style: decimal;
}
.panel p {
  font-size: 15px;
  margin-bottom: 10px;
  color: #414042;
  font-weight: 400;
}
.accordian_sction .panel a {
  font-size: 16px;
  background-color: #6fcd32;
  padding: 10px 30px;
  color: #fff;
  border: none;
  margin: 15px 0 0;
  display: inline-block;
  margin-top: 10px;
}
.accordian_sction .panel a .fa {
  margin-left: 10px;
}
.faq_tabs .nav-tabs .nav-item {
  margin: 0 5px 5px 0;
  width: auto;
}

/* Why Choose US */
.scnd_divider {
  background-color: #fff;
}
.row-centered {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.col-centered {
  display: inline-block;
  float: none;
  text-align: left;
  text-align: center;
}
.serving_services {
  width: 100%;
  float: left;
  background-color: #fff !important;
  height: 160px;
  display: table;
  border-bottom: 8px solid #005880;
}
.serving_center {
  display: table-cell;
  vertical-align: middle;
}
.serving_center img {
  max-width: 100%;
}
.serving_center p {
  font-size: 28px;
  color: #414042;
  font-weight: 600;
}
.registration {
  width: 100%;
  float: left;
  margin-bottom: 100px;
  text-align: center;
  background-color: #fff;
}
.registration h2 {
  color: #046289;
  font-size: 48px;
  text-transform: capitalize;
  font-weight: 600;
}
.registration h5 {
  color: #414042;
  font-size: 25px;
  margin-top: -5px;
}
.mb-4,
.my-4 {
  margin-bottom: 3.5rem !important;
}
.registration ul li {
  float: left;
}
.hosting_srvcs {
  width: 100%;
  float: left;
  padding: 20px 20px 40px;
  margin-bottom: 30px;
  text-align: left;
  border-bottom: 5px solid #046289;
  cursor: pointer;
  position: relative;
  z-index: 999;
  transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
}
.hosting_srvcs h3 {
  font-size: 25px;
  color: #414042;
  text-transform: capitalize;
  margin-bottom: 20px;
  font-weight: 600;
  padding-right: 50px;
  line-height: 32px;
  transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
}
.hosting_srvcs p {
  font-size: 16px;
  color: #414042;
  text-transform: none;
  margin-bottom: 20px;
  font-weight: 400;
  transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
}
.hosting_srvcs a {
  background-color: #046289;
  font-size: 18px;
  color: #fff;
  border: none;
  padding: 10px 15px;
  transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
}
.registration ul li .hosting_srvcs a .fa-arrow-right {
  margin-left: 10px;
  font-size: 18px;
  font-weight: lighter;
  transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
}
.bg_overlay {
  background-color: #ebf2f5 !important;
  width: 100%;
  height: 0;
  transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
  background-repeat: no-repeat;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  position: absolute;
  z-index: -1;
  background-size: cover;
}
.hosting_srvcs:hover .bg_overlay {
  height: 100%;
  transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
}
.hosting_srvcs:hover .bg_overlay {
  height: 100%;
  transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
}
.hosting_srvcs:hover h3 {
  transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
}
.hosting_srvcs:hover p {
  transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
}
.hosting_srvcs:hover a {
  background-color: #046289;

  transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
}
.hosting_srvcs:hover {
  border-bottom: 5px solid #046289;
  transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
}
/* Payment */
.payment_gateway {
  width: 100%;
  float: left;
  padding: 100px 0;
  margin-top: -100px;
}
.payment_gateway {
  width: 100%;
  float: left;
  background-color: #ebf2f5;
}
.payment_tabs {
  width: 100%;
  float: left;
  background-color: #fff;
  -webkit-box-shadow: #848383 0 0 8px 0;
  box-shadow: #848383 0 0 8px 0;
}
.payment_tabs ul {
  width: 20%;
  display: block;
  float: left;
}
.nav-tabs {
  border: none;
}
.payment_gateway .nav-tabs .nav-link {
  color: #414042;
  font-size: 20px;
  font-weight: 550;
}
.payment_gateway .nav-tabs .nav-link img {
  max-width: 100%;
  margin-bottom: 5px;
}
.payment_gateway .nav-tabs .nav-link,
.payment_gateway .nav-tabs .nav-link.active {
  border: none;
  text-align: center;
  padding: 20px 0;
}
.payment_gateway .nav-tabs .nav-item {
  width: 100%;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#ebf2f5),
    to(#ebf2f5)
  );
  background-image: -o-linear-gradient(top, #ebf2f5, #ebf2f5);
  background-image: linear-gradient(to bottom, #ebf2f5, #ebf2f5);
  position: relative;
}
.payment_gateway .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#fff),
    to(#fff)
  );
  background-image: -o-linear-gradient(bottom, #fff, #fff);
  background-image: linear-gradient(to top, #fff, #fff);
}
.payment_gateway .tab-content {
  width: 80%;
  float: left;
  padding: 30px 0 0 70px;
}
.payment_gateway .tab-content ul {
  width: 100%;
}
.dashed_border {
  padding: 100px 100px 0;
  border-bottom: 1px dashed #999;
  width: 100%;
  float: left;
}
.payment_img {
  text-align: left;
  width: 100%;
  float: left;
}
.payment_img img {
  max-width: 100%;
  margin-bottom: 20px;
}
.payment_text {
  width: 100%;
  float: left;
  padding-right: 50px;
}
.payment_text p {
  margin-bottom: 20px;
  color: #414042;
  font-size: 19px;
  font-weight: 200;
}
.payment_text ul li {
  width: 50%;
  float: left;
  list-style: inside;
  color: #414042;
  margin-bottom: 5px;
}
.nav-tabs .nav-item:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  /* background-image: url(../images/arrow.png); */
  background-repeat: no-repeat;
  background-position: center;
  bottom: -55px;
  display: none;
}
.nav-tabs .nav-item.active:before .arrow_img {
  display: inline-block;
  text-align: center;
}
.pkgs_tab {
  width: 100%;
  padding: 0;
}
.payment_method {
  width: 100%;
  float: left;
  padding-bottom: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.payment_mthod_img {
  width: 100%;
  float: left;
}
.payment_mthod_img img {
  max-width: 100%;
}
.payment_method_detail {
  width: 100%;
  float: left;
}
.payment_method_detail h3 {
  font-size: 45px;
  color: #005a7e;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 20px;
}
.payment_method_detail p {
  font-size: 24px;
  color: #414042;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 30px;
}
.payment_method_detail ul li {
  margin-bottom: 30px;
  padding-left: 70px;
  position: relative;
}
.payment_method_detail ul li h4 {
  font-size: 24px;
  color: #414042;
  font-weight: 600;
}
.payment_method_detail ul li p {
  font-size: 18px;
  color: #6f6f6f;
  font-weight: 200;
  line-height: 20px;
}
.payment_method_detail ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  width: 100%;
  height: 100%;
  /* background-image: url(../img/green_tick.png); */
  background-repeat: no-repeat;
  background-position: left top;
}

.all_content {
  padding: 50px 0 0;
}
.all_content .sales_inquiry a:hover {
  text-decoration: none !important;
}

/* Contact */
.reach_us {
  width: 100%;
  float: left;
  text-align: center;
  padding: 50px 0;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.reach_us:before {
  position: absolute;
  content: "";
  background: rgba(0, 90, 126, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.reach_us h2 {
  color: #fff;
  position: sticky;
  font-weight: 700;
}
.reach_us h5 {
  font-size: 25px;
  color: #fff;
  position: sticky;
  margin-top: -5px;
  font-weight: 600;
}
.reach_us ul li {
  float: left;
  border-left: 1px solid #fff;
}
.reach_us ul li:nth-child(1) {
  border: none;
}
.reach_sction {
  width: 100%;
  float: left;
  text-align: left;
  padding-right: 20px;
}
.reach_sction p {
  font-size: 25px;
  color: #fff;
  font-weight: 300;
  margin-bottom: 30px;
}
.reach_sction h3 {
  font-size: 40px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 35px;
}
.reach_sction h5 {
  font-size: 25px;
  color: #fff;
  font-weight: 300;
  margin-bottom: 15px;
}
.reach_sction p strong {
  font-weight: 600;
}
.reach_us ul li:nth-child(2) .reach_sction {
  padding: 0 95px;
}
.map {
  width: 100%;
  float: left;
  margin-bottom: -7px;
}
.map iframe {
  width: 100%;
  height: 360px;
}

/* Client */
.clients {
  width: 100%;
  float: left;
  padding: 0;
}
.clients p {
  font-size: 17px;
  text-align: left;
  margin-bottom: 50px;
  color: #414042;
}
.clients ul {
  padding: 0 15px;
}
.clients ul li {
  margin-bottom: 0;
}
.client_logo {
  width: 100%;
  padding: 20px;
  text-align: center;
  height: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 10px solid #ebf2f5;
  border-radius: 30px;
  margin-bottom: 30px;
}
.client_logo:hover:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
}
.client_logo img {
  max-width: 100%;
  z-index: 999999;
}
.clients_pagination {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 30px 0;
}
.clients_pagination .page-link {
  padding: 10px;
  color: #1e1e1e;
}
.clients_pagination .page-item.active .page-link {
  color: #fff;
  background-color: #005880;
  border-color: #005880;
}
.clients_pagination .page-item.disabled .page-link {
  color: #1e1e1e;
}
.clients_pagination .page-link:hover {
  color: #fff;
  background-color: #005880;
  border-color: #005880;
}
.faq_tabs .nav-tabs .nav-item .nav-link.active {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#6fcd32),
    to(#6fcd32)
  );
  background-image: -o-linear-gradient(bottom, #005880, #005880);
  background-image: linear-gradient(to top, #005880, #005880);
  color: #fff !important;
}

.faq_tabs .nav-tabs .nav-item .nav-link {
  background-color: #ebf2f5 !important;
  color: black !important;
  font-size: 14px;
  border-radius: 0;
  border: none;
  padding: 6px 15px;
}

/* server slider margin */

.margin10 {
  margin: 10px !important;
}

/* add domain */

.search_com {
  width: 100%;
  float: left;
}
.selection_tab {
  width: 100%;
  float: left;
  /* padding: 50px 30px; */
  text-align: center;
}
.search_domain_div {
  padding-top: 50px;
}
.selection_tab h3 {
  font-size: 36px;
  color: #414042;
  margin-bottom: 20px;
}
.search_domain_div .form-control {
  padding: 17px 30px;
  border-color: #707070;
  color: #a0a0a0;
}
.search_domain_div button.input-group-text {
  background-color: #fff;
  color: #005880;
  font-size: 35px;
  padding: 0 20px;
  cursor: pointer;
  border: 0;
}
.search_domain_div button.input-group-text .fa {
  font-size: 18px;
}

/* congratulations */

.congratulation_section {
  width: 100%;
  float: left;
}
.search_congratulation_msg {
  width: 100%;
  float: left;
  text-align: left;
  padding: 30px 0;
}
.search_congratulation_msg .fa {
  font-size: 48px;
  color: #005880;
}
.search_congratulation_msg h4 {
  font-size: 25px;
  color: #414042;
  line-height: 28px;
  font-weight: 400;
}
.search_congratulation_msg h4 span {
  color: #005880;
  font-weight: 600;
}
.Premium {
  display: inline-block;
  background-color: #ff5900;
  color: #fff;
  padding: 0 10px;
  border-radius: 50px;
  margin: 0 0 0 7px;
  font-size: 14px;
}
.search_congratulation_msg p {
  color: #414042;
  font-weight: 600;
  font-size: 14px;
  font-weight: 400;
}
.search_congratulation_msg button {
  font-size: 17px;
  color: #fff;
  border: none;
  padding: 10px 15px;
  background-color: #6fcd31;
}
.search_congratulation_msg button .fa {
  font-size: 15px;
  color: #fff;
  margin-left: 10px;
}

/* cart */

.billing_form {
  width: 100%;
  float: left;
  padding-bottom: 100px;
}
.billing_information {
  width: 100%;
  float: left;
  padding: 30px 20px;
  border: 4px solid #f8f8f8;
  border-radius: 3px;
  background-color: #fff;
}
.billing_datail {
  width: 100%;
  float: left;
  background-color: #fff;
  padding: 25px;
  border: 1px solid #ececec;
  margin-bottom: 30px;
}
.billing_datail h4 {
  font-size: 24px;
  color: #414042;
  margin-bottom: 20px;
  font-weight: 400;
  font-style: italic;
}
.billing_datail h4 span {
  font-weight: 700;
  color: #005880;
  text-decoration: underline;
  font-style: normal;
}
.billing_datail p {
  font-size: 16px;
  color: #414042;
  margin: -10px 0 30px;
}
.billing_datail p a {
  color: #1d94ce;
}
.billing_datail label {
  font-size: 16px;
  color: #414042;
  display: block;
}
.billing_datail input[type="text"],
.billing_datail input[type="tel"],
.billing_datail input[type="url"],
.billing_datail input[type="email"],
.billing_datail input[type="password"],
.billing_datail input[type="address"],
.billing_datail input[type="radio"],
.billing_datail input[type="checkbox"] {
  border: 1px solid #e1e1e1;
  padding: 10px;
}
.billing_order_summary {
  width: 100%;
  float: left;
  border: 4px solid #f8f8f8;
  border-radius: 3px;
  background-color: #fff;
  padding: 30px 18px;
}
.billing_order_summary h4 {
  font-size: 24px;
  color: #414042;
  margin-bottom: 20px;
  font-weight: 600;
}
.billing_order_datail {
  border: 1px solid #ececec;
  padding: 25px;
  margin-bottom: 30px;
}
.premium_linux_based {
  width: 100%;
  float: left;
  margin-bottom: 20px;
  display: inline-block;
}
.premium_linux_based h6 {
  font-size: 16px;
  color: #414042;
  margin-bottom: 0;
  display: block;
  font-weight: 600;
}
.premium_linux_based h6 span {
  display: inline-block;
  float: right;
}
.premium_linux_based p {
  font-size: 14px;
  color: #afafaf;
  display: block;
  font-weight: 600;
}
.summary_adds_on {
  margin-bottom: 20px;
  width: 100%;
  padding-bottom: 20px;
  display: inline-block;
  border-bottom: 1px solid #ebf2f5;
}
.summary_adds_on p {
  font-size: 14px;
  color: #606060;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px;
}
.summary_adds_on p span.first_span {
  display: block;
  width: 70%;
  text-align: left;
}
.summary_adds_on p i {
  margin-right: 10px;
  font-weight: 100;
}
.summary_adds_on p span.second_span {
  display: block;
  text-align: right;
  width: 30%;
}
.summary_adds_on span.domain_Register_free {
  font-size: 16px;
  color: #414042;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.summary_adds_on span.domain_Register_free div {
  display: inline-block;
  width: 75%;
}
.summary_adds_on span.domain_Register_free span {
  float: right;
  color: #1d94ce;
  text-transform: capitalize;
  width: 25%;
  text-align: right;
}
.domain_registration_price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  color: #606060;
}
.summary_adds_on p {
  font-size: 14px;
  color: #606060;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px;
}
.domain_registration_price .selected_domain_name {
  display: block;
  width: 75%;
}
.domain_registration_price span {
  display: block;
  width: 25%;
  text-align: right;
}
.summary_adds_on p {
  font-size: 14px;
  color: #606060;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px;
}
.setup_fees {
  padding: 0 25px;
}
.setup_fees p {
  font-size: 14px;
  color: #606060;
  margin-bottom: 5px;
}
.setup_fees p span {
  display: inline-block;
  float: right;
}
.subtotal_summary {
  width: 100%;
  padding: 15px 0;
}
.subtotal_summary h6 {
  font-size: 18px;
  color: #414042;
  font-weight: 600;
}
.checkout_btn {
  width: 100%;
  float: left;
  padding-top: 30px;
}
.checkout_btn ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  float: right;
}
.checkout_btn ul li {
  display: block;
  margin-right: 10px;
}
.checkout_btn ul li a {
  color: #414042;
  text-decoration: none;
  display: block;
  border: 1px solid #414042;
  padding: 10px 20px;
}
.billing_datail {
  width: 100%;
  float: left;
  background-color: #fff;
  padding: 25px;
  border: 1px solid #ececec;
  margin-bottom: 30px;
}
.addons_detail {
  width: 100%;
  float: left;
}
.addons_detail h3 {
  font-size: 30px;
  color: #414042;
  margin-bottom: 20px;
  font-weight: 400;
}
.addons_detail .checkbox_container {
  display: block;
  font-size: 24px;
  color: #414042;
  margin-bottom: 15px;
  padding-left: 30px;
  position: relative;
  font-weight: 400;
}
.addons_detail p {
  font-size: 16px;
  margin-bottom: 40px;
  color: #979797;
}
.addons_detail .checkbox_container input {
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
  cursor: pointer;
}
.addons_detail .checkbox_container .checkedmark {
  position: absolute;
  left: 0;
  top: 6px;
  border-radius: 50px;
  height: 22px;
  width: 22px;
  border: 2px solid #ddd;
}
.addons_detail label span.float-right {
  color: #1d94ce;
  font-weight: 600;
}
.search_domain_price {
  width: 100%;
  float: left;
  text-align: center;
  background-color: #ebf2f5;
  padding: 15px 20px;
  margin-bottom: 15px;
}
.search_domain_price ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.search_domain_price ul li {
  width: 20%;
  background-color: #fff;
  margin: 0 15px;
  padding: 10px;
}
.search_domain_price ul li h5 {
  font-size: 20px;
  color: #1d94ce;
  margin-bottom: 5px;
}
.search_domain_price ul li h6 {
  font-size: 18px;
  color: #414042;
  margin-bottom: 5px;
}
.search_domain_price ul li button {
  display: block;
  background-color: #6fcd31;
  width: 100%;
  color: #fff;
  border: none;
  padding: 5px;
  cursor: pointer;
  text-decoration: none;
}
.search_domain_price ul li button:hover {
  text-decoration: none;
}
.search_domain_price ul li a {
  text-decoration: none;
}
.search_domain_price ul li a:hover {
  text-decoration: none;
}
.search_domain_price {
  width: 100%;
  float: left;
  text-align: center;
  background-color: #ebf2f5;
  padding: 15px 20px;
}
.search_domain_price ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.search_domain_price ul li {
  width: 20%;
  background-color: #fff;
  margin: 0 15px;
  padding: 10px;
}
.search_domain_price ul li h5 {
  font-size: 20px;
  color: #1d94ce;
  margin-bottom: 5px;
}
.search_domain_price ul li h6 {
  font-size: 18px;
  color: #414042;
  margin-bottom: 5px;
}
.search_domain_price ul li button {
  display: block;
  background-color: #6fcd31;
  width: 100%;
  color: #fff;
  border: none;
  padding: 5px;
  cursor: pointer;
  border: 1px solid #d7d7d7;
  text-decoration: none;
}
.chose_payment_method {
  width: 100%;
  float: left;
  border: 1px solid #ececec;
  background-color: #fff;
  /* padding: 25px; */
}
.chose_payment_method h4 {
  /* font-size: 24px; */
  color: #414042;
  margin-bottom: 20px;
  font-weight: 600;
}
.chose_payment_method .checkbox_container {
  display: block;
  font-size: 16px;
  color: #414042;
  padding-left: 30px;
  padding-top: 4px;
  position: relative;
}
.chose_payment_method .checkbox_container input {
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
  cursor: pointer;
}
.chose_payment_method .checkbox_container .checkedmark {
  position: absolute;
  left: 0;
  top: 6px;
  border-radius: 50px;
  height: 22px;
  padding: 5px;
  width: 22px;
  border: 1px solid #ddd;
}

/* won free domain */

.first_select_tab {
  width: 100%;
  float: left;
  padding: 8px 8px 0px 8px;
  background-color: #ebf2f5;
  text-align: left;
}
.first_select_tab .checkbox_container {
  display: block;
  font-size: 18px;
  color: #414042;
  padding-left: 45px;
  position: relative;
  font-weight: 300;
}

.first_select_tab .checkbox_container input {
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
  cursor: pointer;
}
.first_select_tab .checkbox_container .checkedmark {
  position: absolute;
  margin-left: 10px !important;
  background-color: #fff;
  left: 0;
  top: 15px;
  cursor: pointer;
  border-radius: 50px;
  height: 22px;
  width: 22px;
  border: 2px solid #ddd;
}
.search_form {
  width: 100%;
  float: left;
}
.search_form .input-group-text {
  background-color: white;
  color: #005880;
  border: none;
  font-size: 18px;
}
.form-group {
  margin-bottom: 1rem;
}
.search_form input {
  border: none;
}
.search_form select {
  border: none;
}
.search_form input {
  border: none;
}
.check_btn {
  background-color: #6fcd31;
  color: #fff;
  border: none;
  font-size: 18px;
}
.check_btn:hover {
  background-color: #6fcd31;
  color: #fff;
  border: none;
  font-size: 18px;
}
.first_select_tab .checkbox_container input:checked ~ .checkedmark {
  background-color: #1d94ce;
  border: 2px solid #1d94ce;
}
.first_select_tab .checkbox_container input:checked ~ .checkedmark:after {
  display: block;
}
.first_select_tab .checkbox_container .checkedmark:after {
  width: 5px;
  height: 10px;
  left: 6px;
  top: 3px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  content: "";
  display: none;
}
.billing_datail {
  width: 100%;
  float: left;
  background-color: #fff;
  padding: 25px;
  border: 1px solid #ececec;
  margin-bottom: 30px;
}
.addons_detail {
  width: 100%;
  float: left;
}
.addons_detail h3 {
  font-size: 30px;
  color: #414042;
  margin-bottom: 20px;
  font-weight: 400;
}
.addons_detail .checkbox_container {
  display: block;
  font-size: 24px;
  color: #414042;
  margin-bottom: 15px;
  padding-left: 30px;
  position: relative;
  font-weight: 400;
}
.addons_detail p {
  font-size: 16px;
  margin-bottom: 40px;
  color: #979797;
}
.addons_detail .checkbox_container input {
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
  cursor: pointer;
}
.addons_detail .checkbox_container .checkedmark {
  position: absolute;
  left: 0;
  top: 6px;
  border-radius: 50px;
  height: 22px;
  width: 22px;
  border: 2px solid #ddd;
}
.addons_detail label span.float-right {
  color: #1d94ce;
  font-weight: 600;
}
.search_domain_price {
  width: 100%;
  float: left;
  text-align: center;
  background-color: #ebf2f5;
  padding: 15px 20px;
  margin-bottom: 15px;
}
.search_domain_price ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.search_domain_price ul li {
  width: 20%;
  background-color: #fff;
  margin: 0 15px;
  padding: 10px;
}
.search_domain_price ul li h5 {
  font-size: 20px;
  color: #1d94ce;
  margin-bottom: 5px;
}
.search_domain_price ul li h6 {
  font-size: 18px;
  color: #414042;
  margin-bottom: 5px;
}
.search_domain_price ul li button {
  display: block;
  background-color: #6fcd31;
  width: 100%;
  color: #fff;
  border: none;
  padding: 5px;
  cursor: pointer;
  text-decoration: none;
}
.search_domain_price ul li button:hover {
  text-decoration: none;
}
.search_domain_price ul li a {
  text-decoration: none;
}
.search_domain_price ul li a:hover {
  text-decoration: none;
}
.search_domain_price {
  width: 100%;
  float: left;
  text-align: center;
  background-color: #ebf2f5;
  padding: 15px 20px;
}
.search_domain_price ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.search_domain_price ul li {
  width: 20%;
  background-color: #fff;
  margin: 0 15px;
  padding: 10px;
}
.search_domain_price ul li h5 {
  font-size: 20px;
  color: #1d94ce;
  margin-bottom: 5px;
}
.search_domain_price ul li h6 {
  font-size: 18px;
  color: #414042;
  margin-bottom: 5px;
}
.search_domain_price ul li button {
  display: block;
  background-color: #6fcd31;
  width: 100%;
  color: #fff;
  border: none;
  padding: 5px;
  cursor: pointer;
  border: 1px solid #d7d7d7;
  text-decoration: none;
}
.chose_payment_method {
  width: 100%;
  float: left;
  border: 1px solid #ececec;
  background-color: #fff;
  /* padding: 25px; */
}
.chose_payment_method h4 {
  /* font-size: 24px; */
  color: #414042;
  margin-bottom: 20px;
  font-weight: 600;
}
.chose_payment_method .checkbox_container {
  display: block;
  font-size: 16px;
  color: #414042;
  padding-left: 30px;
  padding-top: 4px;
  position: relative;
}
.chose_payment_method .checkbox_container input {
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
  cursor: pointer;
}
.chose_payment_method .checkbox_container .checkedmark {
  position: absolute;
  left: 0;
  top: 6px;
  border-radius: 50px;
  height: 22px;
  padding: 5px;
  width: 22px;
  border: 1px solid #ddd;
}
.float-right {
  float: right !important;
}
.addons_detail .checkbox_container .checkedmark:after {
  width: 5px;
  height: 10px;
  left: 6px;
  top: 3px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  content: "";
  display: none;
}
.addons_detail .checkbox_container input:checked ~ .checkedmark:after {
  display: block;
}
.addons_detail .checkbox_container input:checked ~ .checkedmark {
  background-color: #1d94ce;
  border: 2px solid #1d94ce;
  cursor: pointer;
}
.addons_detail .checkbox_container .checkedmark {
  position: absolute;
  left: 0;
  top: 6px;
  border-radius: 50px;
  height: 22px;
  width: 22px;
}

/* Icons */
[class^="ws-"]:before,
[class*=" ws-"]:before {
  font-family: spantic !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ftr_social_icon {
  width: 100%;
  float: left;
}
.ftr_social_icon h4 {
  font-size: 20px;
  color: #005880;
  text-align: left;
}
.ftr_social_icon ul {
  display: block;
}
.ftr_social_icon ul li {
  margin-left: 0;
}
.ftr_social_icon ul li a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #005880;
  border-radius: 50px;
  text-align: center;
}
.ftr_social_icon ul li a .fa.fa-facebook {
  color: #005880;
}
.ftr_social_icon ul li a .fa.fa-twitter {
  color: #005880;
}
.ftr_social_icon ul li a .fa.fa-instagram {
  color: #005880;
  display: block;
  background: -webkit-linear-gradient(
    #005880,
    #005880,
    #005880,
    #005880,
    #005880
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ftr_social_icon ul li a .fa.fa-linkedin {
  color: #005880;
}
.ftr_social_icon ul li a .fa.fa-youtube {
  color: #005880;
}
.ftr_social_icon ul li a:hover {
}
.ftr_social_icon ul li a .fa {
  margin-right: 0;
  line-height: 40px;
  font-size: 22px;
}
.ws-1-click-install-script:before {
  content: "\61";
}
.ws-advanced-tool:before {
  content: "\65";
}
.ws-seo-audit:before {
  content: "\66";
}
.ws-brand-generatin:before {
  content: "\6c";
}
.ws-certified-expert:before {
  content: "\6e";
}
.ws-content-optimization:before {
  content: "\6f";
}
.ws-link-building:before {
  content: "\74";
}
.ws-keyword-analysis:before {
  content: "\79";
}
.ws-local-seo:before {
  content: "\7a";
}
.ws-ogranic-traffic:before {
  content: "\42";
}
.ws-page-speed:before {
  content: "\4d";
}
.ws-outrank:before {
  content: "\4f";
}
.ws-potential-customer:before {
  content: "\50";
}
.ws-proven-techniques:before {
  content: "\51";
}
.ws-revenue-generation:before {
  content: "\52";
}
.ws-sell-to-customer:before {
  content: "\59";
}
.ws-click:before {
  content: "\61";
}
.ws-1-click-install-script:before {
  content: "\61";
}
.ws-click-a:before {
  content: "\62";
}
.ws-rack:before {
  content: "\63";
}
.ws-clients:before {
  content: "\64";
}
.ws-complaint:before {
  content: "\67";
}
.ws-ae:before {
  content: "\68";
}
.ws-identity:before {
  content: "\69";
}
.ws-backups:before {
  content: "\6a";
}
.ws-server:before {
  content: "\6b";
}
.ws-scripts:before {
  content: "\6d";
}
.ws-speaker:before {
  content: "\70";
}
.ws-quick:before {
  content: "\71";
}
.ws-billing:before {
  content: "\72";
}
.ws-convert:before {
  content: "\73";
}
.ws-business-hosting:before {
  content: "\75";
}
.ws-business-website:before {
  content: "\76";
}
.ws-career:before {
  content: "\77";
}
.ws-cart:before {
  content: "\78";
}
.ws-cpanel:before {
  content: "\41";
}
.ws-feedback:before {
  content: "\43";
}
.ws-pen:before {
  content: "\44";
}
.ws-secure:before {
  content: "\45";
}
.ws-dedicated-server:before {
  content: "\46";
}
.ws-disk:before {
  content: "\47";
}
.ws-tag:before {
  content: "\48";
}
.ws-transfer:before {
  content: "\49";
}
.ws-time-a:before {
  content: "\4a";
}
.ws-time-b:before {
  content: "\4b";
}
.ws-disk-b:before {
  content: "\4c";
}
.ws-easy:before {
  content: "\4e";
}
.ws-ecommerce:before {
  content: "\53";
}
.ws-email:before {
  content: "\54";
}
.ws-secure-b:before {
  content: "\55";
}
.ws-processor:before {
  content: "\56";
}
.ws-fast:before {
  content: "\57";
}
.ws-analytic:before {
  content: "\58";
}
.ws-uptime:before {
  content: "\30";
}
.ws-fault:before {
  content: "\31";
}
.ws-ftp:before {
  content: "\32";
}
.ws-graph:before {
  content: "\35";
}
.ws-growth:before {
  content: "\36";
}
.ws-chat:before {
  content: "\38";
}
.ws-support:before {
  content: "\39";
}
.ws-managed-service:before {
  content: "\21";
}
.ws-mission:before {
  content: "\23";
}
.ws-responsive:before {
  content: "\25";
}
.ws-setup:before {
  content: "\26";
}
.ws-award:before {
  content: "\27";
}
.ws-config:before {
  content: "\29";
}
.ws-payless:before {
  content: "\2b";
}
.ws-performance:before {
  content: "\2c";
}
.ws-email-b:before {
  content: "\2d";
}
.ws-pk-domain:before {
  content: "\2e";
}
.ws-handshake:before {
  content: "\2f";
}
.ws-monitor:before {
  content: "\3a";
}
.ws-proactive:before {
  content: "\3b";
}
.ws-provision:before {
  content: "\3d";
}
.ws-updates:before {
  content: "\3e";
}
.ws-reseller-hosting:before {
  content: "\40";
}
.ws-brand:before {
  content: "\5b";
}
.ws-ssd:before {
  content: "\5d";
}
.ws-scalability:before {
  content: "\5e";
}
.ws-secure-b-1:before {
  content: "\5f";
}
.ws-hand-b:before {
  content: "\60";
}
.ws-monitor-b:before {
  content: "\7c";
}
.ws-cart-b:before {
  content: "\7d";
}
.ws-cart-c:before {
  content: "\e000";
}
.ws-location:before {
  content: "\e001";
}
.ws-recycle:before {
  content: "\e003";
}
.ws-search:before {
  content: "\e004";
}
.ws-migrate:before {
  content: "\e005";
}
.ws-optimize:before {
  content: "\e006";
}
.ws-audit:before {
  content: "\e007";
}
.ws-serve:before {
  content: "\e00a";
}
.ws-serve-b:before {
  content: "\e00b";
}
.ws-shared-hosting:before {
  content: "\e00c";
}
.ws-sm-icons:before {
  content: "\e00d";
}
.ws-email-c:before {
  content: "\e011";
}
.ws-chain:before {
  content: "\e013";
}
.ws-support-b:before {
  content: "\e014";
}
.ws-reviews:before {
  content: "\e015";
}
.ws-vision:before {
  content: "\e016";
}
.ws-vps:before {
  content: "\e017";
}
.ws-wordpress-hosting:before {
  content: "\e018";
}
.ws-comas:before {
  content: "\e019";
}
.ws-linux:before {
  content: "\e01a";
}
.ws-windows:before {
  content: "\e01b";
}
.why_us_simple {
  width: 100%;
  text-align: center;
}
/* 
section {
  margin: 50px 0px;
} */

.whychooseUsGap {
  margin-bottom: calc(var(--bs-gutter-x) * 1);
}

/* .why_us_simple h2 {
  color: #046289;
  font-weight: 300;
}
.why_us_simple h5 {
  font-size: 25px;
  color: #414042;
  font-weight: 300;
  margin-top: -5px;
} */
.brdr_lft_rght:nth-of-type(1),
.brdr_lft_rght:nth-of-type(2),
.brdr_lft_rght:nth-of-type(3),
.brdr_lft_rght:nth-of-type(4),
.brdr_lft_rght:nth-of-type(5) {
  margin-bottom: 35px;
}
.brdr_lft_rght {
  position: relative;
}
.serving_since {
  width: 100%;
  text-align: center;
  cursor: context-menu;
  padding: 0 20px;
}
.serving_since .img_circle {
  width: 100px;
  height: 100px;
  display: inline-block;
  line-height: 75px;
  padding: 10px;
  border-radius: 50px;
  border: 1px solid #046289;
  transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
}
.serving_since .img_circle .icon {
  font-size: 75px;
  color: #046289;
  line-height: 75px;
}
.img_btm {
  background-color: #046289;
  height: 1px;
  max-width: 50px;
  margin-top: 0 !important;
  margin-bottom: 10px !important;
  opacity: 0;
  transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
}
.serving_since h4 {
  font-size: 25px;
  color: #414042;
  font-weight: 400;
  margin-bottom: 10px;
}
.serving_since p {
  font-size: 16px;
  color: #414042;
  text-align: justify;
  -moz-text-align-last: center;
  text-align-last: center;
  margin-top: 0;
  font-weight: 300;
}
.srvcs {
  width: 100%;
  float: left;
  border-bottom: 1px solid #e7e7e7;
  margin-top: -100px;
}
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
.srvcs ul li:nth-child(1) {
  border-left: none;
}
.srvcs ul li {
  background-color: #fafafa;
  float: left;
  padding: 0;
  border-left: 2px solid #e7e7e7;
}
.srvcs ul li .srving_srvcs {
  width: 100%;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #fff;
  padding: 5px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.srving_srvcs .icon {
  font-size: 85px;
  color: #414042;
  margin-right: 25px;
}
.srving_srvcs h4 {
  font-size: 20px;
  color: #414042;
  font-weight: 600;
}
.all_content {
  padding: 25px 0 25px;
}

.divider {
  border-top: 3px solid #707070 !important;
  max-width: 75px !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.new_testimonials {
  width: 100%;
  background-color: #005880;
  text-align: center;
  padding: 100px 0;
}
.new_testimonials h2 {
  color: #fff;
  font-weight: 700;
}
.new_testimonials h5 {
  font-size: 25px;
  color: #fff;
  margin-top: -5px;
  font-weight: 600;
}
.scnd_divider {
  background-color: #fff;
}

.all_plans ul {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.plans_types {
  float: right;
  width: 90%;
  background-color: #ebf2f5;

  cursor: context-menu;
  height: 125px;
  margin-bottom: 30px;
  position: relative;
  padding-left: 70px;
  text-align: left;
  display: table;
}
.icon_box {
  width: 75px;
  height: 75px;
  background-color: #005880;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  float: left;
  position: absolute;
  left: -35px;
  top: 20%;
}
.icon_box .icon {
  font-size: 50px;
  margin-top: 10px;
  color: #fff;
}
.align_data {
  display: table-cell;
  vertical-align: middle;
}
.align_data h4 {
  font-size: 24px;
  color: #414042;
  text-transform: capitalize;
  line-height: 28px;
  font-weight: 400;
}
.align_data p {
  font-size: 17px;
  color: #414042;
  padding-right: 35px;
  font-weight: 400;
}
p {
  padding: 0;
  margin: 0;
  letter-spacing: normal;
  text-transform: none;
  line-height: normal;
}
.why_choose_us h2 {
  color: #fff;
  position: sticky;
}
.why_choose_us h5 {
  font-size: 25px;
  color: #fff;
  margin-top: -5px;
  font-weight: 600;
  position: relative;
}
.serving_center {
  display: table-cell;
  background-color: #ebf2f5;
  vertical-align: middle;
}
.serving_center .icon {
  font-size: 80px;
  color: #005880;
}
.serving_center p {
  font-size: 28px;
  color: #414042;
  font-weight: 300;
}
.serving_services {
  width: 100%;
  float: left;
  background-color: #fff;
  height: 160px;
  display: table;
  border-bottom: 8px solid #005880;
}

/* BlockQuotes */
.blockquote {
  background-color: #ebf2f5;
  font-size: 1.25rem;
  border: 1px solid #ebf2f5;
  border-left: 0.5rem solid #005880;
  color: #6c757d;
  text-align: left;
  line-height: 1.6;

  padding: 25pt 25pt 25pt 25pt;
  position: relative;
  width: 100%;
}
.block_text {
  font-size: 16pt;
  background-color: transparent;
  padding: 25px 0pt;
  text-align: left;
  height: 170px;
}
.block_recommendation {
  text-align: left;
}
.back_color {
  color: white;
}
mark {
  background-color: #005880;
  color: white;
}

/* Search Field */
#custom-search-input {
  margin: 0;
  margin-top: 10px;
  padding: 0;
}

#custom-search-input .search-query {
  padding-right: 5px;
  padding-right: 5px \9;
  padding-left: 5px;
  padding-left: 5px \9;
  /* IE7-8 doesn't have border-radius, so don't indent the padding */
  background-color: #f8f8f8;
  margin-bottom: 0;
  border-radius: 5px;
}

#custom-search-input button {
  border: 0;
  background: none;
  /** belows styles are working good */
  padding: 8px 8px;
  margin-top: 2px;
  position: relative;
  /* left: -28px; */
  /* IE7-8 doesn't have border-radius, so don't indent the padding */
  margin-bottom: 0;
  border-radius: 3px;
  color: #005880;
}

.search-query:focus + button {
  z-index: 3;
}
.front_search {
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  text-transform: none;
  letter-spacing: normal;
  line-height: 1.15;
  color: var(--dl-color-gray-black);
  background-color: var(--dl-color-gray-white);
}

/* WHy Chose Us */
.why_choose_us h2 {
  color: #005880;
  position: sticky;
  text-align: center;
}
.why_choose_us h5 {
  font-size: 25px;
  color: #414042;
  text-align: center;
  font-weight: 300;
  position: relative;
}

.why_choose_us ul li {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-bottom: 30px;
}

/* Domain Search New Design*/
.button {
  color: #005880;
  background-color: transparent;
  border: none;
}
.box-border {
  border: 1px solid #d2d2d2;
  -webkit-box-shadow: 1px #d2d2d2;
  box-shadow: 1px #d2d2d2;
  border-radius: 5px;
}
.table-heading {
  color: #414042;
  font-weight: 400;
}
.table-items {
  font-weight: 300;
  color: #414042;
  font-size: 16px;
}
.cart-heading {
  color: #414042;
  font-weight: 400;
}
.cart-heading2 {
  color: #414042;
  font-size: 15px;
  font-weight: 400;
}
.cart-heading6 {
  color: #414042;
  font-size: 15px;
  font-weight: 300;
}
.cart-total {
  color: #005880;
  font-size: 20px;
  font-weight: 400;
}

.cross-button {
  color: #414042;
  background-color: transparent;
  border: none;
}
.cart-options {
  font-size: 13px;
}

.cart-botton {
  width: 100%;
  padding: 13px;
  margin-top: 5px;
  background-color: #005880;
  border: none;
  color: #fff;
}
.result-message {
  width: 100%;
  background-color: #ebf2f5;
  text-transform: uppercase;
  font-weight: 300;
  color: #414042;
}

.cart-heading3 {
  color: #414042;
  font-size: 18px;
  font-weight: 300;
}
.headring-back-color {
  background-color: #c7dae3;
}
.section-tab-heading {
  background-color: #ebf2f5;
}

.pop_up {
  width: 100%;
  float: left;
  background: rgba(0, 0, 0, 0.7);
  height: 100vh;
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
}
.hide_popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.center_popup.width_change {
  width: 682px;
  margin: auto;
}
.center_popup {
  width: 100%;
  float: left;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.ecommerce_solution {
  padding: 0 50px;
}
.hosting_plans_popup {
  height: 500px;
  padding: 0 80px;
}
span.close_popup {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}
.ecommerce_solution h3 {
  font-size: 32px;
  margin-bottom: -2px;
  text-transform: capitalize;
  line-height: normal;
  color: #fff;
  padding: 0 10px;
}
.hosting_plans_popup h3 {
  text-align: center;
  padding: 0 50px;
  color: #005880;
  font-weight: 400;
}
.popup_body h3 span.white_span {
  color: #fff;
  text-transform: uppercase;
}
.ecommerce_solution p {
  font-size: 25px;
  font-weight: 600;
}
.hosting_plans_popup p {
  font-size: 16px;
  color: #414042;
  text-align: center;
  font-weight: 300;
  /* margin-bottom: 30px; */
}
.ecommerce_solution ul {
  padding: 0 30px;
}
.popup_body ul {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.hosting_plans_popup ul li {
  margin-right: 0;
}
.hosting_plans_popup ul li form {
  text-align: center;
}
.popup_body ul li form input[type="text"],
.popup_body ul li form input[type="email"],
.popup_body ul li form input[type="tel"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
}
.popup_body {
  width: 100%;
  float: left;
  position: relative;
  background-color: #ebf2f5;
  /* padding: 20px 20px 20px 50px; */
  padding: 40px 15px 0px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  border-radius: 10px;
  /* overflow-y: auto; */
  max-height: 100%;
}
.popup_body ul li button {
  padding: 10px 30px;
  text-decoration: none;
  /* border-radius: 5px; */
  border: none;
  display: inline-block;
  background-color: #005880;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border: none;
}
/* .owl-item.active:first-child {
  display: none;
  } */

/* for suggestions */

/* .cart-mobile{
  bottom: 0;
    display: inline-block;
    left: 0;
    margin-top: -112px;
    overflow: visible;
    position: fixed;
    right: 0;
    transition: all .26s ease-in-out;
    transform: translateZ(0);
    -ms-transform: translateZ(0);
    z-index: 1039;
    box-shadow: 0 1px 15px 0rgba(0,0,0,0.5);
    height: 100px;
    background-color: blue;
}
.cart-content-wrapper{
  width: 100%;
  z-index: 1000;
  border-color: #ddd;
  background-color: #fff;

  border-radius: 4px;
}
.cart-detauls{
  display: flex;
    justify-content: space-between;
    font-weight: 600;
    text-transform: uppercase;
    color: #484848;
    background-color: #B8D0DC;
    margin: 0;
    line-height: 43px;
    font-size: 12px;
}
.cart-buttons{
  background: #fff;
    padding: 2px;
}

.pull-left {
  float: left;
}
.subtotal-price-label {
  margin-left: 10px;
}

.cart-mobile.open{
  height: 100%;
    margin-top: 0;
    top: 0;
    background: #fff;
}

.cart-mobile .cart-footer {
  background: #fff;
  border-top: none;
  bottom: 0;
  left: 0;
  padding: 0 15px 10px;
  position: absolute;
  right: 0;
  z-index: 12;
  margin-top: 0;
} */

.domainCheckMobile {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}
.domaiPrivacy {
  padding-left: 10px;
}

.shopping-cart {
  bottom: 0;
  display: inline-block;
  left: 0;
  margin-top: -112px;
  overflow: visible;
  position: fixed;
  right: 0;
  -webkit-transition: all 0.26s ease-in-out;
  -o-transition: all 0.26s ease-in-out;
  transition: all 0.26s ease-in-out;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -ms-transform: translateZ(0);
  z-index: 1039;
  -webkit-box-shadow: 0 1px 15px 0 rgb(0 0 0 / 50%);
  box-shadow: 0 1px 15px 0 rgb(0 0 0 / 50%);
}

.shopping-cart.open {
  height: 100%;
  margin-top: 0;
  top: 0;
  background: #fff;
}

.shopping-cart .cart-content-wrapper {
  width: 100%;
  z-index: 1000;
}
.panel {
  border-radius: 3px;
  background: #fff;
}

.shopping-cart .cart-content-wrapper {
  width: 100%;
  z-index: 1000;
}
.shopping-cart .panel {
  margin-bottom: 0;
}
.shopping-cart .cart-content-wrapper {
  width: 100%;
  z-index: 1000;
}
.shopping-cart .panel {
  margin-bottom: 0;
}
.panel {
  border-radius: 3px;
  background: #fff;
}
.panel {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.panel-default {
  border-color: #ddd;
}

.shopping-cart .cart-header.mobile {
  background-color: #fafafa;
  border-bottom: 1px solid #ececec;
}

.shopping-cart .cart-header {
  background-color: #eee;
  bottom: 0;
  color: #484848;
  z-index: 1060;
  font-weight: bold;
  padding: 0;
}
.registration :before {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.shopping-cart .cart-header .cart-trigger {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-weight: 600;
  text-transform: uppercase;
  color: #484848;
  margin: 0;
  line-height: 43px;
  font-size: 12px;
}

.panel-heading {
  padding: 0 15px;
  height: 43px;
  background: #b8d0dc;
  color: #414042;
}
.panel-heading {
  border-radius: 0;
}
.panel-heading h3 {
  line-height: 43px;
  font-size: 12px;
  color: #414042;
}
.subtotal-price-label {
  margin-left: 10px;
}
.registration span {
  font-weight: 400;
}

.shopping-cart .cart-header .cart-button {
  background: #fff;
  /* padding: 12px; */
}
.shopping-cart .continue-checkout-button {
  width: 100% !important;
}
.shoppingCart .btn-primary,
.shoppingCart .reg-button {
  color: #fff !important;
  background-color: #3575d3 !important;
  border: 1px solid #3575d3 !important;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#5f92dc),
    color-stop(85%, #5f92dc),
    to(#3575d3)
  ) !important;
  background-image: -o-linear-gradient(
    left,
    #5f92dc 0%,
    #5f92dc 85%,
    #3575d3 100%
  ) !important;
  background-image: linear-gradient(
    to right,
    #5f92dc 0%,
    #5f92dc 85%,
    #3575d3 100%
  ) !important;
  background-repeat: no-repeat !important;
  background-size: 10% 100% !important;
  background-position: -100% center !important;
  -webkit-transition: all 0.5s ease !important;
  -o-transition: all 0.5s ease !important;
  transition: all 0.5s ease !important;
  padding: 10px 0 !important;
  width: 100% !important;
  text-shadow: none !important;
}
.registration span {
  font-weight: 400;
}
.clearfix:after {
  clear: both;
}
.component.simple.shoppingCart .cart-list-container {
  padding: 0 5px;
}
.registration :before {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.shopping-cart .cart-content {
  position: relative;
}

.shopping-cart .cart-content .cart-summary {
  border-bottom: none;
  margin: 0;
  padding-top: 19px;
}

.cart-content .cart-summary .name-of-domain-purchase {
  color: #555;
}
.name-of-domain-purchase {
  font-weight: 600;
}
.name-of-domain-purchase {
  text-transform: uppercase;
  word-break: break-word;
}
.cart-summary .cart-item.title {
  word-wrap: break-word;
  width: 40%;
}

.cart-summary .cart-item {
  color: #666666;
}

.cart-summary .cart-item.total {
  text-align: right;
  float: right;
  width: 26%;
}

.cart-summary .cart-item {
  color: #666666;
}
.add-remove {
  cursor: pointer;
  font-size: 10px;
  position: relative;
  top: 2px;
}

.cart-summary .cart-item .remove-item {
  color: #333333;
  -webkit-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  margin-top: 3px;
}

.component.simple.shoppingCart .cart-summary li:after {
  content: "";
  clear: both;
  display: table;
}
.cart-summary .privacy {
  margin-bottom: 15px;
}

.cart-summary .privacy li {
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 15px;
}

.cart-summary .cart-item.title {
  word-wrap: break-word;
  width: 40%;
}

.cart-summary li > div {
  float: left;
  width: 25%;
}

.cart-summary .cart-item.total {
  text-align: right;
  float: right;
  width: 26%;
}

.cart-summary li:after {
  content: "";
  clear: both;
  display: table;
}

.cart-footer {
  background: #fff;
  border-top: none;
  bottom: 0;
  left: 0;
  padding: 0 15px 10px;
  position: absolute;
  right: 0;
  z-index: 12;
  margin-top: 0;
}
.shopping-cart .cart-footer .total,
.shopping-cart .cart-footer .total-label {
  font-weight: bold;
}
.shopping-cart .cart-footer .btn-primary {
  -webkit-font-smoothing: subpixel-antialiased;
}
.hidden {
  display: none !important;
}

.owl-carousel .nav-btn {
  height: 47px;
  position: absolute;
  width: 26px;
  cursor: pointer;
  top: 100px !important;
}

.owl-carousel .owl-prev.disabled,
.owl-carousel .owl-next.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.owl-carousel .prev-slide {
  /* background: url(nav-icon.png) no-repeat scroll 0 0; */
  left: -33px;
}
.owl-carousel .next-slide {
  /* background: url(nav-icon.png) no-repeat scroll -24px 0px; */
  right: -33px;
}
.owl-carousel .prev-slide:hover {
  background-position: 0px -53px;
}
.owl-carousel .next-slide:hover {
  background-position: -24px -53px;
}

.text-grey {
  color: #414042 !important;
}

.text-light-grey {
  color: #979797 !important;
}
.font-16 {
  font-size: 16px;
}

.seperator {
  width: 100%;
}

.controls-wrapper {
  width: 100%;
}

.carousel-wrapper {
  width: 100%;
}

.rec-carousel-item:focus {
  outline: none;
  -webkit-box-shadow: inset 0 0 1px 0px violet;
  box-shadow: inset 0 0 1px 0px violet;
}

.button-login {
  padding: 10px;
  margin-top: 5px;
  background-color: #005880;
  border: none;
  color: #fff;
  width: 30%;
  margin-top: 0px;
  margin-bottom: 20px;
}

.button-sigup {
  padding: 10px;
  /* margin-top: 5px; */
  background-color: #005880;
  border: none;
  color: #fff;
  width: 15%;
  margin-top: 0px;
  margin-bottom: 20px;
}

a {
  color: #005880;
}

input {
  border: 1px solid #d2d2d2 !important;
  line-height: 2.5 !important;
}
.form-control {
  color: #414042 !important;
  -webkit-box-shadow: 0 0 0 0.25rem rgb(65 64 66 / 1%) !important;
  box-shadow: 0 0 0 0.25rem rgb(65 64 66 / 1%) !important;
}
.form-select:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.checkbox-round {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  background-color: #005880;
}

.form-select {
  line-height: 2.5 !important;
  color: #414042 !important;
}

.checkss > .discount {
  padding-top: 0;
}
.checkss > .package-one-price {
  margin-bottom: 20;
}
.modal-header {
  border-bottom: 0px !important;
}
.modal-footer {
  border-top: 0px !important;
}
.btn-close {
  width: 1px !important;
  height: 10px !important;
}
.modal-header .btn-close {
  padding: 0rem 0.5rem !important;
}
.modal-body {
  padding-top: 0px;
  padding-left: 40px;
  padding-right: 40px;
}

.recomend {
  margin-top: -20px;
  color: #414042;
  font-weight: 300;
}

.popup-fetaure {
  margin-top: 20px;
  margin-bottom: 0px;
  color: #414042;
  text-transform: uppercase;
  font-weight: 400;
}

.popup-featuresub {
  font-weight: 300;
}

.popUpUl li {
  padding-top: 2px;
  padding-bottom: 2px;
  font-weight: 300;
}

.popupcheck {
  color: #005880;
  padding-right: 10px;
}

.poppamount {
  font-size: 25px;
  color: #414042;
  padding-bottom: 30px;
}

.popamountcut {
  color: #a5a5a5;
  font-weight: 200;
  font-size: 18px;
}

.popupButton {
  font-size: 16px;
  background-color: #005880;
  padding: 10px 30px;
  color: #fff;
  border: none;
}
.block_recommendation > span {
  text-align: left;
}
.popopsee {
  font-size: 15px;
}
#check_search {
  line-height: 1 !important;
}
.navbar {
  padding-top: 0px;
  padding-bottom: 0px;
}
.dropdown-toggle::after {
  margin-left: 0.355em;
  vertical-align: 0.155em;
}
.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}
.dropdown-item {
  display: block;
  min-width: 10rem;
  padding: 0.8rem 0.8rem;
  font-weight: 400;
  color: #414042;
  white-space: nowrap;
}

.dropdown-menu {
  background-color: #fff;
  padding-top: 0px;
  padding-bottom: 0;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  -webkit-box-shadow: 0.25rem 0.25rem 0.75rem rgb(0 0 0 / 20%);
  box-shadow: 0.25rem 0.25rem 0.75rem rgb(0 0 0 / 20%);
}
.navbar-light .navbar-nav .nav-link {
  color: #414042;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #1e2125;
  background-color: #ebf2f5;
}

.rec-arrow-left,
.rec-arrow-right {
  font-size: 28px !important;
  color: #414042 !important;
  background-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.sec-compare-plans .table td:first-child {
  padding: 0 80px;
  text-align: right;
}

/* .table {
  border: 2px solid #ebf2f5;
} */
.table > thead {
  border: 2px solid #ebf2f5;
}
tr {
  /* //border: 2px solid #ebf2f5; */
  border-bottom: 1px solid #ebf2f5;
}
.accordion-body {
  padding-top: 0px;
}
.newdomainCheck > .fa-search {
  color: #ebf2f5;
}
.sec-customzied-text {
  margin-top: 50px;
  margin-bottom: 50px;
}
.button-active {
  background-color: #fff !important;
  color: #000 !important;
}
.nav-button {
  border: none !important;
  text-align: center !important;
  padding: 0px 0 !important;
}
.payment_text p {
  margin-bottom: 20px;
  color: #414042;
  font-size: 19px;
  font-weight: 200;
}
.nav-link.active.navActive {
  background-color: #fff !important;
}

.FaqButton {
  font-size: 16px;
  background-color: #005880;
  padding: 10px 30px;
  color: #fff;
  border: none;
  text-decoration: none;
  font-weight: 600;
  margin: 35px 0 0;
  display: inline-block;
}
.FaqButton:hover {
  color: #fff;
}
.input-group > .form-control,
.input-group > .form-select {
  -webkit-box-shadow: 0 0 0 0.25rem rgb(65 64 66 / 1%);
  box-shadow: 0 0 0 0.25rem rgb(65 64 66 / 1%);
}
.list-group-item {
  color: #414042;
  border-radius: 5px;
}
.cart-button_secondary {
  color: #414042;
  background: transparent;
  border: 1px solid #005880;
  width: 100%;
  padding: 13px;
  margin-top: 12px;
}
.go-back {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0rem 0.5rem 0rem;
}
.list-group-item + .list-group-item {
  border-top-width: inherit;
}
.bordersetps {
  border-top: 1px solid #005880;
  border-left: 1px solid #005880;
  border-bottom: 1px solid #005880;
}

/* BLOG */
.blog_category {
  width: 100%;
  float: left;
  margin-bottom: 50px;
}
.blog_category .blog_img {
  width: 100%;
  float: left;
  border-radius: 5px;
}
.blog_category .blog_img img {
  border-radius: 5px;
  max-width: 100%;
}
.blog_category .blog_text {
  width: 100%;
  float: left;
  padding: 10px 0 25px;
  border-bottom: 1px solid #e6e6e6;
}
.blog_category .blog_text span {
  font-size: 14px;
  color: #434343;
  display: block;
}
.blog_category .blog_text a {
  text-decoration: none;
  color: #434343;
}
.blog_category .blog_text p {
  font-size: 14px;
  color: #434343;
}
.blog_articles_section .input-group .input-group-append {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: #666;
  cursor: pointer;
  z-index: 99999;
}
.input-group-append span button {
  background-color: transparent;
  border: none;
}
.blog_articles_section .blog_articales {
  width: 100%;
  float: left;
  padding-top: 30px;
}
.blog_articles_section .blog_articales span {
  font-size: 16px;
  color: #434343;
  font-weight: 300;
  display: block;
  margin-bottom: 20px;
}
.blog_articles_section .blog_articales .first_article {
  width: 100%;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 0 30px;
  border-top: 1px solid #eaeaea;
}
.blog_articles_section .blog_articales .first_article .articl_img {
  width: 28%;
  float: left;
  border-radius: 5px;
}
.blog_articles_section .blog_articales .first_article .articl_img img {
  border-radius: 5px;
  max-width: 100%;
}
.blog_articles_section .blog_articales .first_article .article_text {
  width: 72%;
  float: left;
  padding-left: 15px;
}
.blog_articles_section .blog_articales .first_article .article_text span {
  font-size: 14px;
  color: #434343;
  margin-bottom: 5px;
  display: block;
  display: none;
}
.blog_articles_section .blog_articales .first_article .article_text a {
  text-decoration: none;
  color: #434343;
}
.blog_articles_section .blog_articales .first_article .article_text h5 {
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 20px;
  font-weight: 600;
}
.blog_category .blog_text a:hover {
  text-decoration: underline;
}
.blog_detail_sction {
  width: 100%;
  float: left;
  padding: 50px 0;
  text-align: left;
}
.blog_detail_sction p {
  font-size: 16px;
  display: table;
  color: #434343;
  margin-bottom: 25px;
  font-weight: 400;
  line-height: 1.7;
}
.blog_detail_sction h3 {
  font-size: 35px;
  color: #005a7e;
  margin-bottom: 25px;
  font-weight: 700;
}
.blog_detail_sction p img {
  margin-bottom: 30px;
  display: block;
}

@media (min-width: 992px) {
  .offcanvas {
    visibility: visible;
    position: relative;
    background: none;
    border: none;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
    color: red;
  }
}
@media (max-width: 992px) {
  .offcanvas {
    width: 250px !important;
  }
  .offcanvas-start-lg {
    top: 0;
    left: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.color-b {
  color: #414042;
}
.frequently {
  background-color: #f8f8f8;
  padding: 100px 80px;
}
.border-top {
  border-top: 10px solid #049bd8 !important;
}
.satisfied_customers_faq.pt-5.pb-5.text-left {
  padding-top: 0 !important;
}
.satisfied_customers_faq p {
  color: #414042;
  font-size: 15px;
  margin-top: 20px;
}
.question_section {
  background-color: #fff;
  padding: 100px 50px 50px;
  text-align: center;
  border-radius: 5px;
}
.bannerSecond h2 {
  /* font-size: 48px; */
  margin-bottom: 25px;
  font-weight: 400;
}

.bannerSecond p {
  color: #414042;
  font-size: 25px;
  margin-top: -5px;
  font-weight: 300;
}

.addonss::before {
  font-family: FontAwesome;
  width: 1.25rem;
  height: 1.25rem;
  padding-right: 6px;
  margin-left: auto;
  background-image: none;
  content: "\f067";
}

.notAllowed {
  cursor: not-allowed !important;
  color: #8f8f8f !important;
}
.pacakgenotAllowed {
  cursor: not-allowed !important;
  background-color: #d2d2d2 !important;
  color: #8f8f8f !important;
}

.pacakgenotAllowedsubmit {
  cursor: not-allowed !important;
  background-color: #ebf2f5 !important;
  color: #8f8f8f !important;
}

.online_banking_system ul li:last-child p {
  margin-bottom: 0px;
}

.cartmbile-off {
  height: 0px;
  overflow: hidden;
}

.cartmbile-open {
  height: 100%;
  overflow: hidden;
  transition: all 0.26s ease-in-out;
  transform: translateZ(0);
}

.ReactCollapse--content {
  overflow: scroll;
}

.krmNah {
  /* background-color: rgb(0 88 128) !important;
  box-shadow: 0 0 1px 3px rgb(0 88 128) !important; */
  background-color: #005880 !important;
  box-shadow: 0 0 1px 3px #005880 !important;
}
/* .navbar-nav li a {
  color: white !important;
} */

/* .modal-backdrop{
  background-color: #ebf2f5 !important;
} */

/* new cart */

/* .component.simple.shoppingCart {
  position: relative;
}
.component.simple.shoppingCart .shopping-cart {
  bottom: 0;
  display: inline-block;
  left: 0;
  margin-top: -112px;
  overflow: hidden;
  position: fixed;
  right: 0;
  transition: all .26s ease-in-out;
  transform: translateZ(0);
  -ms-transform: translateZ(0);
  z-index: 1039;
  box-shadow: 0 1px 15px 0 rgba(0,0,0,0.5);
}
.affix {
  position: fixed;
}
.component.simple.shoppingCart .shopping-cart .cart-content-wrapper {
  width: 100%;
  z-index: 1000;
}
.component.simple.shoppingCart .shopping-cart .panel {
  margin-bottom: 0;
}
.panel {
  border-radius: 3px;
  background: #fff;
}
.component.simple.shoppingCart .shopping-cart .cart-header.mobile {
  background-color: #fafafa;
  border-bottom: 1px solid #ececec;
}
.component.simple.shoppingCart .shopping-cart .cart-header {
  background-color: #eee;
  bottom: 0;
  color: #484848;
  z-index: 1060;
  font-weight: bold;
  padding: 0;
}
.registration ::before {
  box-sizing: content-box;
}
.clearfix::after, .clearfix::before {
  content: " ";
  display: table;
}

.component.simple.shoppingCart .shopping-cart .cart-header .cart-trigger {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  text-transform: uppercase;
  color: #484848;
  margin: 0;
  line-height: 43px;
  font-size: 12px;
}
.component.simple.shoppingCart .panel-heading {
  padding: 0 15px;
  height: 43px;
  background: #eee;
  color: #555;
}
.panel-heading {
  border-radius: 0;
}
.component.simple.shoppingCart .panel-heading h3 {
  line-height: 43px;
  font-size: 12px;
  color: #555;
}
.component.simple.shoppingCart .subtotal-price-label {
  margin-left: 10px;
}
.registration p, .registration span {
  font-weight: 400;
}
.component.simple.shoppingCart .shopping-cart .cart-header .cart-trigger {
  font-weight: 600;
  text-transform: uppercase;
  color: #484848;
  line-height: 43px;
  font-size: 12px;
}
.component.simple.shoppingCart .shopping-cart .cart-header .cart-button {
  background: #fff;
  padding: 12px;
}
.registration p, .registration span {
  font-weight: 400;
}
.clearfix::after {
  clear: both;
}
.clearfix::after, .clearfix::before {
  content: " ";
  display: table;
}
.registration ::before {
  box-sizing: content-box;
}
.component.simple.shoppingCart .shopping-cart .cart-content {
  position: relative;
}
.component.simple.shoppingCart .shopping-cart .cart-content .cart-summary {
  border-bottom: none;
  margin: 0;
  padding-top: 19px;
}
.registration li, .registration p {
  font-weight: 200;
}
.component.simple.shoppingCart .shopping-cart .cart-content .cart-summary .name-of-domain-purchase {
  color: #555;
}
.component.simple.shoppingCart .name-of-domain-purchase {
  font-weight: 600;
}
.component.simple.shoppingCart .name-of-domain-purchase {
  text-transform: uppercase;
  word-break: break-word;
}
.component.simple.shoppingCart .cart-summary .cart-item.title {
  word-wrap: break-word;
  width: 40%;
}
.component.simple.shoppingCart .cart-summary .cart-item {
  color: #666666;
}
.component.simple.shoppingCart .cart-summary li > div {
  float: left;
  width: 25%;
}
.component.simple.shoppingCart .cart-summary .cart-item {
  color: #666666;
}
.component.simple.shoppingCart .cart-summary li > div {
  float: left;
  width: 25%;
}
.component.simple.shoppingCart .cart-summary .cart-item {
  color: #666666;
}
.component.simple.shoppingCart .cart-summary .cart-item {
  color: #666666;
}
.component.simple.shoppingCart .add-remove {
  cursor: pointer;
  font-size: 10px;
  position: relative;
  top: 2px;
}
.registration p, .registration span {
  font-weight: 400;
}
.component.simple.shoppingCart .cart-summary .cart-item .remove-item {
  color: #333333;
  transition: opacity .2s ease-in-out;
  margin-top: 3px;
}
.component.simple.shoppingCart .icon-close {
  background-image: url("//static.registration.bluehost.com/561/static/reggie/img/shoppingCart/simple/close.svg");
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  position: relative;
  right: -6px;
  top: -2px;
  display: inline-block;
}
.pull-right {
  float: right !important;
}
.component.simple.shoppingCart .cart-summary li::after {
  content: '';
  clear: both;
  display: table;
} */

.text-left {
  text-align: left !important;
}

.sec-faq-accor .accordion button {
  width: 100%;
  text-align: left;
  padding: 15px 15px 15px 45px;
  /* border: none; */
  background-color: #ebf2f5;
  font-size: 20px;
  color: #414042;
  font-weight: 400;
  margin-bottom: 18px;
  cursor: pointer;
  outline: none;
  position: relative;
}

.notfound {
  max-width: 767px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
  padding: 15px;
}
.notfound .notfound-404 h1 {
  font-family: "Kanit", sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 186px;
  font-weight: 200;
  margin: 0px;
  background: linear-gradient(130deg, #005880, #005880);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  text-transform: uppercase;
}
#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.notfound .notfound-404 {
  position: relative;
  height: 220px;
}
.notfound h2 {
  font-family: "Kanit", sans-serif;
  font-size: 33px;
  font-weight: 200;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 25px;
  letter-spacing: 3px;
}
.notfound h2 {
  font-family: "Kanit", sans-serif;
  font-size: 33px;
  font-weight: 200;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 25px;
  letter-spacing: 3px;
}
.notfound a {
  font-family: "Kanit", sans-serif;
  color: #005880;
  font-weight: 200;
  text-decoration: none;
  border-bottom: 1px dashed #005880;
  border-radius: 2px;
}
.fa-cart-plus {
  font-size: 20px !important;
}
#sfn,
#sem,
#sln,
#seme,
#spw,
#sph,
#sadd,
#sct,
#sst,
#spo,
#sco,
#sho,
#spay {
  font-size: 14px;
  color: red;
}

.readmoreMobile {
  width: 160px;
  font-size: 16px;
  height: 45px;
  border: none;
  background-color: #005880;
  color: #fff;
  text-transform: capitalize;
}

.kCa-dlP {
  background-color: #ebf2f5 !important;
  box-shadow: none !important;
  height: 13px !important;
  width: 13px !important;
}
.jYxeHE {
  box-shadow: none !important;
  background-color: #005880 !important;
  height: 13px !important;
  width: 13px !important;
}
.jYxeHE:hover,
.jYxeHE:focus {
  box-shadow: none !important;
}
.kCa-dlP:hover,
.kCa-dlP:focus {
  box-shadow: none !important;
}
.jhmYzC:hover,
.jhmYzC:focus {
  box-shadow: 0 0 1px 3px rgb(1 89 128) !important;
}

.newcolorss {
  background-color: #ebf2f5;
}

#overlayCSS {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 70%;
  cursor: pointer;
  z-index: 9999;
}

.overlay_content {
  position: relative;
  top: 50%;
  width: 100%;
  font-size: 24px;
  text-align: center;
  margin-top: 30px;
  color: #ebf2f5;
  padding-left: 50px;
  padding-right: 50px;
}
/* 
  .blog-img{
    display: flex;
    width: 100%;
    justify-content: center;
  } */

.padding_both_sides img {
  display: flex;
  width: 100%;
  justify-content: center;
}

.padding_both_sides h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.Previous_btn {
  font-size: 16px;
  background-color: #005880;
  padding: 10px 30px;
  color: #fff;
  border: none;
  margin: 40px 0 25px 0;
  text-decoration: none;
}
.Previous_btn:hover {
  color: white;
}

.maxwidth70 {
  max-width: 70%;
}
.input-group > .form-control:focus {
  z-index: 0;
}

.ordersub {
  font-size: 20px;
  text-align: justify;
  text-align-last: center;
  margin-top: 0;
  font-weight: 400;
}
.ordersubsecond {
  font-size: 20px;
  text-align: justify;
  text-align-last: center;
  margin-top: 0;
  font-weight: 400;
}
.submitammiunt {
  /* width: 40%; */
  padding: 10px;
  cursor: text !important;
  background-color: #ebf2f5;
  border: none;
}

.read-more-less--more {
  font-size: 1rem !important;
  color: #005880 !important;
  font-weight: 600 !important;
}
.read-more-less--less {
  font-size: 1rem !important;
  color: #005880 !important;
  font-weight: 600 !important;
}

.formValidate{
  border-color: #f78080 !important;
}
#cardNumber, #expiry, #cvc, #card-number-element{
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
}

.asrwqsadasdasd{
  margin-top: 30px;
}
.asd123asdasd1:hover{
  color: #005880;
}